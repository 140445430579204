import { Link } from "react-router-dom";
import { ButtonText, CurateOrderDetailBox, basicTableDateFormat } from "../../utils/consts";
import { BarCode, ClockTimeIcon, DiamondIcon, PriceIcon, SymbolNext } from "../";
import moment from "moment-timezone";
import { formatPrice } from "../../utils/helpers";

type OrderDetailBoxProps = {
  key: string;
  id: string;
  price: string | any;
  quantity: string | any;
  shape: string;
  valid: string | any;
};

export const OrderDetailBox = (props: OrderDetailBoxProps) => {
  return (
    <div className="order-detail-box">
      <div className="caption-large-upper heavy">{CurateOrderDetailBox.Head}</div>
      <div className="order-id one-line">
        <BarCode />
        <div className="body-small medium one-line">
          {CurateOrderDetailBox.ID} {props.id}
        </div>
      </div>
      <div className="order-offer-price one-line">
        <PriceIcon />
        <div className="body-small medium one-line">
          {CurateOrderDetailBox.Price} {formatPrice(props.price)}
        </div>
      </div>
      <div className="stones-count one-line">
        <DiamondIcon />
        <div className="body-small medium one-line">
          {CurateOrderDetailBox.Stones} {props.quantity} {props.shape}
        </div>
      </div>
      <div className="offer-valid one-line">
        <ClockTimeIcon />
        <div className="body-small medium one-line">
          {CurateOrderDetailBox.ValidUntil} {moment(props.valid).format(basicTableDateFormat)}
        </div>
      </div>
      <Link to={`/curate/my-offers/${props.id}`}>
        <button className="btn-primary-outline btn-wide">
          {ButtonText.Review} {<SymbolNext />}
        </button>
      </Link>
    </div>
  );
};
