import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import * as zip from "@zip.js/zip.js";
import { ZipFile } from "../utils/models";
import { AdditionalConfig } from "../utils/consts";
import { addToCache, getFromCache } from "../utils/helpers";

const filenameEncoding = "cp437";

const fetchFile = async ({ queryKey }: any): Promise<ZipFile[]> => {
  const [, fileName] = queryKey;
  let list = getFromCache(fileName, []);
  if (!list.length) {
    const response = await axios.get(fileName, {
      responseType: "blob",
      headers: {
        Authorization: undefined,
      },
    });
    const entries = await new zip.ZipReader(new zip.BlobReader(response.data)).getEntries({ filenameEncoding });
    for (const entry of entries) {
      if (!entry.directory) {
        const index = entries.indexOf(entry);
        const file = await entry.getData(new zip.BlobWriter(), {});
        const buffer = await file.arrayBuffer();
        list.push({
          filename: entry.filename,
          index,
          file: buffer,
        });
      }
    }
    addToCache(fileName, list);
  }
  return list;
};

export const useGetZip = (fileName?: string): UseQueryResult<ZipFile[]> => {
  return useQuery(["zipFile", fileName], fetchFile, {
    enabled: !!fileName,
    ...AdditionalConfig,
  });
};
