import { useEffect, useState } from "react";
import { CarouselNext, CarouselPrev } from "../GlobalIcons";

export type CustomCarouselProps = {
  children: React.ReactNode;
};

export const calculateVisibleBoxes = (carouselWidth: number, singleBoxWidth: number): number => {
  return Math.floor(carouselWidth / singleBoxWidth);
};

export const CustomCarousel = ({ children }: CustomCarouselProps) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const singleBoxWidth = 290;

  const handlePrev = () => {
    setSlideIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const handleNext = () => {
    const numBoxes = (children as Array<any>).length;
    const visibleBoxes = calculateVisibleBoxes(carouselWidth, singleBoxWidth);
    const remainingClicks = numBoxes - visibleBoxes - slideIndex;
    if (remainingClicks > 0) {
      setSlideIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    const calculateSize = () => {
      if (window.innerWidth > 1024) {
        setCarouselWidth(window.innerWidth - 590);
      }
      if (window.innerWidth <= 1024) {
        setCarouselWidth(window.innerWidth - 130);
      }
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, []);

  const numBoxes = (children as Array<any>).length;
  const visibleBoxes = calculateVisibleBoxes(carouselWidth, singleBoxWidth);
  const showButtons = numBoxes > visibleBoxes && slideIndex + visibleBoxes < numBoxes;
  const slidePercentage = (slideIndex * singleBoxWidth * 100) / (numBoxes * singleBoxWidth);

  return (
    <div className="carousel-container">
      <div className="custom-carousel">
        {slideIndex > 0 && (
          <div className="button-holder-left">
            <button className="button-left" onClick={handlePrev}>
              <CarouselPrev />
            </button>
          </div>
        )}

        <div
          className="custom-carousel-wrapper"
          style={{
            width: carouselWidth,
            marginLeft: slideIndex >= 1 ? 80 : 0,
            marginRight: slideIndex >= 1 && showButtons ? -80 : 0,
          }}
        >
          <div
            className="carousel-items"
            style={{
              transform: `translateX(-${slidePercentage}%)`,
            }}
          >
            {(children as Array<any>)?.map((child, index) => (
              <div className="single-item" key={index}>
                {child}
              </div>
            ))}
          </div>
        </div>

        {showButtons && (
          <div className="button-holder-right">
            <button className="button-right" onClick={handleNext} disabled={slideIndex >= numBoxes - visibleBoxes}>
              <CarouselNext />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
