import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { AdditionalConfig } from "../utils/consts";

const fetch = async ({ queryKey }: { queryKey: any[] }): Promise<string> => {
  const [, path] = queryKey;
  const response = await axios.get(path, {
    responseType: "text",
  });
  return response.data;
};

export const useGetText = (path?: string): UseQueryResult<string> => {
  return useQuery(["text", path], fetch, {
    enabled: !!path,
    ...AdditionalConfig,
  });
};
