import { Modal } from "..";

type Button = {
  text: string;
  onClick: () => void;
  class: string;
  symbol?: any;
};
export type InfoStatusBlockProps = {
  icon?: any;
  message: string | any;
  buttons?: Button[];
  isModalOpen?: boolean;
  modalButtons?: Button[];
  setIsModalOpen?: (arg0: boolean) => void;
  modalHeader?: string;
  modalContent?: string;
  widerTextBlock?: boolean;
};
export const InfoStatusBlock = ({
  icon,
  message,
  buttons,
  isModalOpen,
  modalButtons,
  setIsModalOpen,
  modalHeader,
  modalContent,
  widerTextBlock,
}: InfoStatusBlockProps) => {
  return (
    <>
      <div className="info-status-box">
        <div className="info-block">
          <div className="icon-block">{icon}</div>
          <div className={`text-block ${widerTextBlock ? "wider" : ""}`}>{message}</div>
          <div className="buttons-wrapper">
            {buttons &&
              buttons.map((button, index) => {
                return (
                  <button className={`${button.class}`} onClick={button.onClick} key={index}>
                    {button.symbol}
                    {button.text}
                  </button>
                );
              })}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          modalHeader={modalHeader}
          modalContent={modalContent}
          buttons={modalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
