import { PolishedItemRaw, RequestItem, RequestItemRaw, Stone, TnC, TnCRaw, TnCService } from "./models";
import moment from "moment-timezone";
import { serverDateTimeFormat } from "./consts";

export const serializeCurateItem = (item: RequestItemRaw): RequestItem => ({
  sightHolder: {
    id: item.SightHolderId,
    name: item.SightHolderName,
  },
  createdBy: {
    userID: item?.createdBy?.UserID,
    name: item?.createdBy?.Name,
    lastName: item?.createdBy?.LastName,
    email: item?.createdBy?.Email,
  },
  date: item.requestedDate,
  id: item.requestId,
  shape: item.shape,
  quantity: item.quantity,
  weight: { low: item.weight.low, high: item.weight.high },
  color: { low: item.colour.low, high: item.colour.high },
  clarity: { low: item.clarity.low, high: item.clarity.high },
  flu: { low: item.flu.low, high: item.flu.high },
  make: { low: item.make.low, high: item.make.high },
  deliveryDate: item.requestedDeliveryDate,
  status: item.status,
  offer: {
    stoneCount: item.offer?.stoneCount ? parseInt(item.offer.stoneCount, 10) : null,
    validTo: item.offer?.validTo || null,
    price: item.offer?.offerPrice || null,
    matchRate: item.offer?.matchRate || null,
    boxId: item.offer?.boxId || null,
    expectedDeliveryDate: item.offer?.expectedDeliveryDate || null,
    acceptedOfferDate: item.offer?.acceptedOfferDate || null,
    offerDate: item.offer?.offeredDate || null,
  },
});

export const deserializeCurateItem = (item: RequestItem): RequestItemRaw => ({
  SightHolderId: item.sightHolder.id,
  SightHolderName: item.sightHolder.name,
  requestedDate: item.date || moment().format(serverDateTimeFormat),
  requestId: item.id,
  shape: item.shape,
  quantity: item.quantity,
  weight: item.weight,
  colour: item.color,
  clarity: item.clarity,
  flu: item.flu,
  make: item.make,
  requestedDeliveryDate: item.deliveryDate,
  status: item.status,
  offer:
    !item.offer || !item.offer.boxId
      ? undefined
      : {
          stoneCount: item.offer.stoneCount?.toString() || null,
          validTo: item.offer.validTo || null,
          offerPrice: item.offer.price || null,
          matchRate: item.offer.matchRate || null,
          boxId: item.offer.boxId || null,
          expectedDeliveryDate: item.offer.expectedDeliveryDate || null,
          acceptedOfferDate: item.offer.acceptedOfferDate || null,
          offeredDate: item.offer?.offerDate || null,
        },
});

export const serializeTnC = (item: TnCRaw): TnC => {
  let service = TnCService.AlphaConnect;
  if (item.service.toLowerCase() === TnCService.AlphaCurate.toLowerCase()) {
    service = TnCService.AlphaCurate;
  }
  if (item.service.toLowerCase() === TnCService.Streams.toLowerCase()) {
    service = TnCService.Streams;
  }
  return {
    service,
    status: item.status,
    acceptedVersion: item.accepted_version,
    latestVersion: item.latest_version,
    contentLocation: item.contentLocation,
  };
};

export const serializePolishedItem = (item: PolishedItemRaw): Stone => ({
  polishedID: item.PolishedId,
  shape: item.Shape,
  weight: item.Weight,
  color: item.Colour,
  flu: item.Flu,
  grade: item.Grade,
  make: item.Make || "",
  filename: item.plyfilename,
  clarity: item.Clarity || "",
});
