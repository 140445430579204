import { Row, Col } from "react-bootstrap";
import { BarCode, Calendar, RoughtStone, SymbolDownload, SymbolNext, Modal } from "../";
import { NavLink } from "react-router-dom";
import { ButtonText, ModalTexts } from "../../utils/consts";
import { MouseEvent, useState } from "react";
import { useDoDownload } from "../../hooks";

interface SingleBoxProps {
  id: string;
  link: string;
  count: number;
  date: string | undefined;
  image: string;
}

export const SingleBox = (props: SingleBoxProps) => {
  const boxInfo = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  const handleOpenModal = (e: MouseEvent) => {
    setIsModalOpen(true);
    e.preventDefault();
  };

  const handleDownloadBox = () => {
    setDownloadList([boxInfo.link]);
    setIsModalOpen(false);
  };

  const modalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  return (
    <>
      <Col sm={12} md={6} lg={6} xl={4} xxl={3} xxxl={2}>
        <NavLink to={`/active-boxes/${boxInfo.id}`}>
          <div className="single-box" key={boxInfo.id}>
            <div
              className="img-holder"
              style={{
                backgroundImage: `url(${props.image})`,
              }}
            ></div>
            <div className="box-info">
              <Row className="small-gap">
                <Col sm={4} className="small-gap">
                  <div className="box-id">
                    <BarCode />
                    <div className="number caption-large-upper medium">{boxInfo.id}</div>
                  </div>
                </Col>
                <Col sm={4} className="small-gap">
                  <div className="box-date">
                    <Calendar />
                    <div className="number caption-large-upper medium">{props.date}</div>
                  </div>
                </Col>
                <Col sm={4} className="small-gap">
                  <div className="box-info-stones">
                    <RoughtStone />
                    <div className="number caption-large-upper medium">{boxInfo.count}</div>
                  </div>
                </Col>
              </Row>
              <div className="links-to-download">
                <button className="btn-primary btn-wide" onClick={handleOpenModal}>
                  {ButtonText.DownloadBox} {<SymbolDownload />}
                </button>
                <button className="btn-secondary btn-wide">
                  {ButtonText.RoughStones} {<SymbolNext />}
                </button>
              </div>
            </div>
          </div>
        </NavLink>
      </Col>
      {isModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBox}
          modalContent={ModalTexts.ContentDownloadBox}
          buttons={modalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
