import { Slider } from "@mui/material";
import { ChangeEvent } from "react";
import { MaxMin } from "..";
import { caratRange } from "../../utils/consts";

export type RangeSliderWithInputsProps = {
  caratValues: MaxMin;
  setCaratValues: (value: MaxMin) => void;
};

export const RangeSliderWithInputs = ({ caratValues, setCaratValues }: RangeSliderWithInputsProps) => {
  const handleInputChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(event.target.value);
    if (newValue > caratValues.max) {
      newValue = caratValues.max;
    }
    setCaratValues({ min: newValue, max: caratValues.max });
  };

  const handleInputChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(event.target.value);
    if (newValue < caratValues.min) {
      newValue = caratValues.min;
    }
    setCaratValues({ min: caratValues.min, max: newValue });
  };

  const handleBlurFrom = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(event.target.value);
    if (newValue > caratValues.max) {
      newValue = caratValues.max;
    }
    if (newValue < caratRange.min) {
      newValue = caratRange.min;
    }
    if (isNaN(newValue)) {
      newValue = caratRange.min;
    }
    setCaratValues({ min: newValue, max: caratValues.max });
  };

  const handleBlurTo = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = parseFloat(event.target.value);
    if (newValue < caratValues.max) {
      newValue = caratValues.max;
    }
    if (newValue > caratRange.max) {
      newValue = caratRange.max;
    }
    if (isNaN(newValue)) {
      newValue = caratRange.max;
    }
    setCaratValues({ min: caratValues.min, max: newValue });
  };

  return (
    <>
      <div className="slider-container">
        <div className="slider-container-header caption-large-upper heavy">carat</div>
        <div className="slider-with-values">
          <div className="slider-box">
            <Slider
              component="span"
              value={[
                isNaN(caratValues.min) ? caratRange.min : caratValues.min,
                isNaN(caratValues.max) ? caratRange.max : caratValues.max,
              ]}
              disableSwap={true}
              step={0.01}
              min={caratRange.min}
              max={caratRange.max}
              onChange={(event, values) => {
                if (Array.isArray(values)) {
                  setCaratValues({ min: values[0], max: values[values.length - 1] });
                } else {
                  setCaratValues({ min: values, max: values });
                }
              }}
            />
          </div>
          <div className="values-box">
            <div className="left-value-box">
              <input
                type={"number"}
                min={caratRange.min}
                max={isNaN(caratValues.max) ? caratRange.max : caratValues.max}
                value={caratValues.min || ""}
                onChange={handleInputChangeFrom}
                onBlur={handleBlurFrom}
              ></input>
            </div>
            <div className="right-value-box">
              <input
                type={"number"}
                min={isNaN(caratValues.min) ? caratRange.min : caratValues.min}
                max={caratRange.max}
                value={caratValues.max || ""}
                onChange={handleInputChangeTo}
                onBlur={handleBlurTo}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
