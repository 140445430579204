import { Col, FormCheck, Row } from "react-bootstrap";
import { GroupedData, PolyItem } from "../../utils/models";
import { ArrowDown, BarCode, Calendar, SymbolDownload, Weight, Modal } from "../";
import { ButtonText, Diamonds, ModalTexts, tabletScreenSize } from "../../utils/consts";
import { useState } from "react";
import { useDoDownload } from "../../hooks";

type StoneLeftPanelProps = {
  grouped: GroupedData;
  id: string;
  weight: string;
  sightNumber: string;
  selected: string;
  updateSelected: (value: string) => void;
  downloadLink: string;
  boxDownloadLink: string;
};

export const StoneLeftPanel = ({
  id,
  weight,
  sightNumber,
  selected,
  downloadLink,
  updateSelected,
  grouped,
  boxDownloadLink,
}: StoneLeftPanelProps) => {
  const [isModalOpenStone, setIsModalOpenStone] = useState(false);
  const [isModalOpenBox, setIsModalOpenBox] = useState(false);

  const handleOpenModalStone = () => {
    setIsModalOpenStone(true);
  };
  const handleOpenModalBox = () => {
    setIsModalOpenBox(true);
  };
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  const handleDownloadStone = () => {
    setDownloadList([downloadLink]);
    setIsModalOpenStone(false);
  };

  const handleDownloadBox = () => {
    setDownloadList([boxDownloadLink]);
    setIsModalOpenBox(false);
  };
  const modalButtonsStone = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpenStone(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadStone(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  const modalButtonsBox = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpenBox(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];
  return (
    <Col className={`stone-left-panel-wrapper ${window.innerWidth < tabletScreenSize ? "col-sm-12" : ""}`}>
      <Row className="description">
        <Col>
          <Row className="description-row">
            <Col className="caption-large-upper heavy">Rough stone details</Col>
          </Row>
          <div className="details">
            <div className="description-row body-small medium">
              <Col className="icon-block">
                <BarCode />
              </Col>
              <Col className=" body-small medium text_pad">ID: {id}</Col>
            </div>
            <div className="description-row">
              <Col className="icon-block body-small medium">
                <Weight />
              </Col>
              <Col className="body-small medium text_pad">Rough Weight: {weight}</Col>
            </div>
            <div className="description-row">
              <Col className="icon-block">
                <Calendar />
              </Col>
              <Col className="body-small medium text_pad">Sight: {sightNumber}</Col>
            </div>
          </div>

          <Row className="description-row smaller-padding">
            <Col className="caption-large-upper heavy">Polished fit</Col>
          </Row>
          <Row className="description-row">
            <div>
              {Object.keys(grouped).map((item, index) => {
                return (
                  <div key={index}>
                    <div className="sub-group-block">
                      {Diamonds[item.toLowerCase()]?.Icon}
                      <div className="caption-large-upper heavy">{Diamonds[item.toLowerCase()]?.Title}</div>
                    </div>
                    <Row>
                      <Col>
                        {(grouped[item] as PolyItem[]).map((element, subIndex) => {
                          const active = selected === element.id;
                          const statusClass = active ? "active" : "";
                          return (
                            <div
                              className={`stones-wrapper ${statusClass}`}
                              key={subIndex}
                              onClick={() => updateSelected(element.id)}
                            >
                              <div className="icon-item">
                                <FormCheck
                                  type="radio"
                                  className="diamond-size"
                                  checked={active}
                                  onChange={() => updateSelected(element.id)}
                                />
                              </div>
                              <div className="stones-parameters">
                                <div className="body-small medium weight long w74">{`${element.weight} ct`}</div>
                                <div className="body-small medium grade long w50">{element.clarity}</div>
                                <div className="body-small medium grade long w30">{element.color}</div>
                                <div className="body-small medium last first-letter-cap long w74">{element.flu}</div>
                              </div>
                            </div>
                          );
                        })}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </Row>
        </Col>
      </Row>
      <Row className="buttons-wrapper">
        <button className="btn-primary btn-wide flex1" onClick={handleOpenModalStone}>
          {ButtonText.DownloadStone}
          <ArrowDown />
        </button>
        <button className="btn-secondary btn-wide flex1" onClick={handleOpenModalBox}>
          {ButtonText.DownloadBox}
        </button>
      </Row>
      {isModalOpenStone && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadStone}
          modalContent={ModalTexts.ContentDownloadStone}
          buttons={modalButtonsStone}
          setIsModalOpen={setIsModalOpenStone}
        />
      )}
      {isModalOpenBox && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBox}
          modalContent={ModalTexts.ContentDownloadBox}
          buttons={modalButtonsBox}
          setIsModalOpen={setIsModalOpenBox}
        />
      )}
    </Col>
  );
};
