import { Col, Row } from "react-bootstrap";
import { DeBeersLogo, SymbolConfirmWhite } from "../GlobalIcons";
import { ButtonText, TACSubTitle, TACSubTitleConnect, TACSubTitleCurate } from "../../utils/consts";
import { Modal } from "../Modal";
import { useState } from "react";
import { useGetText } from "../../queries/useGetText";
import { Loader } from "../Loader";
import { TnCService } from "../../utils/models";
import { useSaveTnC } from "../../queries/useSaveTnC";
import { useQueryClient } from "react-query";

export type TermsAndConditionsProps = {
  tncKey: TnCService;
  contentLink: string;
  version: number;
};

export const convertTextToReact = (text: string): any => {
  const convertedText = unescape(encodeURIComponent(text));
  return (
    <>
      {convertedText.split("\n").map((item, index) => (
        <p key={index}>{item.trim()}</p>
      ))}
    </>
  );
};

export const TermsAndConditions = ({ tncKey, contentLink, version }: TermsAndConditionsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { isLoading, isError, data } = useGetText(contentLink);
  const mutationSave = useSaveTnC();
  const queryClient = useQueryClient();
  const handleAccept = async () => {
    await mutationSave.mutateAsync({
      version: version,
      type: tncKey,
    });
    await queryClient.invalidateQueries("user");
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return null;
  }
  if (isLoading || !data || isError) {
    return <Loader hasError={isError} />;
  }

  let subTitle = TACSubTitle;
  if (tncKey === TnCService.AlphaConnect) {
    subTitle = TACSubTitleConnect;
  }
  if (tncKey === TnCService.AlphaCurate) {
    subTitle = TACSubTitleCurate;
  }

  return (
    <Modal
      isBig={true}
      hideClose={true}
      modalHeader={
        <Row className={"tac"}>
          <Col>
            <DeBeersLogo />
          </Col>
          <Col className={"modal-sub-header"}>{subTitle}</Col>
        </Row>
      }
      modalContent={convertTextToReact(data)}
      buttons={[
        {
          text: `${ButtonText.AcceptAndContinue}`,
          onClick: () => handleAccept(),
          class: "btn-primary btn-wide",
          symbol: <SymbolConfirmWhite />,
        },
      ]}
      setIsModalOpen={setIsModalOpen}
    />
  );
};
