import { StoneItem } from "../../utils/models";
import { Col, Row } from "react-bootstrap";
import { StoneItemContent } from "./StoneItemContent";
import { CuratedStonesTableHead, PaginationStartNumber } from "../../utils/consts";
import { Pagination } from "..";
import { useState } from "react";

type StoneListSelectionProps = {
  stones: StoneItem[];
  selectedStone: string;
  setSelectedStone: (value: string) => void;
  selectedTab: number;
};

export const StoneListSelection = ({
  stones,
  selectedStone,
  setSelectedStone,
  selectedTab,
}: StoneListSelectionProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const itemsPerPage = PaginationStartNumber;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = stones.slice(startIndex, endIndex);

  return (
    <>
      <Row className="description-row">
        <Col>
          <Row>
            {selectedTab === 0 && (
              <>
                <Col xs={12}>
                  <div className="offer-table-headers stones-wrapper">
                    <div className="diamond-size">
                      <span></span>
                    </div>

                    <div className="stones-parameters top">
                      <div className="body-small heavy last first-letter-cap wi70">
                        {CuratedStonesTableHead.StoneId}
                      </div>

                      <div className="body-small heavy weight wi60">{CuratedStonesTableHead.RWeight}</div>
                      <div className="body-small heavy last first-letter-cap wi60">{CuratedStonesTableHead.RColor}</div>
                      <div className="body-small heavy last first-letter-cap wi80">{CuratedStonesTableHead.RFlu}</div>
                      <div className="body-small heavy last first-letter-cap wi60">{CuratedStonesTableHead.PShape}</div>
                      <div className="body-small heavy last first-letter-cap wi60">
                        {CuratedStonesTableHead.PWeight}
                      </div>
                      <div className="body-small heavy last first-letter-cap wi60">
                        {CuratedStonesTableHead.PClarity}
                      </div>
                      <div className="body-small heavy last first-letter-cap wi60">{CuratedStonesTableHead.PMake}</div>
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  {paginatedData.map((element, subIndex) => (
                    <StoneItemContent
                      key={subIndex}
                      active={selectedStone === element.id}
                      setSelectedStone={setSelectedStone}
                      element={element}
                    />
                  ))}
                </Col>
                {stones.length > itemsPerPage && (
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    allItems={stones.length}
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </>
            )}
            {selectedTab === 1 && <Col></Col>}
          </Row>
        </Col>
      </Row>
    </>
  );
};
