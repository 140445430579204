import moment from "moment-timezone";
import { basicTableDateFormat } from "../../utils/consts";
import { RequestItem } from "../../utils/models";
import { Calendar, ClockTimeIcon, PriceIcon } from "../GlobalIcons";

type Button = {
  text: string;
  onClick: () => void;
  class: string;
  symbol?: any;
};
export type ManageOfferInfoBarProps = {
  order: RequestItem;
  buttons?: Button[];
};

export const ManageOfferInfoBar = ({ order, buttons }: ManageOfferInfoBarProps) => {
  return (
    <div className="offer-info-bar">
      <div className="main-info-block">
        <div className="info-block body-small medium">
          <div className="icon">
            <PriceIcon />
          </div>
          <div className="text">Offer Price: $ {order.offer?.price}</div>
        </div>
        <div className="info-block body-small medium">
          <div className="icon">
            <Calendar />
          </div>
          <div className="text">
            Expected Delivery Date: {moment(order.offer?.expectedDeliveryDate).format(basicTableDateFormat)}
          </div>
        </div>
        <div className="info-block body-small medium">
          <div className="icon">
            <ClockTimeIcon />
          </div>
          <div className="text">Offer Valid Until: {moment(order.offer?.validTo).format(basicTableDateFormat)}</div>
        </div>
      </div>
      <div className="buttons-wrapper">
        {buttons &&
          buttons.map((but, index) => {
            return (
              <button className={`${but.class}`} onClick={but.onClick} key={index}>
                {but.text}
                {but.symbol}
              </button>
            );
          })}
      </div>
    </div>
  );
};
