interface FeatureBoxProps {
  icon: any;
  btnText: string;
  onClick?: () => void;
  active?: boolean;
}

export const FeatureBox = (props: FeatureBoxProps): JSX.Element => {
  return (
    <div className={`feature-box ${props.active ? "active-feature" : ""}`}>
      {props.icon}
      <div className="box-title body-x-small medium">{props.btnText}</div>
    </div>
  );
};
