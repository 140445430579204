import { Row } from "react-bootstrap";
import { ButtonText, ModalTexts, NewRequestCurateConfirmText, NewRequestCurateConfirmTextError } from "../../utils/consts";
import { Checkbox, Modal, Plus, SymbolNext } from "../";

export const NewRequestConfirmation = ({
  isNew,
  setNewEmptyRequest,
  openHomePage,
  handleCheckbox,
  handleConfirmRequest,
  showCheckboxError,
  agreeTerms,
  isModalOpen,
  setIsModalOpen,
}: {
  isNew: boolean;
  setNewEmptyRequest: () => void;
  openHomePage: () => void;
  handleCheckbox: (checked: boolean) => void;
  handleConfirmRequest: () => void;
  showCheckboxError: boolean;
  agreeTerms: boolean;
  setAgreeTerms: (value: boolean) => void;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}) => {
  
  const modalButtonsRequest = [
    {
      text: `${ButtonText.ReturnToRequestList}`,
      onClick: () => {
        setIsModalOpen(false);
        openHomePage();
      },
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.AnotherRequest}`,
      onClick: () => {
        setIsModalOpen(false);
        setNewEmptyRequest();
      },
      class: "btn-primary btn-wide",
      symbol: <Plus />,
    },
  ];

  return (
    <>
      <div className="request-confirmation-block">
        <Row>
          <div className="terms-confirmation">
            <Checkbox onChange={handleCheckbox} isChecked={agreeTerms} error={showCheckboxError} />
            <div className="body-small medium terms">{NewRequestCurateConfirmText}</div>
          </div>
          <Row>
            <div className={`checkbox-error body-small medium color-error ${showCheckboxError ? "" : "d-none"} `}>
              {NewRequestCurateConfirmTextError}
            </div>
          </Row>
        </Row>
        <Row className="">
          <div className="buttons-wrapper">
            <button className="btn-secondary" onClick={() => openHomePage()}>
              {ButtonText.Cancel}
            </button>
            <button className="btn-primary" onClick={handleConfirmRequest}>
              {isNew ? ButtonText.SubmitRequest : ButtonText.ConfirmUpdates} <SymbolNext />
            </button>
          </div>
        </Row>
        {isModalOpen && (
          <Modal
            hideClose={true}
            modalHeader={isNew ? ModalTexts.HeaderRequest : ModalTexts.HeaderRequestUpdate}
            modalContent={ModalTexts.ContentRequest}
            buttons={modalButtonsRequest}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </>
  );
};
