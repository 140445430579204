import {
  Asscher,
  Cushion,
  Emerald,
  Heart,
  Marquise,
  Oval,
  Pear,
  Princess,
  Radiant,
  Round,
} from "../components/GlobalIcons";

export enum AccessTypes {
  Connect,
  Curate,
  Manage,
}

export const PLYExt = ".ply";
export const VTPExt = ".vtp";
export const JPGExt = ".jpg";

export const defaultSurfaceColor: number[] = [0.784, 0.331, 0.071, 0.5];
export const defaultWireframeColor: number[] = [0.784, 0.331, 0.071, 0.5];
export const interpolateHoverDefaultColor: number[] = [0.953, 0.439, 0.129, 0.5];
export const surfaceColor: number[] = [0.388, 0.361, 0.31, 0.5];
export const meshColor: number[] = [0.905, 0.796, 0.596, 0.7];

export const caratRange = { min: 0.75, max: 9.99 };
export const colourRange = ["D", "E", "F", "G", "H", "I", "J"];
export const clarityRange = ["FL", "IF", "VVS1", "VVS2", "VS1", "SI1", "SI2"];
export const fluorescenceRange = ["None", "Faint", "Medium", "Strong", "Very Strong"];
export const makeRange = ["H&A", "XXX", "VG", "G"];

export const Diamonds: { [key: string]: { Title: string; Icon: any } } = {
  round: {
    Title: "Round",
    Icon: <Round />,
  },
  asscher: {
    Title: "Asscher",
    Icon: <Asscher />,
  },
  cushion: {
    Title: "Cushion",
    Icon: <Cushion />,
  },
  emerald: {
    Title: "Emerald",
    Icon: <Emerald />,
  },
  heart: {
    Title: "Heart",
    Icon: <Heart />,
  },
  marquise: {
    Title: "Marquise",
    Icon: <Marquise />,
  },
  oval: {
    Title: "Oval",
    Icon: <Oval />,
  },
  pear: {
    Title: "Pear",
    Icon: <Pear />,
  },
  princess: {
    Title: "Princess",
    Icon: <Princess />,
  },
  radiant: {
    Title: "Radiant",
    Icon: <Radiant />,
  },
};

export const Roles = {
  Connect: ["Access Alpha Connect Service", "Access all connect data"],
  Curate: ["Access Alpha Curate Service", "Access all curate data"],
  Manage: ["Access Alpha Manage Service"],
};

export const NoPlatformAccess = (
  <>
    Unfortunately, you don’t have access to STREAM services, you must become a subscriber. <br />
    Please contact us at info@companyname.com or call us at (000) 000-0000 and we will be happy to assist you in getting
    started.
  </>
);

export const ThereIsNoData = {
  ActiveBoxes:
    "Unfortunately, You don't currently have any active boxes available, but please check back soon as we will notify you as soon as new boxes become available via email or in the notification panel.",
  ManageRequests: (
    <>
      Unfortunately, have not yet had any requests. <br /> You will be notifying via email or in the notification panel
      when a new request is made.
    </>
  ),
  ManageOffers: "Unfortunately, have not yet had any offers.",
  CurateRequests:
    "Unfortunately, you don’t have any requests yet. To receive the offer, please send us a request, as we will notify you as soon as new offer become available via email or in the notification panel.",
};

export const serverDateTimeFormat = "YYYY-MM-DD[T]HH:mm:ss";
export const basicTableDateFormat = "DD.MM.YYYY";
export const tabletScreenSize = 1279.99;
export const WiderTableScreen = 1399.99;

export const manageTableCols = [
  "Order Requested Date",
  "Request ID",
  "Sightholder Name",
  "Shape",
  "Quantity",
  "Request Delivery Date",
  "Request Price $",
  "Status",
];

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
  None = "None",
}

export const AdditionalConfig = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  structuralSharing: false,
  keepPreviousData: false,
  fetchPolicy: "network-only",
};

export const cacheTime = 10 * 60 * 1000;

export enum ConnectTableSortValue {
  RoughWeight = "Rough Weight",
  RoughColour = "Rough Colour",
  RoughFlu = "Rough Flu",
}

export const ManageTableSortValue = {
  RequestDate: "Order Requested Date",
  Status: "Status",
  OfferedDate: "Offered Date",
};

export enum ConnectTableValueReturn {
  RoughWeight = "weight",
  RoughColour = "color",
  RoughFlu = "flu",
}

export const ManageTableSortValueReturn = {
  RequestDate: "date",
  Status: "status",
  OfferedDate: "offerDate",
};

export const ManageOfferTableSortValues = {
  RWeight: "RWeight",
  RColour: "RColour",
  RClarity: "RClarity",
  RFlu: "RFlu",
  PShape: "PShape",
  PWeight: "PWeight",
  PColour: "PColour",
  PClarity: "PClarity",
  PFlu: "PFlu",
  Make: "Make",
};

export const ManageOfferTableSortValuesReturn = {
  RWeight: "weight",
  RColour: "color",
  RClarity: "clarity",
  RFlu: "flu",
  PShape: "pshape",
  PWeight: "pweight",
  PColour: "pcolor",
  PClarity: "pclarity",
  PFlu: "pflu",
  Make: "pmake",
};

export enum PlatformName {
  Connect = "Connect",
  Curate = "Curate",
  Manage = "Manage",
}

export enum PlatformHeadText {
  Connect = "STREAM Products & Services",
  Curate = "CHange text in consts",
  Manage = "Change text in consts",
  Basic = "STREAM Products & Services",
}

export enum PlatformSubTitle {
  Connect = "Get polished fit for your rough stones",
  Curate = "Request your preferred diamonds and get an offer",
  Manage = "Manage requests and prepare an offer",
}

export enum ButtonText {
  Launch = "Launch",
  Next = "Next",
  RoughStones = "Rough Stones",
  DownloadBox = "Download Box",
  Review = "Review",
  Download = "Download",
  DownloadStone = "Download Stone",
  DownloadSelected = "Download Selected",
  Reject = "Reject",
  Cancel = "Cancel",
  Edit = "Edit",
  Confirm = "Confirm",
  ConfirmUpdates = "Confirm Updates",
  AcceptOffer = "Accept Offer",
  Submit = "Submit",
  SubmitRequest = "Submit Request",
  HomePage = "Homepage",
  AnotherRequest = "Another Request",
  AcceptAndContinue = "Accept and Continue",
  ToHomePage = "To Homepage",
  Details = "Details",
  Decline = "Decline Request",
  ReturnToRequestList = "Return to Request List",
  SendOffer = "Send Offer",
  Withdrawn = "Withdraw",
  RestoreOffer = "Restore Offer",
  ExtendOfferDate = "Extend Offer Date",
  NotOffered = "Not Offered",
  ReturnToStream = "Return to STREAM Homepage",
  ReviewRequests = "Review Requests",
  NewRequest = "New Request",
  LogOut = "Log Out",
}

export const Second = 1000;
export const apiCallResolveMargin = 3 * Second;
export const logoutKey = "logout";

export const TACSubTitle = "Before you continue to the STREAM Platform";
export const TACSubTitleConnect = "Before you continue to the Connect";
export const TACSubTitleCurate = "Before you continue to the Curate";

export enum RequestStatus {
  Offered = "offered",
  Submitted = "submitted",
  Cancelled = "cancelled",
  Accepted = "accepted",
  Declined = "declined",
  Acknowledged = "acknowledged",
  Withdrawn = "withdrawn",
  NotOffered = "not offered",
  Expired = "expired",
  InProgress = "in progress",
}

export enum CurateOrderDetailBox {
  NewRequest = "New Request",
  Head = "Details",
  ID = "ID: ",
  Price = "Offer Price $: ",
  Stones = "Stones: ",
  ValidUntil = "Offer Valid Until: ",
}

export enum PlatformPageLink {
  Connect = "/active-boxes/",
  Curate = "/curate/my-offers/",
  Manage = "/manage/",
}

export enum CuratePageText {
  OffersHeading = "My Offers",
  OffersSubtitle = "Below are the boxes which have been curated for you and you need to accept or reject",
  RequestsHeading = "Requests",
  RequestsSubtitle = "Below is your history of curated boxes",
}

export enum CurateTableSortValue {
  OrderRequestedDate = "Order Requested Date",
}

export enum CurateTableSortValueReturn {
  OrderRequestedDate = "date",
}

export enum NavbarText {
  Services = "Services",
  Notifications = "Notifications",
  ActiveBoxes = "Active Boxes",
  Requests = "Requests",
  NewRequest = "New Request",
  Offers = "Offers",
  PreviousBox = "Previous Box",
  NextBox = "Next Box",
  Filter = "Filter",
  Download = "Download",
  BactToList = "Back to List",
  Export = "Export",
  LogOut = "Log Out",
}

export enum NavBarLinks {
  Home = "/",
  Connect = "/active-boxes/",
  Curate = "/curate/",
  Manage = "/manage/",
}

export const ModalTexts = {
  HeaderDownload: "Preparing to Download",
  ContentDownload: "It will take few minutes to download",
  HeaderDownloadStone: "Download the Stone Data",
  ContentDownloadStone: (
    <>
      <p>
        This file includes all the details you need to know about the rough stone, as well as 3D polished fit view files
        and 2D images.
      </p>
      <p>Please be aware that this download may take some time due to the large size of the file.</p>
    </>
  ),
  HeaderDownloadBox: "Download All Stones Data from the Box",
  ContentDownloadBox: (
    <>
      <p>
        This file includes all the details you need to know about the all rough stones in the box, as well as 3D
        polished fit view files for each rough stone and 2D images.
      </p>
      <p>Please be aware that this download may take some time due to the large size of the file.</p>
    </>
  ),
  HeaderDownloadBoxCurate: "Download All Curated Stones Data",
  ContentDownloadBoxCurate: (
    <>
      <p>
        This file includes all the details you need to know about the all curated stones in the box with 3D view and 2D
        images.
      </p>
      <p>Please be aware that this download may take some time due to the large size of the file.</p>
    </>
  ),
  HeaderAcceptOffer: "Accept the Offer",
  ContentAcceptOffer: "Are you sure you want to accept this offer?",
  HeaderRejectOffer: "Reject the Offer",
  ContentRejectOffer: "Are you sure you want to reject the offer?",
  HeaderRequest: "Request has been sent",
  HeaderRequestUpdate: "Request has been updated and sent",
  ContentRequest: "STREAM team will review your request soon",
  HeaderCancel: "Cancel the Request",
  ContentCancel: "Are you sure you want to cancel the request?",
  HeaderDecline: "Decline Request",
  ContentDecline: "Are you sure you want to decline the request?",
  HeaderWithdrawn: "Withdraw offer?",
  ContentWithdrawn: "Are you sure you want to withdraw the offer?",
  HeaderRestoreOffer: "Restore the Offer",
  ContentRestoreOffer: "Select Confirm to restore the Offer for the Sightholder",
  HeaderExtendOffer: "Extend offer modal?",
  HeaderExport: "Download New Requests",
  ContentExport: `This file includes all new requests with a status of "Submitted" in .CSV file format.`,
  HeaderNotOffered: "Not Offered?",
  ContentNotOffered: "Are you sure you want to set the request as Not Offered?",
  HeaderLogOut: "Logout Confirmation",
  ContentLogOut: "Are you sure you want to log out of the platform?",
};

export const RequestErrors = {
  Shape: "Please select one of the available shapes",
  Quantity: "Please include a stones quantity for the request",
  Price: "Please include a request price for your request",
  Date: "Please include a delivery date for your request",
  TermsAndServices:
    "Please check the box to confirm that you have read and agree with the terms of service before submitting the form",
};

export const ErrorPageMessages = {
  Header: "Oops...",
  Error404: "404 Error",
  Message404: (
    <>
      We can’t seem to find the page you’re looking for. <br /> Don't worry, you will be redirected to the homepage in 5
      seconds.
    </>
  ),
};

export const OfferErrors = {
  Price: "Please include a offer price",
  DeliveryDate: "Please include a expected delivery date",
  ValidUntil: "Please include a offer valid until date",
};

export const ManageOfferStatusText = {
  NotOffered: "Unfortunately, the request was not offered.",
  Cancelled: "Unfortunately, the request was canceled by Sightholder.",
  Acknowledged: "Take action from the backend to upload the stones and move forward with the process...",
  Declined: "Offer was DECLINED",
};

export const CuratedStonesTableHead = {
  StoneId: "Stone ID",
  RWeight: "RWeight",
  RColor: "RColour",
  RFlu: "RFlu",
  PShape: "PShape",
  PWeight: "PWeight",
  PClarity: "PClarity",
  PMake: "PMake",
};

export const NewRequestCurateConfirmText =
  " I have reviewed my request and agree to the STREAM Terms of Service and Privacy Policy";

export const NewRequestCurateConfirmTextError =
  "Please check the box to confirm that you have read and agree with the terms of service before submitting the form";

export const PlatformTermsAndConditions = {
  Stream: "STREAM Terms & Conditions",
  Connect: "Connect Terms & Conditions",
  Curate: "Curate Terms & Conditions",
};

export const PaginationStartNumber = 100;
