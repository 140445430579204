import { NavbarText } from "../../utils/consts";
import { Services, FeatureBox } from "../";

export const NavBarBasic = () => {
  return (
    <>
      <FeatureBox icon={<Services />} btnText={NavbarText.Services} />
    </>
  );
};
