import { useQuery, UseQueryResult } from "react-query";
import RequestsData from "../mocks/curateRequests.json";
import { RequestItem, RequestItemRaw } from "../utils/models";
import { AdditionalConfig } from "../utils/consts";
import Server from "../config/server.json";
import Config from "../config/auth.json";
import axios from "axios";
import { serializeCurateItem } from "../utils/serializers";

const fetch = async ({ queryKey }: { queryKey: any[] }): Promise<RequestItemRaw> => {
  const [, id] = queryKey;
  if (Server.useMocks) {
    const item = RequestsData.find((el) => el.requestId === id);
    if (item) {
      return item;
    }
    return RequestsData[0];
  }
  const url = Config.endpoint + Server.getCurateRequestItem.replace(":id", id);
  const response = await axios.get(url);
  return response.data;
};

export const useGetCurateRequestItem = (id?: string): UseQueryResult<RequestItem> => {
  return useQuery(["requestItem", id], fetch, {
    enabled: !!id && id !== "new",
    select: serializeCurateItem,
    ...AdditionalConfig,
  });
};
