import { Index } from "./screens/Index";
import { ActiveBoxWrapper } from "./screens/Connect";
import { StoneListWrapper } from "./screens/StoneListConnect";
import { StoneDetail } from "./screens/StoneDetail";
import { OfferScreen } from "./screens/Offer";
import { NewRequestWrapper } from "./screens/NewRequestCurate";
import { MyOffersCurateWrapper } from "./screens/MyOffersCurate";
import { AccessTypes } from "./utils/consts";
import { Page404 } from "./screens/404";
import { Manage } from "./screens/Manage";
import { ManageOffersScreen } from "./screens/ManageOffers";
import { ManageStatus } from "./screens/ManageStatus";

export const routes: { path: string; component: any; hidePanel?: boolean; access?: AccessTypes }[] = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/active-boxes",
    component: ActiveBoxWrapper,
    access: AccessTypes.Connect,
  },
  {
    path: "/active-boxes/:boxID/",
    component: StoneListWrapper,
    access: AccessTypes.Connect,
  },
  {
    path: "/active-boxes/:boxID/:stoneID/v3d/",
    component: StoneDetail,
    access: AccessTypes.Connect,
  },
  {
    path: "/curate/my-offers/",
    component: MyOffersCurateWrapper,
    access: AccessTypes.Curate,
  },
  {
    path: "/curate/my-offers/:id",
    component: OfferScreen,
    access: AccessTypes.Curate,
  },
  {
    path: "/curate/my-offers/request/:id",
    component: NewRequestWrapper,
    access: AccessTypes.Curate,
  },
  {
    path: "/manage",
    component: Manage,
    access: AccessTypes.Manage,
  },
  {
    path: "/manage/:id",
    component: ManageStatus,
    access: AccessTypes.Manage,
  },
  {
    path: "/manage/offers",
    component: ManageOffersScreen,
    access: AccessTypes.Manage,
  },
  {
    path: "*",
    component: Page404,
    hidePanel: true,
  },
];
