import { PlatformTermsAndConditions } from "../../utils/consts";
import { Modal } from "../Modal";
import { useGetUser } from "../../queries";
import { Loader } from "../Loader";
import { TnCService } from "../../utils/models";
import { getTnCLink } from "../../layouts/access";
import { useGetText } from "../../queries/useGetText";
import { convertTextToReact } from "./index";

export const NotRequiredTnC = ({ type, onClose }: { type: TnCService; onClose: () => void }) => {
  const { isLoading, data: user, isError } = useGetUser();
  let path = "";

  if (user) {
    const tncLink = getTnCLink(user, type, false);
    path = tncLink?.path || "";
  }
  const { isLoading: isLoadingText, isError: isErrorText, data: text } = useGetText(path);

  if (isLoading || isLoadingText || isError || isErrorText || !user || !text) {
    return <Loader hasError={isError || isErrorText} />;
  }

  let title = PlatformTermsAndConditions.Stream;
  if (type === TnCService.AlphaConnect) {
    title = PlatformTermsAndConditions.Connect;
  }
  if (type === TnCService.AlphaCurate) {
    title = PlatformTermsAndConditions.Curate;
  }
  return (
    <Modal
      setIsModalOpen={() => onClose()}
      modalHeader={title}
      modalContent={convertTextToReact(text)}
      isBig={true}
      headerLeft={true}
    />
  );
};
