import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import { AdditionalConfig } from "../utils/consts";
import Server from "../config/server.json";
import { addToCache, getFromCache } from "../utils/helpers";

const fetchFile = async ({ queryKey }: any): Promise<string[]> => {
  const [, fileNames] = queryKey;
  if (Server.useMocks) {
    return Array.isArray(fileNames) ? fileNames : [fileNames];
  }
  let result: Promise<string>[] = [];
  if (Array.isArray(fileNames)) {
    result = fileNames.map((fileName) => {
      return axios
        .get(fileName, {
          responseType: "text",
        })
        .then((response) => response.data);
    });
  } else {
    const cacheItem = getFromCache(fileNames);
    if (cacheItem) {
      result.push(Promise.resolve(cacheItem));
    } else {
      result.push(
        axios
          .get(fileNames, {
            responseType: "text",
          })
          .then((response) => response.data)
          .then((data) => {
            addToCache(fileNames, data);
            return data;
          }),
      );
    }
  }

  return Promise.all(result);
};

export const useGetZipPath = (fileNames?: string | string[]): UseQueryResult<string[]> => {
  return useQuery(["zipFilePath", fileNames], fetchFile, {
    enabled: Array.isArray(fileNames) ? fileNames.length > 0 : !!fileNames,
    ...AdditionalConfig,
  });
};
