import { useState } from "react";
import { SortButton, Loader, Modal, Refresh, SymbolConfirm, TableWithPaginationWrapper } from "../../components";

import {
  ButtonText,
  ManageTableSortValue,
  ModalTexts,
  PaginationStartNumber,
  RequestStatus,
  SortDirection,
  basicTableDateFormat,
} from "../../utils/consts";
import { RequestItem } from "../../utils/models";
import { useQueryClient } from "react-query";
import { useGetBoxList, useSaveRequest } from "../../queries";
import { useNavigate } from "react-router-dom";
import { copy, formatDate, formatPrice } from "../../utils/helpers";
import moment from "moment-timezone";

export const ManageOffersTable = ({ data }: { data: RequestItem[] }) => {
  const [sortTableDir, setSortTableDir] = useState<SortDirection.ASC | SortDirection.DESC>(SortDirection.DESC);

  const filteredOfferData: RequestItem[] = data.filter((item) =>
    [
      RequestStatus.Accepted.toString(),
      RequestStatus.Declined.toString(),
      RequestStatus.Withdrawn.toString(),
      RequestStatus.Expired.toString(),
      RequestStatus.Offered.toString(),
    ].includes(item.status.toLowerCase()),
  );

  const { isLoading, data: boxes, isError } = useGetBoxList();
  const [sortTableColumn, setSortTableColumn] = useState<string>(ManageTableSortValue.OfferedDate);

  const navigate = useNavigate();
  const saveMutation = useSaveRequest();
  const queryClient = useQueryClient();
  const [isWithdrawnModalOpen, setIsWithdrawnModalOpen] = useState(false);
  const [isRestoreOfferModalOpen, setIsRestoreOfferModalOpen] = useState(false);

  const [editableOffer, setEditableOffer] = useState<RequestItem>();

  if (isLoading || !Array.isArray(boxes)) {
    return <Loader hasError={isError} />;
  }

  const sortedData = filteredOfferData.sort(
    (a: RequestItem, b: RequestItem) =>
      (sortTableDir === SortDirection.DESC ? -1 : 1) * moment(a.date).diff(moment(b.date), "seconds"),
  );

  const handleSortClick = (colName: string, sortTableDir: string) => {
    setSortTableColumn(colName);
    if (sortTableDir === SortDirection.DESC) {
      setSortTableDir(SortDirection.ASC);
    } else {
      setSortTableDir(SortDirection.DESC);
    }
  };

  const manageTableCols = [
    <SortButton
      colName={"Offered Date"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "Offered Date" ? sortTableDir : SortDirection.None}
    />,
    "Request ID",
    "Sightholder Name",
    "PShape",
    "Quantity",
    "Expected Delivery Date",
    "Offer Price $",
    "Status",
    "",
  ];

  const handleOpenModalWithdrawn = (order: RequestItem) => {
    setIsWithdrawnModalOpen(true);
    setEditableOffer(order);
  };

  const withdrawnOffer = async () => {
    if (editableOffer) {
      const newWithdrawnOffer = copy(editableOffer);
      newWithdrawnOffer.status = RequestStatus.Withdrawn;
      await saveMutation.mutateAsync(newWithdrawnOffer);
      await queryClient.invalidateQueries("requestList");
      setIsWithdrawnModalOpen(false);
    }
  };

  const modalWithdrawnButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => setIsWithdrawnModalOpen(false),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Withdrawn}`,
      onClick: () => withdrawnOffer(),
      class: "btn-primary-warning btn-wide",
    },
  ];

  const handleOpenModalRestoreOffer = (order: RequestItem) => {
    setIsRestoreOfferModalOpen(true);
    setEditableOffer(order);
  };

  const restoreOffer = async () => {
    if (editableOffer) {
      const newRestoreOffer = copy(editableOffer);
      newRestoreOffer.status = RequestStatus.Offered;
      await saveMutation.mutateAsync(newRestoreOffer);
      await queryClient.invalidateQueries("requestList");
      setIsRestoreOfferModalOpen(false);
    }
  };

  const modalRestoreOfferButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => setIsRestoreOfferModalOpen(false),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Confirm}`,
      onClick: () => restoreOffer(),
      class: "btn-primary btn-wide",
      symbol: <SymbolConfirm />,
    },
  ];
  return (
    <div className="manage-offer-table">
      <TableWithPaginationWrapper
        itemsPerPage={PaginationStartNumber}
        allItems={sortedData.length}
        stickyCols={2}
        columns={manageTableCols}
        data={sortedData.map((order) => {
          const onSelect = () => {
            navigate(`/manage/${order.id}`);
          };
          const formattedDate = formatDate(order.offer?.offerDate || "");
          return [
            {
              value: formattedDate,
              tdClass: "manage first-letter-cap cell_1",
              onClick: onSelect,
            },
            { value: order.id, tdClass: "cell_2 first-letter-cap", onClick: onSelect },
            { value: order.sightHolder.name.toLowerCase(), tdClass: "cell_3 first-letter-cap", onClick: onSelect },
            { value: order.shape.toLowerCase(), tdClass: "first-letter-cap cell_4", onClick: onSelect },
            { value: order.quantity, tdClass: "first-letter-cap cell_5", onClick: onSelect },
            {
              value: moment(order.offer?.expectedDeliveryDate).format(basicTableDateFormat),
              tdClass: "first-letter-cap cell_5",
              onClick: onSelect,
            },
            { value: formatPrice(order.offer?.price as string), tdClass: "first-letter-cap cell_5", onClick: onSelect },
            { value: order.status, tdClass: "first-letter-cap cell_10 sticky-cell2", onClick: onSelect },
            {
              value: (
                <div className="buttons-wrapper">
                  {order.status.toLowerCase() === RequestStatus.Offered && (
                    <button className="btn-primary-warning-small" onClick={() => handleOpenModalWithdrawn(order)}>
                      {ButtonText.Withdrawn}
                    </button>
                  )}
                  {order.status.toLowerCase() === RequestStatus.Withdrawn && (
                    <button className="btn-secondary-small" onClick={() => handleOpenModalRestoreOffer(order)}>
                      <Refresh />
                      {ButtonText.RestoreOffer}
                    </button>
                  )}
                </div>
              ),
              tdClass: "cell_11 buttons_status_col sticky-cell1",
            },
          ];
        })}
      />
      {isWithdrawnModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderWithdrawn}
          modalContent={ModalTexts.ContentWithdrawn}
          buttons={modalWithdrawnButtons}
          setIsModalOpen={setIsWithdrawnModalOpen}
        />
      )}
      {isRestoreOfferModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderRestoreOffer}
          modalContent={ModalTexts.ContentRestoreOffer}
          buttons={modalRestoreOfferButtons}
          setIsModalOpen={setIsRestoreOfferModalOpen}
        />
      )}
    </div>
  );
};
