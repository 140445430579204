import { useGetCurateRequests } from "../../queries";
import { MyOffersCurate } from "./MyOffersCurate";
import { Loader } from "../../components/";

export const MyOffersCurateWrapper = () => {
  const { isLoading, data, isError } = useGetCurateRequests();
  if (isLoading || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }
  return <MyOffersCurate data={data} />;
};
