import { useState } from "react";
import { Pagination, PaginationProps, StoneTable, StoneTableProps } from "..";

export const TableWithPaginationWrapper = ({
  columns,
  data,
  stickyCols,
  itemsPerPage,
  allItems,
}: StoneTableProps & PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <>
      <StoneTable columns={columns} data={paginatedData} stickyCols={stickyCols} />

      {allItems > itemsPerPage && (
        <Pagination
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          allItems={allItems}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};
