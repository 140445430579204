import { Close } from "../";
import { ReactNode } from "react";

type Button = {
  text: string;
  onClick: () => void;
  class: string;
  symbol?: any;
};

type ModalProps = {
  setIsModalOpen?: ((arg0: boolean) => void) | undefined;
  modalHeader?: string | ReactNode;
  modalContent?: string | ReactNode;
  buttons?: Button[];
  hideClose?: boolean;
  isBig?: boolean;
  headerLeft?: boolean;
};

export const Modal = (props: ModalProps) => {
  const handleCloseModal = () => {
    if (props.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
  };

  const handleCloseOutsideModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!props.hideClose) {
      if (event.target === event.currentTarget) {
        if (props.setIsModalOpen) {
          props.setIsModalOpen(false);
        }
      }
    }
  };

  return (
    <div className="modal-overlay" onClick={handleCloseOutsideModal}>
      <div className={"modal-box" + (props.isBig ? " big" : "") + (props.headerLeft ? " left": "")}>
        <div className="modal-header">
          <h3 className="light">{props.modalHeader}</h3>
          <button className="close" onClick={handleCloseModal}>
            {!props.hideClose && <Close />}
          </button>
        </div>
        <div className="modal-body body-large medium">{props.modalContent}</div>
        <div className="modal-footer">
          {props.buttons &&
            props.buttons.map((modal, index) => {
              return (
                <button className={`${modal.class}`} onClick={modal.onClick} key={index}>
                  {modal.text}
                  {modal.symbol}
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};
