import { useCallback, useEffect, useRef, useState } from "react";
import { ImageContent } from "../../utils/models";
import { ChevronLeft, ChevronRight, Minus, Plus } from "../";

const offsetStep = 25;
const zoomStep = 50;
const baseZoom = 0;
const baseOffset = 0;

// temp variable for moving
let moving = false;
let x = 0;
let y = 0;
let offsetX = 0;
let offsetY = 0;

export const View2D = ({ images }: { images: ImageContent[] }) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(baseZoom);
  const [left, setLeft] = useState<number>(baseOffset);
  const [top, setTop] = useState<number>(baseOffset);
  const [maxTop, setMaxTop] = useState<number>(baseOffset);
  const [maxLeft, setMaxLeft] = useState<number>(baseOffset);
  const imageRef = useRef(null);
  const wrapperRef = useRef(null);
  const canMove = zoom > baseZoom;

  const nextImage = () => {
    setZoom(baseZoom);
    const newImageNumber = selectedImage - 1;
    if (newImageNumber < 0) {
      setSelectedImage(images.length - 1);
    } else {
      setSelectedImage(newImageNumber);
    }
  };

  const prevImage = () => {
    setZoom(baseZoom);
    const newImageNumber = selectedImage + 1;
    if (newImageNumber >= images.length) {
      setSelectedImage(0);
    } else {
      setSelectedImage(newImageNumber);
    }
  };

  const zoomIn = useCallback(() => {
    setZoom(zoom + 1);
  }, [zoom]);
  const zoomOut = useCallback(() => {
    if (zoom > baseZoom) {
      setZoom(zoom - 1);
    }
  }, [zoom, setZoom]);

  const onWheel = useCallback(
    (e: WheelEvent) => {
      const delta = Math.sign(e.deltaY);
      if (delta > 0) {
        zoomOut();
      } else {
        zoomIn();
      }
    },
    [zoomOut, zoomIn],
  );

  const mouseMove = useCallback((e: MouseEvent) => {
    if (moving) {
      const newX = e.clientX - x;
      const newY = e.clientY - y;
      setLeft(newX + offsetX);
      setTop(newY + offsetY);
    }
  }, []);

  // updating mouseMove do too much refresh dom, so, move it to mousedown
  const mouseDown = useCallback(
    (e: MouseEvent) => {
      if (canMove) {
        moving = true;
        x = e.clientX;
        offsetX = left;
        offsetY = top;
        y = e.clientY;
      }
    },
    [left, top, canMove],
  );
  const mouseUp = useCallback(() => {
    moving = false;
  }, []);

  useEffect(() => {
    let localRef: any = null;
    if (imageRef.current) {
      localRef = imageRef.current;
    }
    localRef?.addEventListener("wheel", onWheel);
    window.addEventListener("mousedown", mouseDown);
    window.addEventListener("mouseup", mouseUp);
    window.addEventListener("mousemove", mouseMove);

    return () => {
      localRef?.removeEventListener("wheel", onWheel);
      window.removeEventListener("mousedown", mouseDown);
      window.removeEventListener("mouseup", mouseUp);
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [onWheel, mouseDown, mouseUp, mouseMove]);

  useEffect(() => {
    let localRef: any = null;
    let localRefWrapper: any = null;
    if (imageRef.current) {
      localRef = imageRef.current;
    }
    if (wrapperRef.current) {
      localRefWrapper = wrapperRef.current;
    }
    const rect = localRef?.getBoundingClientRect();
    const wrapperRect = localRefWrapper?.getBoundingClientRect();

    let newMaxTop = 0;
    let newMaxLeft = 0;
    if (rect && wrapperRect) {
      newMaxTop = wrapperRect.height - rect.height;
      newMaxLeft = wrapperRect.width - rect.width;
    }
    setMaxLeft(newMaxLeft);
    setMaxTop(newMaxTop);
  }, [zoom]);

  let leftOffset = -1 * zoom * offsetStep + left;
  if (leftOffset > baseOffset) {
    leftOffset = baseOffset;
  }
  if (leftOffset < maxLeft) {
    leftOffset = maxLeft;
  }
  let topOffset = -1 * zoom * offsetStep + top;
  if (topOffset > baseOffset) {
    topOffset = baseOffset;
  }
  if (topOffset < maxTop) {
    topOffset = maxTop;
  }

  let url = "";
  if (images.length > selectedImage && images[selectedImage] && images[selectedImage].url) {
    url = images[selectedImage].url;
  }

  return (
    <div className="view-2d-wrapper" ref={wrapperRef}>
      <div
        ref={imageRef}
        className="view-2d-image"
        style={{
          cursor: canMove ? "move" : "default",
          backgroundImage: "url(" + url + ")",
          width: `calc(100% + ${zoom * zoomStep}px)`,
          height: `calc(100% + ${zoom * zoomStep}px)`,
          left: `${leftOffset}px`,
          top: `${topOffset}px`,
        }}
      />
      <button className="scroll-button left" onClick={prevImage}>
        <ChevronLeft />
      </button>
      <button className="scroll-button right" onClick={nextImage}>
        <ChevronRight />
      </button>
      <div className={"zoomIn special-icon-wrapper"} onClick={zoomIn}>
        <Plus />
      </div>
      <div className={"zoomOut special-icon-wrapper"} onClick={zoomOut}>
        <Minus />
      </div>
    </div>
  );
};
