import moment from "moment-timezone";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { InputField, DatePickerInput, Calendar } from "../";
import { RequestErrors, serverDateTimeFormat } from "../../utils/consts";

export type FormValues = {
  quantity: string;
  date: string;
};

export type NewRequestStoneDetailsProps = {
  formValues: FormValues;
  setFormValues: (value: FormValues) => void;
  isQuantityError: boolean;
  setIsQuantityError: (value: boolean) => void;
  isRequestedDeliveryDateError: boolean;
  setIsRequestedDeliveryDateError: (value: boolean) => void;
  isNew: boolean;
};

export const NewRequestStoneDetails = ({
  formValues,
  setFormValues,
  isQuantityError,
  setIsQuantityError,
  isRequestedDeliveryDateError,
  setIsRequestedDeliveryDateError,
  isNew,
}: NewRequestStoneDetailsProps) => {
  const [dateValue, onChangeDateValue] = useState<any>(formValues.date);
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleChangeDate = (date: any) => {
    onChangeDateValue(date);
    setIsRequestedDeliveryDateError(false);
    setFormValues({
      ...formValues,
      date: moment(date).format(serverDateTimeFormat),
    });
  };

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (name === "quantity") {
      setIsQuantityError(false);
    }
  };

  useEffect(() => {
    if (formValues.date === "") {
      onChangeDateValue(undefined);
      setOpenCalendar(false);
    } else {
      onChangeDateValue(formValues.date);
      if (moment().isSame(moment(formValues.date), "day")) {
        if (isNew) {
          setOpenCalendar(false);
        } else {
          setOpenCalendar(true);
        }
      }
      if (moment().isAfter(moment(formValues.date), "day")) {
        setOpenCalendar(true);
        setFormValues({ ...formValues, date: moment().format(serverDateTimeFormat) });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formValues, isNew]);

  const handleAllowInput = (event: any) => {
    const isAllowed = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].includes(event.code)
      ? true
      : !isNaN(Number(event.key)) && event.code !== "Space";
    if (!isAllowed) {
      event.preventDefault();
    }
  };

  const quantityFilled = formValues.quantity.length > 0;

  return (
    <>
      <div className="stone-details-box color-primary">
        <div className="box-header heavy caption-large-upper">Request details</div>
        <Row>
          <Col>
            <InputField
              label="Quantity"
              name="quantity"
              type="number"
              onChange={handleChangeInput}
              value={formValues.quantity}
              filled={quantityFilled}
              error={isQuantityError}
              onInput={handleAllowInput}
            />
            {isQuantityError && (
              <div className="input-error-message color-error body-small medium"> {RequestErrors.Quantity}</div>
            )}
          </Col>
          <Col>
            <DatePickerInput
              label={"Delivery Date"}
              dateValue={dateValue}
              onChangeDateValue={onChangeDateValue}
              handleChangeDate={handleChangeDate}
              datePickerError={isRequestedDeliveryDateError}
              icon={<Calendar />}
              isCloseCalendar={true}
              isOpen={openCalendar}
              errorMessage={RequestErrors.Date}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
