import { ReactComponent as Logo } from "../../assets/icons/LOGO.svg";
import { NavLink } from "react-router-dom";
import { useAuth, useBoxNavigation } from "../../hooks";
import { ButtonText, ModalTexts, NavBarLinks, NavbarText } from "../../utils/consts";
import {
  ActiveConnectNavBar,
  NavBarCurate,
  NavBarManage,
  NavBarConnect,
  NavBarBasic,
  // TODO: for feature
  FeatureBox,
  /*Filter,
  Notification,*/
  ActiveConnectNavBar3d,
  Modal,
  LogOutIcon,
  /*AdditionalFeaturesManageNavBar,*/
} from "../";
import { useState } from "react";

export const NavBar = () => {
  const auth = useAuth();
  const { current } = useBoxNavigation();
  const currentPathname = window.location.pathname;
  const [isModalLogOutOpen, setIsModalLogOutOpen] = useState(false);

  const handleLogOut = () => {
    setIsModalLogOutOpen(false);
    auth.logout();
  };
  const modalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalLogOutOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.LogOut}`,
      onClick: () => handleLogOut(),
      class: "btn-primary btn-wide",
    },
  ];
  return (
    <>
      <div className="navbar">
        <NavLink to="/">
          <div className="logo">
            <Logo />
          </div>
        </NavLink>
        <div className="menu">
          <div className="features">
            {currentPathname === NavBarLinks.Home && <NavBarBasic />}
            {currentPathname.startsWith(NavBarLinks.Connect) && <NavBarConnect />}
            {currentPathname.startsWith(NavBarLinks.Curate) && <NavBarCurate />}
            {currentPathname.startsWith(NavBarLinks.Manage) && <NavBarManage />}
            {/* // TODO: for feature */}
            {/* {false && <FeatureBox icon={<Notification />} btnText={NavbarText.Notifications} />} */}
          </div>
          {current !== null &&
            currentPathname.startsWith(NavBarLinks.Connect) &&
            currentPathname.endsWith(`/active-boxes/${current.id}`) && <ActiveConnectNavBar />}
          {current !== null && currentPathname.startsWith(`/active-boxes/${current.id}/`) && <ActiveConnectNavBar3d />}
          {/* // TODO: for feature */}
          {/* {currentPathname.startsWith(NavBarLinks.Manage) && <FeatureBox icon={<Filter />} btnText={NavbarText.Filter} />} */}
          {/* // TODO: Waiting for more info from Peter. Now on Pause */}
          {/* {currentPathname.startsWith(NavBarLinks.Manage) && currentPathname.endsWith(NavBarLinks.Manage) && (
          <AdditionalFeaturesManageNavBar />
        )} */}

          {currentPathname === NavBarLinks.Home && (
            <div className="additional_features">
              <NavLink to="/" onClick={() => setIsModalLogOutOpen(true)}>
                <FeatureBox icon={<LogOutIcon />} btnText={NavbarText.LogOut} />
              </NavLink>
            </div>
          )}
        </div>
      </div>
      {isModalLogOutOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderLogOut}
          modalContent={ModalTexts.ContentLogOut}
          buttons={modalButtons}
          setIsModalOpen={setIsModalLogOutOpen}
        />
      )}
    </>
  );
};
