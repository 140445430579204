import { ChangeEventHandler, useState } from "react";

type InputProps = {
  label: string;
  type: "number";
  value: string;
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  filled: boolean;
  error?: boolean;
  onInput?: (event: any) => void;
};

export const InputField = (props: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    e.currentTarget.focus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const labelClassName = isFocused
    ? "caption-mall medium floating-label active"
    : `body-large medium floating-label ${props.filled ? "filled" : ""}`;

  return (
    <div className={`input-wrapper-floating ${props.error ? "error" : ""}`}>
      <input
        type={props.type}
        value={props.value}
        name={props.name}
        className="body-large medium color-primary"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={props.onChange}
        onKeyDown={props.onInput}
      />
      <label className={labelClassName}>{props.label}</label>
    </div>
  );
};
