import { Row } from "react-bootstrap";
import { ConnectIcon, CurateIcon, InfoStatusBlock, Loader, ManageIcon, Platform } from "../../components";
import {
  NoPlatformAccess,
  PlatformHeadText,
  PlatformName,
  PlatformPageLink,
  PlatformSubTitle,
  PlatformTermsAndConditions,
} from "../../utils/consts";
import { useGetUser } from "../../queries/";
import { useState } from "react";
import { NotRequiredTnC } from "../../components/TermsAndConditions/NotRequired";
import { TnCService } from "../../utils/models";

export const Index = () => {
  const { isLoading, data: user, isError } = useGetUser();
  const [isModalTandCOpen, setIsModalTandCOpen] = useState(false);

  if (isLoading || !user) {
    return <Loader hasError={isError} />;
  }

  return (
    <div className="platform-stream">
      <div className="platform">
        {!(user.access.connect || user.access.curate || user.access.manage) ? (
          ""
        ) : (
          <div className="header">
            <h1 className="light">{PlatformHeadText.Basic}</h1>
          </div>
        )}
        <Row>
          {user.access.connect && (
            <Platform
              icon={<ConnectIcon />}
              platformName={PlatformName.Connect}
              subtitle={PlatformSubTitle.Connect}
              nextPage={PlatformPageLink.Connect}
              headerText={PlatformHeadText.Connect}
            />
          )}
          {user.access.curate && (
            <Platform
              icon={<CurateIcon />}
              platformName={PlatformName.Curate}
              subtitle={PlatformSubTitle.Curate}
              nextPage={PlatformPageLink.Curate}
              headerText={PlatformHeadText.Curate}
            />
          )}
          {user.access.manage && (
            <Platform
              icon={<ManageIcon />}
              platformName={PlatformName.Manage}
              subtitle={PlatformSubTitle.Manage}
              nextPage={PlatformPageLink.Manage}
              headerText={PlatformHeadText.Manage}
            />
          )}
          {!(user.access.connect || user.access.curate || user.access.manage) && (
            <InfoStatusBlock message={NoPlatformAccess} widerTextBlock={true} />
          )}
        </Row>
      </div>
      <div className="platform-tc ">
        <div className="tc-text" onClick={() => setIsModalTandCOpen(true)}>
          {PlatformTermsAndConditions.Stream}
        </div>
      </div>
      {isModalTandCOpen && <NotRequiredTnC onClose={() => setIsModalTandCOpen(false)} type={TnCService.Streams} />}
    </div>
  );
};
