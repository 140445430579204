import { useGetZipPath } from "../queries";
import { useEffect, useState } from "react";

const WaitingDownloadSeconds = 1000 * 30;

export const useDoDownload = (links: string[]) => {
  const { isLoading, isError, data: list } = useGetZipPath(links);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if ((links?.length > 0 && isLoading) || isError || !Array.isArray(list)) {
      setIsDone(false);
    }
  }, [list, links, isError, isLoading]);

  useEffect(() => {
    if (!isLoading && !isError && Array.isArray(list)) {
      list.forEach((item) => {
        const el = document.createElement("iframe");
        el.src = item;
        document.body.append(el);
        setTimeout(function () {
          el.remove();
        }, WaitingDownloadSeconds);
      });
      setIsDone(true);
    }
  }, [isLoading, isError, list]);

  return {
    isLoading,
    isError,
    isDone,
  };
};
