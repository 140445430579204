import moment from "moment-timezone";
import { basicTableDateFormat, cacheTime, SortDirection } from "./consts";
import axios from "axios";

export const copy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const formatDate = (date: string) => {
  const now = moment().local();
  const d = moment(date);
  if (d.isSame(now, "day")) {
    return "Today " + d.local().format("HH:mm");
  } else if (d.clone().add(1, "day").isSame(now, "day")) {
    return "Yesterday " + d.local().format("HH:mm");
  } else {
    return d.format(basicTableDateFormat);
  }
};

export const sleep = async (timeout: number) => new Promise<void>((resolve) => setTimeout(resolve, timeout));

const cacheList: any = {};
export const getFromCache = (key: string, defaultValue: any = null) => {
  if (cacheList[key] && cacheList[key].data) {
    clearTimeout(cacheList[key].timeout);
    cacheList[key].timeout = setTimeout(() => {
      delete cacheList[key];
    }, cacheTime);
    return cacheList[key].data;
  }

  return defaultValue;
};
export const addToCache = (key: string, value: any) => {
  cacheList[key] = {
    data: value,
    timeout: setTimeout(() => {
      delete cacheList[key];
    }, cacheTime),
  };
};

export const getHigherByNumber = (a: any, b: any, key: string, sortTableDir: SortDirection) => {
  const aNum = Number(a[key]);
  const bNum = Number(b[key]);

  if (!isNaN(aNum) && !isNaN(bNum)) {
    return sortTableDir === SortDirection.ASC ? aNum - bNum : bNum - aNum;
  }
  return sortTableDir === SortDirection.ASC ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
};

export const formatPrice = (priceString: string) => {
  const price = parseInt(priceString, 10);
  const formattedPrice = price.toLocaleString("en-US");
  return formattedPrice.replace(/,/g, ",");
};

let interceptorExist = false;

export const addInterceptor = (logout: () => void) => {
  if (!interceptorExist) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      },
    );
    interceptorExist = true;
  }
};
