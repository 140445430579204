interface Props {
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  error?: boolean;
}

export const Checkbox: React.FC<Props> = ({ onChange, isChecked, error }) => {
  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        className={`${error ? "error" : ""}`}
      />
    </div>
  );
};
