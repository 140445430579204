import { useGetStoneInfo } from "../../hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close, EyeWhite, View2D, View3D, ViewSwitcher, EyeOnWhite, Loader } from "../../components";

type StoneContentProps = {
  is3D: boolean;
  setIs3D: (value: boolean) => void;
  boxID: string;
  stoneID: string;
  shape: string;
  setVersion: (value: number) => void;
  version: number;
};

export const StoneContent = ({ version, setVersion, is3D, setIs3D, boxID, stoneID, shape }: StoneContentProps) => {
  const { isLoading, isError, stoneInfo } = useGetStoneInfo(boxID, stoneID);
  const [polishedFitVisible, setPolishedFitVisible] = useState<boolean>(true);
  const [surfaceMeshVisible, setSurfaceMeshVisible] = useState<boolean>(true);
  const [inclusionsVisible, setInclusionsVisible] = useState<boolean>(true);
  const [isHiddenPanel, setIsHiddenPanel] = useState<boolean>(false);
  const navigate = useNavigate();

  if (isLoading || isError || !stoneInfo) {
    return <Loader hasError={isError} />;
  }

  const updateSurfaceMeshVisible = (value: boolean) => {
    setSurfaceMeshVisible(value);
    setVersion(version + 1);
  };

  const updateInclusionsVisible = (value: boolean) => {
    setInclusionsVisible(value);
    setVersion(version + 1);
  };

  const updatePolishedFitVisible = (value: boolean) => {
    setPolishedFitVisible(value);
    setVersion(version + 1);
  };

  if (stoneInfo.surface) {
    stoneInfo.surface.setVisibility(surfaceMeshVisible);
  }
  stoneInfo.surfaceMesh.forEach((meshItem) => {
    meshItem.setVisibility(inclusionsVisible && surfaceMeshVisible);
  });

  stoneInfo.poly.forEach((item) => {
    item.actor.setVisibility(false);
    item.wireframe?.setVisibility(false);
    item.meshes?.forEach((meshItem) => {
      meshItem.setVisibility(false);
    });
    if (item.shape.toLowerCase() === shape.toLowerCase() && polishedFitVisible) {
      item.actor.setVisibility(true);
      item.wireframe?.setVisibility(true);
      item.meshes?.forEach((meshItem) => {
        meshItem.setVisibility(!surfaceMeshVisible && inclusionsVisible);
      });
    }
  });

  const onGoBack = () => {
    navigate(`/curate/my-offers/`);
  };

  const hidePanel = () => {
    setIsHiddenPanel(!isHiddenPanel);
  };
  return (
    <>
      <ViewSwitcher
        additionalClassName={isHiddenPanel ? "hidden" : ""}
        is3D={is3D}
        setIs3D={setIs3D}
        isPolishedVisible={polishedFitVisible}
        surfaceMeshVisible={surfaceMeshVisible}
        updateSurfaceMeshVisible={updateSurfaceMeshVisible}
        inclusionsVisible={inclusionsVisible}
        updateInclusionsVisible={updateInclusionsVisible}
        updateIsPolishedVisible={updatePolishedFitVisible}
      />
      {is3D ? (
        <View3D surface={stoneInfo.surface} mesh={stoneInfo.mesh} poly={stoneInfo.poly} version={version} />
      ) : (
        <View2D images={stoneInfo.images} />
      )}
      {is3D && (
        <div className={"special-icon-wrapper eye"} onClick={hidePanel}>
          {!isHiddenPanel ? <EyeWhite /> : <EyeOnWhite />}
        </div>
      )}
      <div className={"close special-icon-wrapper"} onClick={onGoBack}>
        <Close />
      </div>
    </>
  );
};
