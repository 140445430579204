import { NavLink } from "react-router-dom";
import { NavbarText } from "../../utils/consts";
import {
  FeatureBox,
  RequestsIcon,
  NewRequest,
  // TODO for feature
  // Notification
} from "../";

export const NavBarCurate = () => {
  return (
    <>
      <NavLink to="/curate/my-offers/">
        <FeatureBox icon={<RequestsIcon />} btnText={NavbarText.Requests} />
      </NavLink>
      <NavLink to="/curate/my-offers/request/new">
        <FeatureBox icon={<NewRequest />} btnText={NavbarText.NewRequest} />
      </NavLink>
      {/* // TODO: for feature */}
      {/* <FeatureBox icon={<Notification />} btnText={NavbarText.Notifications} /> */}
    </>
  );
};
