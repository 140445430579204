import { FeatureBox } from "../FeaturesBox";
import { BarCode, Download, SymbolDownload } from "../GlobalIcons";
import { Link } from "react-router-dom";
import { ButtonText, ModalTexts, NavbarText } from "../../utils/consts";
import { useState } from "react";
import { Modal } from "../Modal";
import { useDoDownload, useBoxNavigation } from "../../hooks";

export const ActiveConnectNavBar3d = () => {
  const { current } = useBoxNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDownloadBox = () => {
    setDownloadList([current.link])
    setIsModalOpen(false);
  };

  const modalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  return (
    <>
      <div className="additional_features">
        <FeatureBox active={true} icon={<BarCode />} btnText={`${current.id}`} />
        <Link to="" onClick={handleOpenModal}>
          <FeatureBox icon={<Download />} btnText={NavbarText.Download} />
        </Link>
      </div>
      {isModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBox}
          modalContent={ModalTexts.ContentDownloadBox}
          buttons={modalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
