import { useState } from "react";
import { SortButton, StoneTable } from "../../components";
import {
  SortDirection,
  ManageOfferTableSortValues,
  ManageOfferTableSortValuesReturn,
  fluorescenceRange,
  clarityRange,
  makeRange,
  colourRange,
} from "../../utils/consts";
import { getHigherByNumber } from "../../utils/helpers";
import { StoneItem } from "../../utils/models";

export const getValueByKey = (item: StoneItem, key: string) => {
  if (key === ManageOfferTableSortValuesReturn.PFlu) {
    return item.polish.length ? item.polish[0].flu : "";
  }
  if (key === ManageOfferTableSortValuesReturn.RFlu) {
    return item.flu;
  }
  if (key === ManageOfferTableSortValuesReturn.PClarity) {
    return item.polish.length ? item.polish[0].clarity : "";
  }
  if (key === ManageOfferTableSortValuesReturn.PColour) {
    return item.polish.length ? item.polish[0].color : "";
  }
  if (key === ManageOfferTableSortValuesReturn.Make) {
    return item.polish.length ? item.polish[0].make : "";
  }
  return item.weight
};

const selectValue = (selectedCol: string) => {
  if (selectedCol === ManageOfferTableSortValues.RWeight) {
    return ManageOfferTableSortValuesReturn.RWeight;
  }
  if (selectedCol === ManageOfferTableSortValues.RColour) {
    return ManageOfferTableSortValuesReturn.RColour;
  }
  if (selectedCol === ManageOfferTableSortValues.RFlu) {
    return ManageOfferTableSortValuesReturn.RFlu;
  }
  if (selectedCol === ManageOfferTableSortValues.PShape) {
    return ManageOfferTableSortValuesReturn.PShape;
  }
  if (selectedCol === ManageOfferTableSortValues.PWeight) {
    return ManageOfferTableSortValuesReturn.PWeight;
  }
  if (selectedCol === ManageOfferTableSortValues.PColour) {
    return ManageOfferTableSortValuesReturn.PColour;
  }
  if (selectedCol === ManageOfferTableSortValues.PClarity) {
    return ManageOfferTableSortValuesReturn.PClarity;
  }
  if (selectedCol === ManageOfferTableSortValues.PFlu) {
    return ManageOfferTableSortValuesReturn.PFlu;
  }
  // ManageOfferTableSortValues.Make
  return ManageOfferTableSortValuesReturn.Make;
};

export const ManageStatusTable = ({ data }: { data: StoneItem[] }) => {
  const [sortTableDir, setSortTableDir] = useState<SortDirection.ASC | SortDirection.DESC>(SortDirection.DESC);
  const [sortTableColumn, setSortTableColumn] = useState<string>(ManageOfferTableSortValues.RWeight);
  const handleSortClick = (colName: string, sortTableDir: string) => {
    setSortTableColumn(colName);
    if (sortTableDir === SortDirection.ASC) {
      setSortTableDir(SortDirection.DESC);
    } else {
      setSortTableDir(SortDirection.ASC);
    }
  };

  const key = selectValue(sortTableColumn);
  const sortedData = data.sort((a: any, b: any) => {
    if (
      key === ManageOfferTableSortValuesReturn.PFlu ||
      key === ManageOfferTableSortValuesReturn.RFlu ||
      key === ManageOfferTableSortValuesReturn.PClarity ||
      key === ManageOfferTableSortValuesReturn.PColour ||
      key === ManageOfferTableSortValuesReturn.Make
    ) {
      let sortOrder;
      if (key === ManageOfferTableSortValuesReturn.PFlu || key === ManageOfferTableSortValuesReturn.RFlu) {
        sortOrder = fluorescenceRange.map((value) => value.toLowerCase());
      }
      if (key === ManageOfferTableSortValuesReturn.PClarity) {
        sortOrder = clarityRange.map((value) => value.toLowerCase());
      }
      if (key === ManageOfferTableSortValuesReturn.Make) {
        sortOrder = makeRange.map((value) => value.toLowerCase());
      }
      if (key === ManageOfferTableSortValuesReturn.PColour) {
        sortOrder = colourRange.map((value) => value.toLowerCase());
      }
      const valueA = getValueByKey(a, key);
      const valueB = getValueByKey(b, key);
      let indexA = -1;
      let indexB = -1;

      if (valueA !== undefined && valueB !== undefined) {
        indexA = sortOrder?.indexOf(valueA.toString().toLowerCase()) ?? -1;
        indexB = sortOrder?.indexOf(valueB.toString().toLowerCase()) ?? -1;
      }

      return sortTableDir === SortDirection.ASC ? indexA - indexB : indexB - indexA;
    }

    if (key === ManageOfferTableSortValuesReturn.PShape || key === ManageOfferTableSortValuesReturn.PWeight) {
      const val = key.slice(1).toLocaleLowerCase();
      const aNum = Number(a.polish[0][val]);
      const bNum = Number(b.polish[0][val]);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        return sortTableDir === SortDirection.ASC ? aNum - bNum : bNum - aNum;
      }
      return sortTableDir === SortDirection.ASC
        ? a.polish[0][val].localeCompare(b.polish[0][val])
        : b.polish[0][val].localeCompare(a.polish[0][val]);
    }

    return getHigherByNumber(a, b, key, sortTableDir as SortDirection);
  });

  const manageOfferDetailsTableCols = [
    "Stone ID",
    <SortButton
      colName={"RWeight"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "RWeight" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"RColour"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "RColour" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"RFlu"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "RFlu" ? sortTableDir : SortDirection.None}
    />,

    <SortButton
      colName={"PShape"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PShape" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"PWeight"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PWeight" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"PColour"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PColour" ? sortTableDir : SortDirection.None}
    />,

    <SortButton
      colName={"PClarity"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PClarity" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"PFlu"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PFlu" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"PMake"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "PMake" ? sortTableDir : SortDirection.None}
    />,
  ];
  return (
    <div className="offer-details-block">
      <div className="headline">
        <h3 className="light primary-color">Offer</h3>
        <div className="caption-large-upper heavy">offer details</div>
      </div>
      <div className="offer-details-table">
        <StoneTable
          columns={manageOfferDetailsTableCols}
          data={sortedData.map((stone) => {
            return [
              { value: stone.id, tdClass: "first-letter-cap cell_1 manage" },
              { value: stone.weight, tdClass: "first-letter-cap cell_2" },
              { value: stone.color, tdClass: "first-letter-cap cell_3" },
              { value: stone.flu.toLowerCase(), tdClass: "first-letter-cap cell_4" },

              {
                value: stone.polish.length ? stone.polish[0].shape.toLowerCase() : "",
                tdClass: "first-letter-cap cell_5",
              },
              { value: stone.polish.length ? stone.polish[0].weight : "", tdClass: "first-letter-cap cell_6" },
              { value: stone.polish.length ? stone.polish[0].color : "", tdClass: "first-letter-cap cell_7" },
              {
                value: stone.polish.length ? stone.polish[0].clarity.toUpperCase() : "",
                tdClass: "first-letter-cap cell_8",
              },
              {
                value: stone.polish.length ? stone.polish[0].flu.toLowerCase() : "",
                tdClass: "first-letter-cap cell_9",
              },
              {
                value: stone.polish.length ? stone.polish[0].make.toUpperCase() : "",
                tdClass: "first-letter-cap cell_10",
              },
            ];
          })}
        />
      </div>
    </div>
  );
};
