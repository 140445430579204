import { useQuery, UseQueryResult } from "react-query";
import StoneList from "../mocks/stoneListDetails.json";
import { StoneItem, StoneItemRaw } from "../utils/models";
import Server from "../config/server.json";
import axios from "axios";
import Config from "../config/auth.json";
import { AdditionalConfig } from "../utils/consts";
import { serializePolishedItem } from "../utils/serializers";

export const sample = require("../mocks/sample.zip");

const fetch = async ({ queryKey }: { queryKey: any[] }): Promise<StoneItemRaw[]> => {
  if (Server.useMocks) {
    return StoneList;
  }
  const [, boxID] = queryKey;
  const url = Config.endpoint + Server.getStoneListDetailed.replace(":boxID", boxID);
  const response = await axios.get(url);
  return response.data;
};

export const useGetStoneList = (boxId?: string): UseQueryResult<StoneItem[]> => {
  return useQuery(["stoneList", boxId], fetch, {
    enabled: !!boxId,
    select: (list) => {
      return list.map((item) => ({
        id: item.StoneId,
        weight: item.RoughWeight,
        color: item.RoughColour,
        flu: item.RoughFlu,
        filenames: {
          surface: item.surfacefilename,
          mesh: Array.isArray(item.meshfilename) ? item.meshfilename : [item.meshfilename],
        },
        webData: Server.useMocks ? sample : item.stonewebdata,
        downloadData: Server.useMocks ? sample : item.stonedownloaddata,
        polish: Array.isArray(item.polishedfits) ? item.polishedfits.map(serializePolishedItem) : [],
      }));
    },
    ...AdditionalConfig,
  });
};
