import { NavLink } from "react-router-dom";
import { useBoxNavigation } from "../../hooks/";
import { NavbarText } from "../../utils/consts";
import { ActiveBoxes, FeatureBox, PreviousBox } from "../";

export const NavBarConnect = () => {
  const { current } = useBoxNavigation();
  const currentPathname = window.location.pathname;

  return (
    <>
      {current !== null && currentPathname.startsWith(`/active-boxes/${current.id}/`) && (
        <NavLink to={`/active-boxes/${current.id}`} end>
          <FeatureBox icon={<PreviousBox />} btnText={NavbarText.BactToList} />
        </NavLink>
      )}

      <NavLink to="/active-boxes/" end>
        <FeatureBox icon={<ActiveBoxes />} btnText={NavbarText.ActiveBoxes} />
      </NavLink>
    </>
  );
};
