import { Col, Row } from "react-bootstrap";
import { Offer, RequestItem, StoneItem } from "../../utils/models";
import {
  ArrowDown,
  SymbolConfirm,
  SymbolDownload,
  Modal,
  TabItem,
  TabSwitcher,
  BarCode,
  PriceIcon,
  Calendar,
  ClockTimeIcon,
  DiamondIcon,
} from "../";
import { StoneListSelection } from "./stoneListSelection";
import { RequestDetails } from "./requestDetails";
import {
  ButtonText,
  ModalTexts,
  RequestStatus,
  WiderTableScreen,
  basicTableDateFormat,
  serverDateTimeFormat,
} from "../../utils/consts";
import { useState } from "react";
import { useDoDownload } from "../../hooks";
import { useSaveRequest } from "../../queries";
import { copy, formatPrice } from "../../utils/helpers";
import { useQueryClient } from "react-query";
import moment from "moment-timezone";

type OfferLeftPanelProps = {
  requestDetails: RequestItem;
  stones?: StoneItem[];
  selectedStone: string;
  setSelectedStone: (value: string) => void;
  boxDownloadLink: string;
  handleRefresh3D: () => void;
};

export const OfferLeftPanel = ({
  requestDetails,
  stones,
  selectedStone,
  setSelectedStone,
  boxDownloadLink,
  handleRefresh3D,
}: OfferLeftPanelProps) => {
  const tabSwitcherData: TabItem[] = [
    {
      title: "Offer details",
      content: (
        <RequestDetails
          id={requestDetails.id || ""}
          price={requestDetails?.offer?.price as string}
          deliveryDate={requestDetails?.offer?.expectedDeliveryDate as string}
          shape={requestDetails.shape}
          count={requestDetails.offer?.stoneCount || 0}
          validUntil={requestDetails?.offer?.validTo as string}
        />
      ),
    },
  ];

  if (requestDetails?.offer?.boxId) {
    tabSwitcherData.unshift({
      title: "Curated stones",
      content: "",
    });
  }

  const [isModalOpenAccept, setIsModalOpenAccept] = useState(false);
  const [isModalOpenDownloadBox, setIsModalOpenDownloadBox] = useState(false);
  const [isModalOpenReject, setIsModalOpenReject] = useState(false);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);
  const statusMutation = useSaveRequest();
  const queryClient = useQueryClient();

  const [selectedTab, setSelectedTab] = useState(0);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  const handleOpenModalAccept = () => {
    setIsModalOpenAccept(true);
  };
  const handleOpenModalDownloadBox = () => {
    setIsModalOpenDownloadBox(true);
  };
  const handleOpenModalReject = () => {
    setIsModalOpenReject(true);
  };

  const handleDownloadBox = () => {
    setDownloadList([boxDownloadLink]);
    setIsModalOpenDownloadBox(false);
  };

  const handleConfirmOffer = async () => {
    const newRequestItem = copy(requestDetails);
    newRequestItem.status = RequestStatus.Accepted;
    (newRequestItem.offer as Offer).acceptedOfferDate = moment().format(serverDateTimeFormat);
    await statusMutation.mutateAsync(newRequestItem);
    await queryClient.invalidateQueries(["requestItem", newRequestItem.id]);
    await queryClient.invalidateQueries("requestList");
    setIsModalOpenAccept(false);
  };

  const handleRejectOffer = async () => {
    const newRequestItem = copy(requestDetails);
    newRequestItem.status = RequestStatus.Declined;
    await statusMutation.mutateAsync(newRequestItem);
    await queryClient.invalidateQueries(["requestItem", newRequestItem.id]);
    await queryClient.invalidateQueries("requestList");
    setIsModalOpenReject(false);
  };

  const modalButtonsAccept = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpenAccept(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Confirm}`,
      onClick: () => handleConfirmOffer(),
      class: "btn-primary-confirm btn-wide",
      symbol: <SymbolConfirm />,
    },
  ];
  const modalButtonsDownloadBox = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => setIsModalOpenDownloadBox(false),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];
  const modalButtonsReject = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpenReject(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Reject}`,
      onClick: () => handleRejectOffer(),
      class: "btn-primary-warning btn-wide",
    },
  ];

  const acceptModalContent = (
    <>
      <div className="body-large medium accept-offer-text">Are you sure you want to accept this offer?</div>
      <div className="description-row">
        <div className="icon-block">
          <BarCode />
        </div>
        <div className="body-small medium text_pad">ID: {requestDetails.id || ""}</div>
      </div>
      {requestDetails?.offer?.price && (
        <div className="description-row">
          <div className="icon-block">
            <PriceIcon />
          </div>
          <div className="body-small medium text_pad">
            Offer Price $: {formatPrice(requestDetails?.offer?.price as string)}
          </div>
        </div>
      )}
      {requestDetails?.offer?.expectedDeliveryDate && (
        <div className="description-row">
          <div className="icon-block">
            <Calendar />
          </div>
          <div className="body-small medium text_pad">
            Expected Delivery Date:{" "}
            {moment(requestDetails?.offer?.expectedDeliveryDate as string).format(basicTableDateFormat)}
          </div>
        </div>
      )}
      {requestDetails?.offer?.validTo && (
        <div className="description-row">
          <div className="icon-block">
            <ClockTimeIcon />
          </div>
          <div className="body-small medium text_pad">
            Offer Valid Until: {moment(requestDetails?.offer?.validTo as string).format(basicTableDateFormat)}
          </div>
        </div>
      )}
      {requestDetails.offer?.stoneCount && (
        <div className="description-row last-row-pad">
          <div className="icon-block">
            <DiamondIcon />
          </div>
          <div className="body-small medium text_pad stones-info first-letter-cap">
            Stones: {requestDetails.offer?.stoneCount} {requestDetails.shape.toLowerCase()}
          </div>
        </div>
      )}
    </>
  );
  return (
    <Col
      className={`stone-left-panel-wrapper big ${selectedTab === 0 ? "wider-panel" : ""} ${
        window.innerWidth < WiderTableScreen ? "col-sm-12" : ""
      }`}
    >
      <Row className="description">
        <Col>
          <TabSwitcher
            data={tabSwitcherData}
            selectedTab={selectedTab}
            setSelectedTab={(v) => {
              setSelectedTab(v);
              handleRefresh3D();
            }}
          />
          {Array.isArray(stones) && (
            <StoneListSelection
              selectedStone={selectedStone}
              setSelectedStone={setSelectedStone}
              stones={stones}
              selectedTab={selectedTab}
            />
          )}
        </Col>
      </Row>

      {requestDetails.status.toLowerCase() === RequestStatus.Accepted && (
        <div className="offer-accepted">
          <div className="accepted-date medium button-default-text">
            Accepted Offer Date: {moment(requestDetails?.offer?.acceptedOfferDate).format(basicTableDateFormat)}
          </div>
          <button className="btn-secondary " onClick={handleOpenModalDownloadBox}>
            {ButtonText.DownloadBox} <ArrowDown />
          </button>
        </div>
      )}

      {requestDetails.status.toLowerCase() === RequestStatus.Offered && (
        <div className="buttons-wrapper">
          <div className="buttons-wrapper-right">
            <button className="btn-primary" onClick={handleOpenModalAccept}>
              {ButtonText.AcceptOffer}
              <SymbolConfirm />
            </button>
            {boxDownloadLink !== "" && (
              <button className="btn-secondary" onClick={handleOpenModalDownloadBox}>
                {ButtonText.DownloadBox} <ArrowDown />
              </button>
            )}
          </div>
          <button className="btn-secondary" onClick={handleOpenModalReject}>
            {ButtonText.Reject}
          </button>
        </div>
      )}
      {isModalOpenAccept && (
        <Modal
          modalHeader={ModalTexts.HeaderAcceptOffer}
          modalContent={acceptModalContent}
          buttons={modalButtonsAccept}
          setIsModalOpen={setIsModalOpenAccept}
        />
      )}
      {isModalOpenDownloadBox && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBoxCurate}
          modalContent={ModalTexts.ContentDownloadBoxCurate}
          buttons={modalButtonsDownloadBox}
          setIsModalOpen={setIsModalOpenDownloadBox}
        />
      )}
      {isModalOpenReject && (
        <Modal
          modalHeader={ModalTexts.HeaderRejectOffer}
          modalContent={ModalTexts.ContentRejectOffer}
          buttons={modalButtonsReject}
          setIsModalOpen={setIsModalOpenReject}
        />
      )}
    </Col>
  );
};
