import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.scss";
import { AuthWrapper } from "./layouts/auth";
import { NavBar } from "./components";
import { routes } from "./routes";
import { BoxNavigationProvider, AuthProvider } from "./hooks";
import { AccessLayout } from "./layouts/access";

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Container fluid>
            <Routes>
              {routes.map((item, index) => {
                const Component = item.component;
                return (
                  <Route
                    key={index}
                    path={item.path}
                    element={
                      <AuthWrapper>
                        <BoxNavigationProvider>
                          <AccessLayout accessType={item.access}>
                            {!item.hidePanel && <NavBar />}
                            <Component />
                          </AccessLayout>
                        </BoxNavigationProvider>
                      </AuthWrapper>
                    }
                  />
                );
              })}
            </Routes>
          </Container>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
