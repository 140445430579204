import { useBoxNavigation, useDoDownload } from "../../hooks/";
import { Link, NavLink } from "react-router-dom";
import { ButtonText, ModalTexts, NavbarText } from "../../utils/consts";
import { useState } from "react";
import { BarCode, Download, NextBox, PreviousBox, SymbolDownload, FeatureBox, Modal } from "../";

export const ActiveConnectNavBar = () => {
  const { current, prev, next, count } = useBoxNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDownloadBox = () => {
    setDownloadList([current.link]);
    setIsModalOpen(false);
  };

  const modalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  return (
    <>
      <div className="additional_features">
        <FeatureBox active={true} icon={<BarCode />} btnText={`${current.id}`} />
        {prev !== null && count > 1 && (
          <NavLink to={`/active-boxes/${prev}`}>
            <FeatureBox icon={<PreviousBox />} btnText={NavbarText.PreviousBox} />
          </NavLink>
        )}
        {next !== null && count > 1 && (
          <NavLink to={`/active-boxes/${next}`}>
            <FeatureBox icon={<NextBox />} btnText={NavbarText.NextBox} />
          </NavLink>
        )}
        {/* <FeatureBox icon={<Filter />} btnText={NavbarText.Filter} /> */}
        <Link to="" onClick={handleOpenModal}>
          <FeatureBox icon={<Download />} btnText={NavbarText.Download} />
        </Link>
      </div>
      {isModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBox}
          modalContent={ModalTexts.ContentDownloadBox}
          buttons={modalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
