import { Row } from "react-bootstrap";
import { InfoStatusBlock, Loader, PreviousBox, SingleBox } from "../../components";
import Box1 from "../../assets/images/1boxDarker.jpg";
import Box2 from "../../assets/images/2boxDarker.jpg";
import Box3 from "../../assets/images/3boxDarker.jpg";
import Box5 from "../../assets/images/5boxDarker.jpg";
import { useGetBoxList } from "../../queries";
import { ButtonText, PlatformTermsAndConditions, ThereIsNoData } from "../../utils/consts";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { NotRequiredTnC } from "../../components/TermsAndConditions/NotRequired";
import { BoxItem, TnCService } from "../../utils/models";
import moment from "moment-timezone";

export const imagesList = [Box5, Box1, Box2, Box3];

export const ActiveBoxWrapper = () => {
  const { isLoading, data, isError } = useGetBoxList();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  let nextImageIndex = Math.floor(Math.random() * imagesList.length);

  const getRandomImage = () => {
    const image = imagesList[nextImageIndex];
    nextImageIndex = (nextImageIndex + 1) % imagesList.length;
    return image;
  };

  if (isLoading || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }
  const sortedData = data.sort((a: BoxItem, b: BoxItem) =>
    moment(b.createdDate).diff(moment(a.createdDate), "seconds"),
  );

  const returnButtons = [
    {
      text: `${ButtonText.ReturnToStream}`,
      onClick: () => navigate(-1),
      class: "btn-primary",
      symbol: <PreviousBox />,
    },
  ];

  return (
    <div className="platform-connect">
      <div className="active-boxes">
        {sortedData.length === 0 ? (
          <InfoStatusBlock message={ThereIsNoData.ActiveBoxes} widerTextBlock={true} buttons={returnButtons} />
        ) : (
          <>
            <h3 className="light">Active Boxes</h3>
            <div className="active-boxes-list">
              <Row>
                {sortedData.map((box) => {
                  const cardImage = getRandomImage();
                  return (
                    <SingleBox
                      key={box.id}
                      id={box.id}
                      link={box.link}
                      count={box.stoneCount}
                      date={box.sightNumber}
                      image={cardImage}
                    />
                  );
                })}
              </Row>
            </div>
          </>
        )}
      </div>
      <div className="platform-tc ">
        <div className="tc-text" onClick={() => setIsModalOpen(true)}>
          {PlatformTermsAndConditions.Connect}
        </div>
      </div>
      {isModalOpen && <NotRequiredTnC onClose={() => setIsModalOpen(false)} type={TnCService.AlphaConnect} />}
    </div>
  );
};
