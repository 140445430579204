import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SymbolNext } from "../";
import { ButtonText } from "../../utils/consts";

type PlatformProps = {
  icon: any;
  platformName: string;
  subtitle: string;
  nextPage: string;
  headerText: string;
};

export const Platform = (props: PlatformProps): JSX.Element => {
  return (
    <Col sm={12} md={6} lg={6} xl={4} xxl={3} xxxl={2}>
      <Link to={props.nextPage}>
        <div className="platform-item">
          <div className="platform-title-box">
            <div className="platform-icon">{props.icon}</div>
            <div className="platform-des">
              <div className="platform-name">
                <h2 className="medium">{props.platformName}</h2>
              </div>
              <div className="platform-info subtitle medium">{props.subtitle}</div>
            </div>
          </div>
          <div className="platform-link">
            <button className="btn-primary-outline btn-wide">
              {ButtonText.Launch} {<SymbolNext />}
            </button>
          </div>
        </div>
      </Link>
    </Col>
  );
};
