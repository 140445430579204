import { useMutation, UseMutationResult } from "react-query";
import { TnCService } from "../utils/models";
import Server from "../config/server.json";
import axios from "axios";
import Config from "../config/auth.json";

export type useSaveTnCItem = {
  version: number;
  type: TnCService;
};

const fetch = async (version: number, type: TnCService): Promise<void> => {
  if (Server.useMocks) {
    console.log("request tnc sent", version, type);
    return;
  }

  const url = Config.userEndpoint;
  await axios.put(url, {
    TnC: { service: type.toString(), accepted_version: version },
  });
  return;
};

export const useSaveTnC = (): UseMutationResult<void, unknown, useSaveTnCItem, unknown> => {
  return useMutation(({ version, type }: useSaveTnCItem) => fetch(version, type));
};
