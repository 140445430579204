import { useNavigate } from "react-router-dom";
import { useGetUser } from "../queries";
import { Loader, TermsAndConditions } from "../components";
import { AccessTypes } from "../utils/consts";
import { TnCService, User } from "../utils/models";
import { useAuth } from "../hooks";
import { addInterceptor } from "../utils/helpers";

export const getTnCLink = (
  user: User,
  type: TnCService,
  checkLatestVersion = true,
): {
  path: string;
  version: number;
} | null => {
  const tnc = user.tnc.find((el) => el.service.includes(type));
  if (tnc) {
    if (checkLatestVersion) {
      if (!tnc.status || tnc.latestVersion > tnc.acceptedVersion) {
        return { path: tnc.contentLocation, version: tnc.latestVersion };
      }
    } else {
      return { path: tnc.contentLocation, version: tnc.latestVersion };
    }
  }

  return null;
};

export const AccessLayout = ({ children, accessType }: { children: any; accessType?: AccessTypes }) => {
  const navigate = useNavigate();
  const { isLoading, data: user, isError } = useGetUser();
  const auth = useAuth();

  if (isLoading || !user) {
    return <Loader hasError={isError} />;
  }

  addInterceptor(() => auth.logout());

  const mainTncLink = getTnCLink(user, TnCService.Streams);

  if (accessType !== undefined) {
    if (accessType === AccessTypes.Connect && user.access.connect) {
      const tncLink = getTnCLink(user, TnCService.AlphaConnect);
      return (
        <>
          {children}
          {mainTncLink && (
            <TermsAndConditions
              tncKey={TnCService.Streams}
              version={mainTncLink.version}
              contentLink={mainTncLink.path}
            />
          )}
          {!mainTncLink && tncLink && (
            <TermsAndConditions tncKey={TnCService.AlphaConnect} version={tncLink.version} contentLink={tncLink.path} />
          )}
        </>
      );
    }
    if (accessType === AccessTypes.Curate && user.access.curate) {
      const tncLink = getTnCLink(user, TnCService.AlphaCurate);
      return (
        <>
          {children}
          {mainTncLink && (
            <TermsAndConditions
              tncKey={TnCService.Streams}
              version={mainTncLink.version}
              contentLink={mainTncLink.path}
            />
          )}
          {!mainTncLink && tncLink && (
            <TermsAndConditions tncKey={TnCService.AlphaCurate} contentLink={tncLink.path} version={tncLink.version} />
          )}
        </>
      );
    }
    if (accessType === AccessTypes.Manage && user.access.manage) {
      return (
        <>
          {children}
          {mainTncLink && (
            <TermsAndConditions
              tncKey={TnCService.Streams}
              version={mainTncLink.version}
              contentLink={mainTncLink.path}
            />
          )}
        </>
      );
    }
    navigate("/");
    return null;
  }

  return (
    <>
      {children}
      {mainTncLink && (
        <TermsAndConditions tncKey={TnCService.Streams} version={mainTncLink.version} contentLink={mainTncLink.path} />
      )}
    </>
  );
};
