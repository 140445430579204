import { useMutation, UseMutationResult } from "react-query";
import { RequestItem } from "../utils/models";
import Server from "../config/server.json";
import axios from "axios";
import Config from "../config/auth.json";
import { deserializeCurateItem } from "../utils/serializers";

const fetch = async (newRequest: RequestItem): Promise<void> => {
  if (Server.useMocks) {
    console.log("request sent", newRequest, deserializeCurateItem(newRequest));
    return;
  }
  const requestRaw = deserializeCurateItem(newRequest);

  if (newRequest.id) {
    const url = Config.endpoint + Server.updateCurateRequest.replace(":id", newRequest.id);
    await axios.put(url, requestRaw);
    return;
  }
  const url = Config.endpoint + Server.insertCurateRequest;
  await axios.post(url, requestRaw);
  return;
};

export const useSaveRequest = (): UseMutationResult<void, unknown, RequestItem, unknown> => {
  return useMutation((newRequest: RequestItem) => fetch(newRequest));
};
