import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks";
import { useCallback, useEffect } from "react";

const StoragePathKey = "Path";

// for non-refreshing on waiting cognito response
let isLoading = false;

export const AuthWrapper = ({ children }: { children: any }) => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const errorDescription = searchParams.get("error_description");

  const openAuth = useCallback(async () => {
    window.localStorage.setItem(StoragePathKey, window.location.pathname);
    window.location.href = await auth.getAuthEndpoint();
  }, [auth]);

  useEffect(() => {
    const openEndPath = () => {
      let path = window.localStorage.getItem(StoragePathKey);
      if (path) {
        window.localStorage.removeItem(StoragePathKey);
        navigate(path);
      } else {
        setSearchParams({});
      }
    };

    if (!errorDescription && !isLoading) {
      if (auth.accessToken) {
        if (code) {
          openEndPath();
        }
      } else {
        if (!code) {
          openAuth();
        } else {
          isLoading = true;

          auth.loadTokenByCode(code).then((authResult) => {
            isLoading = false;
            if (authResult) {
              openEndPath();
            } else {
              openAuth();
            }
          });
        }
      }
    }
  }, [navigate, auth, openAuth, setSearchParams, code, errorDescription]);

  if (auth.isLoading || !auth.accessToken) {
    return null;
  }

  if (errorDescription) {
    return <div>{errorDescription}</div>;
  }

  return children;
};
