import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import { useCallback, useState } from "react";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkPLYReader from "@kitware/vtk.js/IO/Geometry/PLYReader";
import vtkXMLPolyDataReader from "@kitware/vtk.js/IO/XML/XMLPolyDataReader";

import { useGetStoneList, useGetBoxList, useGetZip, useGetZipPath } from "../queries";
import { ImageContent, PolyItem, Stone, StoneData, ZipFile } from "../utils/models";
import {
  defaultSurfaceColor,
  defaultWireframeColor,
  JPGExt,
  meshColor,
  PLYExt,
  surfaceColor,
  VTPExt,
} from "../utils/consts";
// TODO comment with task pause
// import { reCalculateMesh } from "../utils/helpers3D";

export const applyProperties = (actor: vtkActor, property: any) => {
  actor.setVisibility(false);
  if (property.selected) {
    actor.setVisibility(true);
  }
  if (property.asWireframe) {
    actor.getProperty().setRepresentation(1);
  }
  actor.getProperty().setSpecular(1);
  actor.getProperty().setDiffuse(0.7);
  actor.getProperty().setAmbient(0.5);
  actor.getProperty().setSpecularPower(20.0);
  actor.getProperty().setOpacity(0.5);
  if (property.color !== undefined) {
    actor.getProperty().setColor(property.color[0], property.color[1], property.color[2]);
    if (property.color.length === 4) {
      actor.getProperty().setOpacity(property.color[3]);
    }
  } else {
    actor.getProperty().setColor(0.9, 0.9, 0.7);
  }
};

export const loadPLYPolyData = async (file: ZipFile, properties: any) => {
  const ply_map = vtkMapper.newInstance();
  const ply = vtkActor.newInstance();
  ply.setMapper(ply_map);
  const ply_rdr = vtkPLYReader.newInstance();
  await ply_rdr.parseAsArrayBuffer(file.file);
  ply_map.setInputConnection(ply_rdr.getOutputPort());
  ply.getProperty().setBackfaceCulling(false);
  applyProperties(ply, properties);
  return {
    actor: ply,
    mapper: ply_map,
  };
};

export const loadVTPPolyData = (file: ZipFile, properties: any) => {
  const vtp_map = vtkMapper.newInstance();
  const vtp = vtkActor.newInstance();
  vtp.setMapper(vtp_map);
  const vtp_rdr = vtkXMLPolyDataReader.newInstance();
  vtp_rdr.parseAsArrayBuffer(file.file);
  vtp_map.setInputConnection(vtp_rdr.getOutputPort());
  vtp.getProperty().setBackfaceCulling(false);
  applyProperties(vtp, properties);
  return {
    actor: vtp,
    mapper: vtp_map,
  };
};

export const loadActor = async (
  file: ZipFile,
  visibleByDefault: boolean = false,
  color: number[] = defaultSurfaceColor,
  asWireframe?: boolean,
): Promise<{ actor: vtkActor; mapper: any }> => {
  if (file.filename.includes(PLYExt)) {
    return await loadPLYPolyData(file, { asWireframe: asWireframe || false, selected: visibleByDefault, color });
  }
  if (file.filename.includes(VTPExt)) {
    return loadVTPPolyData(file, { asWireframe: asWireframe || false, selected: visibleByDefault, color });
  }

  throw new Error("something went wrong");
};

export const getFileByName = (fileList: ZipFile[], name: string) => {
  return (fileList as ZipFile[]).find((file) => file.filename.includes(name));
};

export const loadMeshes = (files: string[], data: ZipFile[]) => {
  return Promise.all(
    files.map((meshFileName) => {
      const meshFile = getFileByName(data as ZipFile[], meshFileName as string);
      return loadActor(meshFile as ZipFile, false, meshColor);
    }),
  );
};

export const loadPolyData = async (
  stonesData: Stone[],
  fileList: ZipFile[],
  meshFiles: string[],
): Promise<PolyItem[]> => {
  const promises: Promise<PolyItem>[] = [];
  stonesData.forEach((item) => {
    const file = getFileByName(fileList, item.filename);
    if (file) {
      promises.push(
        Promise.all([
          loadActor(file, true, defaultSurfaceColor, false),
          loadActor(file, true, defaultWireframeColor, true),
          loadMeshes(meshFiles, fileList),
        ]).then(([{ actor }, { actor: wireframe }, meshesList]) => {
          const meshes = meshesList.map((meshItem: any) => meshItem.actor);
          return {
            id: item.polishedID,
            shape: item.shape,
            weight: item.weight,
            color: item.color,
            flu: item.flu,
            grade: item.grade,
            clarity: item.clarity,
            actor,
            wireframe,
            meshes,
          };
        }),
      );
    }
  });

  return Promise.all(promises);
};

export const getImages = (fileList: ZipFile[]) => {
  const images: ImageContent[] = [];
  fileList.forEach((file) => {
    if (file.filename.includes(JPGExt)) {
      const typedArray = new Uint8Array(file.file);
      const blob = new Blob([typedArray], { type: "image/jpeg" });
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(blob);
      images.push({
        file: file.file,
        url,
        name: file.filename,
      });
    }
  });

  return images.sort((a, b) => a.name.localeCompare(b.name));
};

export const useGetStoneInfo = (boxID?: string, stoneID?: string) => {
  const boxListRequest = useGetBoxList();
  const stoneListRequest = useGetStoneList(boxID);
  const stone = stoneListRequest?.data?.find((item) => item.id === stoneID);
  const { isLoading: isLoadingGetPath, isError: isErrorGetPath, data: paths } = useGetZipPath(stone?.webData);
  let path = "";
  if (!isLoadingGetPath && !isErrorGetPath && Array.isArray(paths) && paths.length > 0) {
    path = paths[0];
  }
  const { isLoading, isError, data } = useGetZip(path);
  const [isGenerating, setIsGenerating] = useState(false);
  const [stoneInfo, setStoneInfo] = useState<StoneData | null>(null);
  const isFullLoading =
    isLoading || isGenerating || stoneListRequest.isLoading || boxListRequest.isLoading || isLoadingGetPath;
  const isSomewhereError = isError || stoneListRequest.isError || boxListRequest.isError || isErrorGetPath;

  const generateActors = useCallback(async () => {
    if (stone && data !== null && !isFullLoading && !isSomewhereError) {
      const box = boxListRequest.data?.find((item) => item.id === boxID);
      const surfaceFile = getFileByName(data as ZipFile[], stone?.filenames?.surface as string);
      let mesh: { actor: vtkActor; mapper: any }[] = [];
      try {
        mesh = await loadMeshes(stone?.filenames?.mesh || [], data as ZipFile[]);
      } catch (e) {
        console.log("something wrong with loading inclusions of stone:", e);
      }
      let poly: PolyItem[] = [];
      try {
        poly = await loadPolyData(stone?.polish as Stone[], data as ZipFile[], stone?.filenames?.mesh || []);
      } catch (e) {
        console.log("something wrong with loading polished of stone:", e);
      }

      const meshList = [...mesh.map(({ actor }) => actor)];
      poly.forEach((polyItem) => {
        meshList.push(...(polyItem.meshes || []));
      });
      let surface: vtkActor | null = null;
      try {
        surface = await loadActor(surfaceFile as ZipFile, true, surfaceColor).then(({ actor }) => actor);
      } catch (e) {
        console.log("something wrong with loading surface of stone:", e);
      }

      const newStoneInfo: StoneData = {
        weight: stone?.weight as string,
        id: stone?.id as string,
        color: stone?.color as string,
        flu: stone?.flu as string,
        sightNumber: box?.sightNumber as string,
        surface,
        surfaceMesh: mesh.map(({ actor }) => actor),
        mesh: meshList,
        poly,
        images: getImages(data as ZipFile[]),
        link: stone?.downloadData as string,
        boxLink: box?.link as string,
      };

      setStoneInfo(newStoneInfo);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, isFullLoading, isSomewhereError, path, stoneID, boxID]);

  if (!isFullLoading && !isSomewhereError && data !== undefined && stoneInfo === null) {
    setIsGenerating(true);
    generateActors().then(() => {
      setIsGenerating(false);
    });
  }

  return {
    isLoading: isFullLoading,
    isError: isSomewhereError,
    stoneInfo,
  };
};
