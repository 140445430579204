import { useState } from "react";
import { SortButton, Loader } from "../../components";

import {
  ManageTableSortValue,
  ManageTableSortValueReturn,
  RequestStatus,
  SortDirection,
  basicTableDateFormat,
  PaginationStartNumber,
} from "../../utils/consts";
import { RequestItem } from "../../utils/models";
import { useGetBoxList } from "../../queries";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { formatDate } from "../../utils/helpers";
import { TableWithPaginationWrapper } from "../../components/TableWithPaginationWrapper";

const selectValue = (selectedCol: string) => {
  if (selectedCol === ManageTableSortValue.RequestDate) {
    return ManageTableSortValueReturn.RequestDate;
  }
  // ManageTableSortValue.Status
  return ManageTableSortValueReturn.Status;
};

export const ManageTable = ({ data }: { data: RequestItem[] }) => {
  const [sortTableDir, setSortTableDir] = useState<SortDirection.ASC | SortDirection.DESC>(SortDirection.DESC);

  const filteredOfferData: RequestItem[] = data.filter((item) =>
    [
      RequestStatus.Acknowledged.toString(),
      RequestStatus.InProgress.toString(),
      RequestStatus.Submitted.toString(),
      RequestStatus.Cancelled.toString(),
      RequestStatus.NotOffered.toString(),
    ].includes(item.status.toLowerCase()),
  );

  const { isLoading, data: boxes, isError } = useGetBoxList();
  const [sortTableColumn, setSortTableColumn] = useState<string>(ManageTableSortValue.RequestDate);

  const navigate = useNavigate();

  if (isLoading || !Array.isArray(boxes)) {
    return <Loader hasError={isError} />;
  }

  const handleSortClick = (colName: string, sortTableDir: string) => {
    setSortTableColumn(colName);
    if (sortTableDir === SortDirection.DESC) {
      setSortTableDir(SortDirection.ASC);
    } else {
      setSortTableDir(SortDirection.DESC);
    }
  };

  const sortedData = filteredOfferData.sort((a: RequestItem, b: RequestItem) => {
    const key = selectValue(sortTableColumn);
    if (key === ManageTableSortValueReturn.RequestDate) {
      return (sortTableDir === SortDirection.DESC ? -1 : 1) * moment(a.date).diff(moment(b.date), "seconds");
    }
    const valueA = (a as any)[selectValue(sortTableColumn)];
    const valueB = (b as any)[selectValue(sortTableColumn)];

    return sortTableDir === SortDirection.DESC ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
  });

  const manageTableCols = [
    <SortButton
      colName={"Order Requested Date"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "Order Requested Date" ? sortTableDir : SortDirection.None}
    />,
    "Request ID",
    "Sightholder Name",
    "PShape",
    "Quantity",
    "Request Delivery Date",
    "Status",
  ];

  return (
    <div className="manage-table">
      <TableWithPaginationWrapper
        itemsPerPage={PaginationStartNumber}
        allItems={sortedData.length}
        columns={manageTableCols}
        data={sortedData.map((order) => {
          const onSelect = () => {
            navigate(`/manage/${order.id}`);
          };
          const formattedDate = formatDate(order.date || "");
          return [
            { value: formattedDate, tdClass: "manage first-letter-cap cell_1", onClick: onSelect },
            { value: order.id, tdClass: "cell_2 first-letter-cap", onClick: onSelect },
            { value: order.sightHolder.name.toLowerCase(), tdClass: "cell_3 first-letter-cap", onClick: onSelect },
            { value: order.shape.toLowerCase(), tdClass: "first-letter-cap cell_4", onClick: onSelect },
            { value: order.quantity, tdClass: "first-letter-cap cell_5", onClick: onSelect },
            {
              value: moment(order.deliveryDate).format(basicTableDateFormat),
              tdClass: "first-letter-cap cell_6",
              onClick: onSelect,
            },
            { value: order.status.toLowerCase(), tdClass: "first-letter-cap cell_7", onClick: onSelect },
          ];
        })}
      />
    </div>
  );
};
