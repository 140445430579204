import { PreviousBox, NextBox } from "../GlobalIcons";

export type PaginationProps = {
  itemsPerPage: number;
  allItems: number;
  handlePageChange?: (pageNumber: number) => void;
  currentPage?: number;
  setCurrentPage?: (pageNumber: number) => void;
};

export const Pagination = ({
  itemsPerPage,
  allItems,
  handlePageChange = () => {},
  currentPage = 1,
  setCurrentPage = () => {},
}: PaginationProps) => {
  const buttonsToShow = Math.ceil(allItems / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxButtonsToShow = 3;
    const onlyButtons = 5;

    if (buttonsToShow <= maxButtonsToShow) {
      for (let i = 1; i <= buttonsToShow; i++) {
        pageNumbers.push(i);
      }
    } else if (buttonsToShow <= onlyButtons) {
      for (let i = 1; i <= maxButtonsToShow + 1; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage <= maxButtonsToShow) {
        for (let i = 2; i <= maxButtonsToShow + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(buttonsToShow);
      } else if (currentPage >= buttonsToShow - maxButtonsToShow + 1) {
        pageNumbers.push("...");
        for (let i = buttonsToShow - maxButtonsToShow; i <= buttonsToShow; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(buttonsToShow);
      }
    }

    return pageNumbers.map((pageNumber, index) => (
      <div className="page-item" key={index}>
        {pageNumber === "..." ? (
          <div className="dots">...</div>
        ) : (
          <button
            className={`${pageNumber === currentPage ? "pagination-btn-active" : "pagination-btn"}`}
            onClick={() => {
              if (typeof pageNumber === "number" && setCurrentPage && handlePageChange) {
                setCurrentPage(pageNumber);
                handlePageChange(pageNumber);
              }
            }}
          >
            {pageNumber}
          </button>
        )}
      </div>
    ));
  };

  return (
    <div className="pagination-block">
      <div className="pagination-items">
        <button
          className="pagination-arrow"
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1);
            handlePageChange(currentPage - 1);
          }}
        >
          <PreviousBox />
        </button>
        {renderPageNumbers()}
        <button
          className="pagination-arrow"
          disabled={currentPage === buttonsToShow}
          onClick={() => {
            setCurrentPage(currentPage + 1);
            handlePageChange(currentPage + 1);
          }}
        >
          <NextBox />
        </button>
      </div>
    </div>
  );
};
