import { caratRange, clarityRange, colourRange, fluorescenceRange, makeRange, RequestStatus } from "../../utils/consts";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser, useGetCurateRequestItem } from "../../queries";
import { NewRequestCurate } from "./newRequest";
import { useEffect, useState } from "react";
import { Loader } from "../../components";
import { RequestItem, User } from "../../utils/models";

const getEmptyRequest = (): RequestItem => ({
  shape: "",
  quantity: "",
  deliveryDate: "",
  status: RequestStatus.Submitted,
  weight: {
    low: caratRange.min.toString(),
    high: caratRange.max.toString(),
  },
  color: {
    low: colourRange[0],
    high: colourRange[colourRange.length - 1],
  },
  clarity: {
    low: clarityRange[0],
    high: clarityRange[clarityRange.length - 1],
  },
  flu: {
    low: fluorescenceRange[0],
    high: fluorescenceRange[fluorescenceRange.length - 1],
  },
  make: {
    low: makeRange[0],
    high: makeRange[makeRange.length - 1],
  },
  sightHolder: {
    id: "",
    name: "",
  },
});

export const NewRequestWrapper = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState(getEmptyRequest());
  const { isLoading, data, isError } = useGetCurateRequestItem(id);
  const user = useGetUser();

  useEffect(() => {
    if (id?.toLowerCase() !== "new" && data) {
      const item = data;
      if (item) {
        const newRequestData: RequestItem = {
          ...requestData,
          shape: item.shape,
          quantity: item.quantity.toString(),
          deliveryDate: item.deliveryDate,
          weight: item.weight,
          color: item.color,
          clarity: item.clarity,
          flu: item.flu,
          make: item.make,
          id: item.id,
          sightHolder: item.sightHolder,
          status: item.status,
          offer: item.offer,
        };
        setRequestData(newRequestData);
      } else {
        setRequestData(getEmptyRequest());
      }
    } else {
      setRequestData(getEmptyRequest());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [id, data]);

  const setNewEmptyRequest = () => {
    setRequestData(getEmptyRequest());
    navigate("/curate/my-offers/request/new");
  };

  if (isLoading || user.isLoading || user.isError || isError) {
    return <Loader hasError={isError || user.isError} />;
  }

  return (
    <NewRequestCurate
      setNewEmptyRequest={setNewEmptyRequest}
      user={user.data as User}
      requestID={id as string}
      requestData={requestData}
    />
  );
};
