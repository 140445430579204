import { Col, Row } from "react-bootstrap";

export type TabItem = {
  title: string;
  content: any;
};

type TabSwitcherProps = {
  data: TabItem[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
};

export const TabSwitcher = ({ data, selectedTab, setSelectedTab }: TabSwitcherProps) => {

  return (
    <>
      <Row>
        {data.map(({ title }, index) => {
          const active = index === selectedTab;
          let classNames = "tab-header caption-large-upper medium";
          if (active) {
            classNames += " active heavy";
          }
          return (
            <Col key={index} className={classNames} onClick={() => setSelectedTab(index)}>
              {title}
            </Col>
          );
        })}
      </Row>
      <Row className="tab-switcher-content">{data[selectedTab].content}</Row>
    </>
  );
};
