import { useEffect } from "react";
import { Loader } from "../../components";
import { useGetStoneList } from "../../queries";
import { ManageStatusTable } from "./ManageStatusTable";

export type ManageStatusTableWrapperProps = {
  boxId?: string;
  setStoneCount?: (value: number) => void;
};
export const ManageStatusTableWrapper = ({ boxId, setStoneCount }: ManageStatusTableWrapperProps) => {
  const { isLoading, data, isError } = useGetStoneList(boxId);

   useEffect(() => {
     if (data && setStoneCount) {
       let countedNumber = data?.length;
       setStoneCount(countedNumber);
     }
   }, [data, setStoneCount]);

  if (isLoading || isError || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }

  return (
    <ManageStatusTable
      data={data.sort((a, b) => {
        const aNum = parseFloat(a.weight);
        const bNum = parseFloat(b.weight);
        return bNum - aNum;
      })}
    />
  );
};
