import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";

export type BoxItem = {
  id: string;
  stoneCount: number;
  sightHolderId: string;
  sightHolderName: string;
  sightNumber: string;
  createdDate: string;
  dispatchDate?: string;
  link: string;
};

export type User = {
  id: number;
  name: string;
  lastName: string;
  email: string;
  organisation: {
    name: string;
    id: string;
  };
  access: {
    connect: boolean;
    curate: boolean;
    manage: boolean;
  };
  tnc: TnC[];
};

export type ZipFile = {
  filename: string;
  index: number;
  file: ArrayBuffer;
};

export type StoneItem = {
  id: string;
  weight: string;
  color: string;
  flu: string;
  filenames: {
    surface: string;
    mesh: string[];
  };
  webData: string;
  downloadData: string;
  polish: Stone[];
};

export type PolyItem = {
  id: string;
  shape: string;
  weight: string;
  color: string;
  flu: string;
  grade: string;
  actor: vtkActor;
  wireframe?: vtkActor;
  meshes?: vtkActor[];
  clarity: string;
};

export type ImageContent = {
  file: ArrayBuffer;
  url: string;
  name: string;
};

export type StoneData = {
  weight: string;
  id: string;
  color: string;
  flu: string;
  sightNumber: string;
  surface: vtkActor | null;
  surfaceMesh: vtkActor[];
  mesh: vtkActor[];
  poly: PolyItem[];
  images: ImageContent[];
  link: string;
  boxLink: string;
};

export type GroupedData = {
  [key: string]: PolyItem[];
};

export type Stone = {
  polishedID: string;
  shape: string;
  weight: string;
  color: string;
  flu: string;
  grade: string;
  clarity: string;
  filename: string;
  make: string;
};

export enum TnCService {
  AlphaCurate = "Alpha Curate",
  AlphaConnect = "Alpha Connect",
  Streams = "stream",
}

export type TnCRaw = {
  service: string;
  status: boolean;
  accepted_version: number;
  latest_version: number;
  contentLocation: string;
};
export type TnC = {
  service: TnCService;
  status: boolean;
  acceptedVersion: number;
  latestVersion: number;
  contentLocation: string;
};

export type Offer = {
  stoneCount: number | null;
  validTo: string | null;
  price: string | null;
  matchRate: string | null;
  boxId: string | null;
  expectedDeliveryDate: string | null;
  acceptedOfferDate: string | null;
  offerDate: string | null;
};

export type RequestItem = {
  sightHolder: {
    id: string;
    name: string;
  };
  createdBy?: {
    userID?: string;
    name?: string;
    lastName?: string;
    email?: string;
  };
  date?: string;
  id?: string;
  shape: string;
  quantity: string;
  weight: { low: string; high: string };
  color: { low: string; high: string };
  clarity: { low: string; high: string };
  flu: { low: string; high: string };
  make: { low: string; high: string };
  deliveryDate: string;
  status: string;
  offer?: Offer;
};

export type OrganizationRaw = {
  OrgID: string;
  OrgType: string;
};

export type PermissionRaw = {
  PermissionID: number;
  PermissionName: string;
};

export type RoleRaw = {
  RoleID: number;
  RoleName: string;
  permissions: PermissionRaw[];
};

export type UserRaw = {
  UserID: number;
  Name: string;
  LastName: string;
  Email: string;
  CreatedAt: string;
  organisations: OrganizationRaw[];
  roles: RoleRaw[];
  TnC: TnCRaw[];
};

export type BoxItemRaw = {
  boxId?: string;
  StoneCount: string;
  SightHolderId: string;
  SightHolderName?: string;
  SightId?: string;
  CreatedDate?: string;
  DispatchDate?: string;
  boxdownloaddata?: string;
};

export type RequestItemRaw = {
  SightHolderId: string;
  SightHolderName: string;
  requestedDate: string;
  requestId?: string;
  shape: string;
  quantity: string;
  createdBy?: {
    UserID: string;
    Name: string;
    LastName: string;
    Email: string;
  };
  weight: { low: string; high: string };
  colour: { low: string; high: string };
  clarity: { low: string; high: string };
  flu: { low: string; high: string };
  make: { low: string; high: string };
  requestedDeliveryDate: string;
  status: string;
  offer?: {
    stoneCount: string | null;
    validTo: string | null;
    offerPrice: string | null;
    matchRate: string | null;
    boxId: string | null;
    expectedDeliveryDate: string | null;
    acceptedOfferDate: string | null;
    offeredDate: string | null;
  };
};

export type PolishedItemRaw = {
  PolishedId: string;
  Shape: string;
  Weight: string;
  Colour: string;
  Flu: string;
  Grade: string;
  Clarity: string | null;
  Make?: string;
  plyfilename: string;
};

export type StoneRaw = {
  PolishedId: string;
  Shape: string;
  Weight: string;
  Colour: string;
  Flu: string;
  Grade: string;
  plyfilename: string;
};

export type StoneItemRaw = {
  StoneId: string;
  RoughWeight: string;
  RoughColour: string;
  RoughFlu: string;
  surfacefilename: string;
  meshfilename: string | string[];
  stonewebdata: string;
  stonedownloaddata: string;
  polishedfits?: PolishedItemRaw[];
};
