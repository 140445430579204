import { useGetBoxList, useGetStoneList } from "../../queries";
import { useNavigate, useParams } from "react-router-dom";
import { BoxItem } from "../../utils/models";
import { StoneList } from "./stoneList";
import { Loader } from "../../components";

export const StoneListWrapper = () => {
  const { boxID } = useParams();
  const navigate = useNavigate();
  const { isLoading: isLoadingBoxes, data: boxList, isError: isErrorBox } = useGetBoxList();

  const { isLoading: isLoadingStones, data: stoneList, isError: isErrorStones } = useGetStoneList(boxID);
  if (isLoadingStones || isLoadingBoxes || !Array.isArray(boxList) || !Array.isArray(stoneList)) {
    return <Loader hasError={isErrorBox || isErrorStones} />;
  }
  const box = boxList.find((el) => el.id === boxID);

  if (!box) {
    navigate("/404");
  }
  return <StoneList data={stoneList} box={box as BoxItem} />;
};
