import { RequestItem } from "../../utils/models";
import { useGetStoneList } from "../../queries";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { StoneContent } from "./stoneContent";
import { OfferLeftPanelWrapper } from "./offerLeftPanelWrapper";
import { Loader } from "../../components";
import { WiderTableScreen } from "../../utils/consts";

export const OfferRequest = ({ request }: { request: RequestItem }) => {
  const stones = useGetStoneList(request?.offer?.boxId ?? "");
  const [is3D, setIs3D] = useState<boolean>(true);
  const [selectedStone, setSelectedStone] = useState<string>("");
  // for refreshing 3d screen without context update
  const [version, setVersion] = useState<number>(0);

  const handleRefresh3D = () => {
    setVersion(version + 1);
  };

  useEffect(() => {
    if (request?.offer?.boxId && !stones.isLoading && Array.isArray(stones.data) && stones.data.length) {
      const indexStone = stones.data.findIndex((el) => el.id === selectedStone);
      if (indexStone === -1) {
        setSelectedStone(stones.data[0].id);
      }
    }
  }, [selectedStone, request?.offer?.boxId, stones.isLoading, stones.data]);

  if (request?.offer?.boxId && stones.isLoading && !Array.isArray(stones.data)) {
    return <Loader hasError={stones.isError} />;
  }

  return (
    <Row className={`stone-detail-wrapper wider-panel  big ${is3D ? "active_3d" : "active_2d"}`}>
      {is3D && (
        <OfferLeftPanelWrapper
          requestDetails={request}
          stones={stones.data}
          selectedStone={selectedStone}
          setSelectedStone={setSelectedStone}
          boxId={request?.offer?.boxId as string}
          handleRefresh3D={handleRefresh3D}
        />
      )}
      <Col className={`view-container ${window.innerWidth < WiderTableScreen ? "col-sm-12 wider-panel" : ""}`}>
        {request?.offer?.boxId && (
          <StoneContent
            is3D={is3D}
            setIs3D={setIs3D}
            boxID={request.offer.boxId}
            stoneID={selectedStone}
            shape={request.shape}
            version={version}
            setVersion={setVersion}
          />
        )}
      </Col>
    </Row>
  );
};
