import DatePicker from "react-date-picker/dist/entry.nostyle";
import { useEffect, useRef } from "react";
import moment from "moment-timezone";

export type DatePickerInputProps = {
  label?: string;
  dateValue: any;
  onChangeDateValue: (date: any) => void;
  handleChangeDate: (date: any) => void;
  datePickerError?: boolean;
  icon?: any;
  isOpen?: boolean;
  isCloseCalendar?: boolean;
  minDate?: any;
  errorMessage?: string;
};

const format = (inputDate: Date) => {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${date}/${month}/${year}`;
};

export const DatePickerInput = ({
  label,
  dateValue,
  handleChangeDate,
  datePickerError,
  icon,
  isOpen,
  isCloseCalendar,
  minDate,
  errorMessage,
}: DatePickerInputProps) => {
  const minDateValue = minDate || moment().add(1, "days").toDate();
  const ref = useRef();
  useEffect(() => {
    const elements = document.querySelectorAll(".react-date-picker__inputGroup__year");
    for (let i = 0; i < elements.length; i++) {
      (elements[i] as any).style.width = "45px";
    }
  }, []);

  const dateInvalid = dateValue === undefined || dateValue === null || dateValue === "";

  useEffect(() => {
    const elementsWrapper = document.getElementsByClassName("react-date-picker__wrapper");
    const elements = document.getElementsByClassName("react-date-picker__inputGroup");

    if (dateInvalid) {
      for (let i = 0; i < elementsWrapper.length; i++) {
        const element = elementsWrapper[i] as HTMLElement;
        element.style.justifyContent = "flex-end";
      }

      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        element.style.display = "none";
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        element.style.display = "block";
      }
    }
  }, [dateValue, dateInvalid]);

  const resetInputs = () => {
    if (ref && ref.current && (ref.current as any).wrapper) {
      try {
        const day = moment(dateValue).format("D");
        const dayInput = (ref.current as any).wrapper.current?.querySelector(
          "input.react-date-picker__inputGroup__day",
        );
        dayInput.value = day;
        const month = moment(dateValue).format("M");
        const monthInput = (ref.current as any).wrapper.current?.querySelector(
          "input.react-date-picker__inputGroup__month",
        );
        monthInput.value = month;
        const year = moment(dateValue).format("YYYY");
        const yearInput = (ref.current as any).wrapper.current?.querySelector(
          "input.react-date-picker__inputGroup__year",
        );
        yearInput.value = year;
      } catch (e) {
        // ignore it
      }
    }
  };

  return (
    <div className={`calendar-custom ${datePickerError ? "error" : ""}`}>
      <label className={dateValue ? "float-label" : ""}>
        <span className="label-text body-large medium ">{label}</span>
      </label>
      <DatePicker
        onChange={(value: any) => handleChangeDate(moment(format(value), "DD/MM/YYYY").tz("UTC", true).toDate())}
        value={dateInvalid ? dateValue : moment(dateValue).toDate()}
        calendarIcon={icon}
        clearIcon={null}
        className="body-small medium"
        closeCalendar={isCloseCalendar}
        name={"date"}
        minDate={minDateValue}
        format="dd.MM.yyyy"
        isOpen={isOpen}
        onCalendarClose={resetInputs}
        // @ts-ignore
        ref={ref}
      />
      {datePickerError && <div className="input-error-message color-error body-small medium">{errorMessage}</div>}
    </div>
  );
};
