import { Link } from "react-router-dom";
import { CurateOrderDetailBox } from "../../utils/consts";
import { NewRequest } from "../";

export const OrderRequestBox = () => {
  return (
    <Link to={"/curate/my-offers/request/new"}>
      <div className="order-request-box">
        <NewRequest />
        <div className="request-text">{CurateOrderDetailBox.NewRequest}</div>
      </div>
    </Link>
  );
};
