import { useGetCurateRequests } from "../../queries";
import { InfoStatusBlock, Loader, PreviousBox } from "../../components/";
import { ManageTable } from "./ManageTable";
import { useNavigate } from "react-router-dom";
import { ButtonText, ThereIsNoData } from "../../utils/consts";

export const Manage = () => {
  const navigate = useNavigate();
  const { isLoading, data, isError } = useGetCurateRequests();
  if (isLoading || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }
  const returnButtons = [
    {
      text: `${ButtonText.ReturnToStream}`,
      onClick: () => navigate("/"),
      class: "btn-primary",
      symbol: <PreviousBox />,
    },
  ];

  return (
    <div className="my-manage">
      {data.length === 0 ? (
        <InfoStatusBlock message={ThereIsNoData.ManageRequests} widerTextBlock={true} buttons={returnButtons} />
      ) : (
        <ManageTable data={data} />
      )}
    </div>
  );
};
