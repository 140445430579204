import { useQueryClient } from "react-query";
import { Loader, StoneTable, SymbolConfirm } from "../../components";
import { useSaveRequest } from "../../queries";
import { RequestStatus, basicTableDateFormat } from "../../utils/consts";
import { copy, sleep } from "../../utils/helpers";
import { RequestItem } from "../../utils/models";
import { useState } from "react";
import moment from "moment-timezone";

export type RequestDetailsTableProps = {
  order: RequestItem;
};
export const RequestDetailsTable = ({ order }: RequestDetailsTableProps) => {
  const saveMutation = useSaveRequest();
  const queryClient = useQueryClient();

  const manageRequestDetailsTableCols = [
    "Order Requested Date",
    "Request ID",
    "Sightholder Name",
    "PShape",
    "Quantity",
    "PWeight",
    "PColour",
    "PClarity",
    "PFlu",
    "PMake",
    "Request Delivery Date",
  ];
  const [isLoading, setIsLoading] = useState(false);

  const handleAcknowledgeRequest = async () => {
    setIsLoading(true);
    const newOfferAcknowledge = copy(order);
    newOfferAcknowledge.status = RequestStatus.Acknowledged;
    await saveMutation.mutateAsync(newOfferAcknowledge);
    // take time for backend
    await sleep(1000);
    await queryClient.invalidateQueries("requestList");
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="request-details-block">
      <div className="headline">
        <div>
          <h3 className="light color_primary">Request</h3>
        </div>

        <div className="order-status match-rate caption-large-upper heavy">{order.status}</div>
      </div>
      <div className="caption-large-upper heavy">Request details</div>
      <div className="request-details-table">
        <StoneTable
          columns={manageRequestDetailsTableCols}
          data={[
            [
              { value: moment(order.date).format(basicTableDateFormat), tdClass: "first-letter-cap cell_1 manage" },
              { value: order.id, tdClass: "first-letter-cap cell_2" },
              { value: order.sightHolder.name.toLowerCase(), tdClass: "first-letter-cap cell_3" },
              { value: order.shape.toLowerCase(), tdClass: "first-letter-cap cell_4" },
              { value: order.quantity, tdClass: "first-letter-cap cell_5" },
              {
                value:
                  order.weight.low === order.weight.high
                    ? order.weight.low
                    : order.weight.low + " - " + order.weight.high,
                tdClass: "first-letter-cap cell_5",
              },
              {
                value:
                  order.color.low === order.color.high ? order.color.low : order.color.low + " - " + order.color.high,
                tdClass: "first-letter-cap cell_5",
              },
              {
                value:
                  order.clarity.low === order.clarity.high
                    ? order.clarity.low
                    : order.clarity.low + " - " + order.clarity.high,
                tdClass: "first-letter-cap cell_5",
              },
              {
                value:
                  order.flu.low === order.flu.high
                    ? order.flu.low.toLowerCase()
                    : order.flu.low.toLowerCase() + " - " + order.flu.high.toLowerCase(),
                tdClass: "first-letter-cap cell_5",
              },
              {
                value:
                  order.make.low === order.make.high.toUpperCase()
                    ? order.make.low.toUpperCase()
                    : order.make.low.toUpperCase() + " - " + order.make.high.toUpperCase(),
                tdClass: "first-letter-cap cell_5",
              },
              {
                value: moment(order.deliveryDate).format(basicTableDateFormat),
                tdClass: "first-letter-cap cell_11",
              },
            ],
          ]}
        />
      </div>
      {order.status.toLowerCase() === RequestStatus.Submitted && (
        <div className="button-wrapper">
          <button className="btn-primary" onClick={handleAcknowledgeRequest}>
            Acknowledge Request <SymbolConfirm />
          </button>
        </div>
      )}
    </div>
  );
};
