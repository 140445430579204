import { Col, Row } from "react-bootstrap";
import { View3D, View2D, StoneLeftPanel, ViewSwitcher, Close, EyeWhite, EyeOnWhite, Loader } from "../../components";
import { useGetStoneInfo } from "../../hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GroupedData } from "../../utils/models";
import { defaultSurfaceColor, defaultWireframeColor, tabletScreenSize } from "../../utils/consts";
import { DevInput } from "./devInput";

export const StoneDetail = () => {
  // TODO remove on prod
  const [showColorInput, setShowColorInput] = useState(false);
  const [surface, setSurface] = useState<number[]>(defaultSurfaceColor);
  const [wireframe, setWireframe] = useState<number[]>(defaultWireframeColor);
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      //ctrl+shift+e
      if (e.code.toLowerCase() === "keye" && e.shiftKey && e.ctrlKey) {
        setShowColorInput(true);
      }
    };
    document.body.addEventListener("keyup", onKeyUp);
    return () => {
      document.body.removeEventListener("keyup", onKeyUp);
    };
  }, [setShowColorInput]);
  //

  const { boxID, stoneID } = useParams();
  const { isLoading, isError, stoneInfo } = useGetStoneInfo(boxID, stoneID);
  const [polishedFitVisible, setPolishedFitVisible] = useState<boolean>(true);
  const [surfaceMeshVisible, setSurfaceMeshVisible] = useState<boolean>(true);
  const [inclusionsVisible, setInclusionsVisible] = useState<boolean>(true);
  const [selectedForm, setSelectedForm] = useState<string>("");
  const [is3D, setIs3D] = useState<boolean>(true);
  const [isHiddenPanel, setIsHiddenPanel] = useState<boolean>(false);
  const navigate = useNavigate();
  // for refreshing 3d screen without context update
  const [version, setVersion] = useState<number>(0);

  if (isLoading || isError || !stoneInfo) {
    return <Loader hasError={isError} />;
  }

  const updateSelected = (id: string) => {
    setSelectedForm(id);
    setVersion(version + 1);
  };

  const updateSurfaceMeshVisible = (value: boolean) => {
    setSurfaceMeshVisible(value);
    setVersion(version + 1);
  };

  const updateInclusionsVisible = (value: boolean) => {
    setInclusionsVisible(value);
    setVersion(version + 1);
  };

  const updatePolishedFitVisible = (value: boolean) => {
    setPolishedFitVisible(value);
    setVersion(version + 1);
  };

  if (stoneInfo.surface) {
    stoneInfo.surface.setVisibility(surfaceMeshVisible);
  }
  stoneInfo.surfaceMesh.forEach((meshItem) => {
    meshItem.setVisibility(inclusionsVisible && surfaceMeshVisible);
  });

  const grouped: GroupedData = {};
  if (selectedForm === "" && polishedFitVisible && stoneInfo?.poly?.length) {
    setSelectedForm(stoneInfo.poly[0].id);
  }

  stoneInfo.poly.forEach((item) => {
    item.actor.getProperty().setColor(surface[0], surface[1], surface[2]);
    item.actor.getProperty().setOpacity(surface[3]);

    if (item.wireframe) {
      item.wireframe.getProperty().setColor(wireframe[0], wireframe[1], wireframe[2]);
      item.wireframe.getProperty().setOpacity(wireframe[3]);
    }

    item.actor.setVisibility(false);
    item.wireframe?.setVisibility(false);
    item.meshes?.forEach((meshItem) => {
      meshItem.setVisibility(false);
    });

    if (item.id === selectedForm && polishedFitVisible) {
      item.actor.setVisibility(true);
      item.wireframe?.setVisibility(true);
      item.meshes?.forEach((meshItem) => {
        meshItem.setVisibility(!surfaceMeshVisible && inclusionsVisible);
      });
    }

    if (!grouped[item.shape]) {
      grouped[item.shape] = [];
    }
    grouped[item.shape].push(item);
  });

  const onGoBack = () => {
    navigate(`/active-boxes/${boxID}/`);
  };

  const hidePanel = () => {
    setIsHiddenPanel(!isHiddenPanel);
  };

  return (
    <Row className={`stone-detail-wrapper ${is3D ? "active_3d" : "active_2d"}`}>
      {is3D && (
        <StoneLeftPanel
          id={stoneInfo.id}
          weight={stoneInfo.weight}
          sightNumber={stoneInfo.sightNumber}
          selected={selectedForm}
          grouped={grouped}
          updateSelected={updateSelected}
          downloadLink={stoneInfo.link}
          boxDownloadLink={stoneInfo.boxLink}
        />
      )}

      <Col className={`view-container ${window.innerWidth < tabletScreenSize ? "col-sm-12" : ""}`}>
        <ViewSwitcher
          additionalClassName={isHiddenPanel ? "hidden" : ""}
          is3D={is3D}
          setIs3D={setIs3D}
          isPolishedVisible={polishedFitVisible}
          surfaceMeshVisible={surfaceMeshVisible}
          updateSurfaceMeshVisible={updateSurfaceMeshVisible}
          inclusionsVisible={inclusionsVisible}
          updateInclusionsVisible={updateInclusionsVisible}
          updateIsPolishedVisible={updatePolishedFitVisible}
        />
        {is3D ? (
          <View3D surface={stoneInfo.surface} mesh={stoneInfo.mesh} poly={stoneInfo.poly} version={version} />
        ) : (
          <View2D images={stoneInfo.images} />
        )}
        {is3D && (
          <div className={"special-icon-wrapper eye"} onClick={hidePanel}>
            {!isHiddenPanel ? <EyeWhite /> : <EyeOnWhite />}
          </div>
        )}
        <div className={"close special-icon-wrapper"} onClick={onGoBack}>
          <Close />
        </div>
      </Col>
      {showColorInput && (
        <DevInput
          setHidden={() => setShowColorInput(false)}
          surface={surface}
          setSurface={(value: number[]) => {
            setSurface(value);
            setVersion(version + 1);
          }}
          wireframe={wireframe}
          setWireframe={(value: number[]) => {
            setWireframe(value);
            setVersion(version + 1);
          }}
        />
      )}
    </Row>
  );
};
