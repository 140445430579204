import { BoxItem, StoneItem } from "../../utils/models";
import { useState } from "react";
import {
  ButtonText,
  ConnectTableSortValue,
  ConnectTableValueReturn,
  ModalTexts,
  SortDirection,
  fluorescenceRange,
} from "../../utils/consts";
import { useNavigate } from "react-router-dom";
import { useBoxNavigation, useDoDownload } from "../../hooks/";
import { useGetStoneList } from "../../queries";
import {
  Modal,
  Checkbox,
  ArrowDown,
  StoneTable,
  SymbolDownload,
  SortButton,
  ConnectDetailCard,
  Loader,
} from "../../components";
import { getHigherByNumber } from "../../utils/helpers";

const selectValue = (selectedCol: string) => {
  if (selectedCol === ConnectTableSortValue.RoughWeight) {
    return ConnectTableValueReturn.RoughWeight;
  }
  if (selectedCol === ConnectTableSortValue.RoughColour) {
    return ConnectTableValueReturn.RoughColour;
  }
  //ConnectTableSortValue.RoughFlu
  return ConnectTableValueReturn.RoughFlu;
};

export const StoneList = ({ data, box }: { data: StoneItem[]; box: BoxItem }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [sortTableDir, setSortTableDir] = useState<SortDirection.ASC | SortDirection.DESC>(SortDirection.DESC);
  const [sortTableColumn, setSortTableColumn] = useState<string>(ConnectTableSortValue.RoughWeight);
  const [isModalOpenBox, setIsModalOpenBox] = useState(false);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const newSelected = [...selected];
    if (checked) {
      newSelected.push(value);
    } else {
      const index = newSelected.indexOf(value);
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);
  };

  const navigate = useNavigate();

  const { isLoading, isError } = useBoxNavigation();

  const stoneListRequest = useGetStoneList(box.id);
  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  if (isLoading || isError || stoneListRequest.isLoading || stoneListRequest.isError) {
    return <Loader />;
  }

  const quantity = data.length;

  const handleSortClick = (colName: string, sortTableDir: string) => {
    setSortTableColumn(colName);

    if (sortTableDir === SortDirection.ASC) {
      setSortTableDir(SortDirection.DESC);
    } else {
      setSortTableDir(SortDirection.ASC);
    }
  };

  let sortedData: StoneItem[] = [];
  const key = selectValue(sortTableColumn);
  if (key) {
    if (key === ConnectTableValueReturn.RoughFlu) {
      const sortOrder = fluorescenceRange.map((value) => value.toLowerCase());
      sortedData = data.sort((a, b) => {
        let indexA = sortOrder.indexOf(a.flu.toLowerCase());
        let indexB = sortOrder.indexOf(b.flu.toLowerCase());

        return sortTableDir === SortDirection.ASC ? indexA - indexB : indexB - indexA;
      });
    } else {
      sortedData = data.sort((a, b) => getHigherByNumber(a, b, key, sortTableDir));
    }
  }

  const connectTableCols = [
    " ",
    "Stone ID",
    <SortButton
      colName={"Rough Weight"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "Rough Weight" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"Rough Colour"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "Rough Colour" ? sortTableDir : SortDirection.None}
    />,
    <SortButton
      colName={"Rough Flu"}
      onClick={handleSortClick}
      sortBy={sortTableColumn === "Rough Flu" ? sortTableDir : SortDirection.None}
    />,
  ];

  let additionalClass = "";
  if (!!selected.length) {
    additionalClass = "has-footer";
  }

  const handleDownloadBox = () => {
    const list: string[] = [];
    selected.forEach((item) => {
      const stone = stoneListRequest.data?.find((el) => el.id === item);
      if (stone) {
        list.push(stone.downloadData);
      }
    });
    setDownloadList(list);
    setIsModalOpenBox(false);
  };
  const modalButtonsBox = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpenBox(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadBox(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  return (
    <>
      <ConnectDetailCard
        quantity={quantity}
        boxId={box.id}
        sightHolder={box.sightHolderName}
        sightDate={box.sightNumber}
      />
      <div className={"stone-list-block " + additionalClass}>
        <StoneTable
          columns={connectTableCols}
          data={sortedData.map((stone) => {
            const onSelect = () => {
              navigate(`/active-boxes/${box.id}/${stone.id}/v3d`);
            };
            return [
              {
                value: (
                  <Checkbox
                    onChange={(checked) => handleCheckboxChange(stone.id, checked)}
                    isChecked={selected.includes(stone.id)}
                  />
                ),
                tdClass: "first-cb",
              },
              { value: stone.id, onClick: onSelect, tdClass: "first-letter-cap cell_5" },
              { value: stone.weight, onClick: onSelect, tdClass: "first-letter-cap cell_5" },
              { value: stone.color.toLowerCase(), onClick: onSelect, tdClass: "first-letter-cap cell_5" },
              { value: stone.flu.toLowerCase(), onClick: onSelect, tdClass: "first-letter-cap cell_5" },
            ];
          })}
        />
      </div>
      {!!selected.length && (
        <div className={"footer-button"}>
          <button className="btn-primary-small" onClick={() => setIsModalOpenBox(true)}>
            {`${ButtonText.DownloadSelected} (${selected.length})`}
            <ArrowDown />
          </button>
        </div>
      )}
      {isModalOpenBox && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadStone}
          modalContent={ModalTexts.ContentDownloadStone}
          buttons={modalButtonsBox}
          setIsModalOpen={setIsModalOpenBox}
        />
      )}
    </>
  );
};
