import { RequestItem, User } from "../../utils/models";
import { useEffect, useState } from "react";
import { clarityRange, colourRange, fluorescenceRange, makeRange, serverDateTimeFormat } from "../../utils/consts";
import { useSaveRequest } from "../../queries";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  NewRequestShape,
  NewRequestStoneDetails,
  NewRequestRangeGroup,
  NewRequestConfirmation,
} from "../../components/";
import { Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

type NewRequestCurateProps = {
  requestID: string;
  requestData: RequestItem;
  setNewEmptyRequest: () => void;
  user: User;
};

export const NewRequestCurate = ({ user, requestID, requestData, setNewEmptyRequest }: NewRequestCurateProps) => {
  const isNew = requestID?.toLowerCase() === "new";
  const [selectedShape, setSelectedShape] = useState(requestData.shape);
  const [formValues, setFormValues] = useState({
    quantity: requestData.quantity,
    date: requestData.deliveryDate,
  });
  const [caratValues, setCaratValues] = useState({
    min: Number(requestData.weight.low),
    max: Number(requestData.weight.high),
  });
  const [colorValues, setColorValues] = useState({
    low: colourRange.indexOf(requestData.color.low),
    high: colourRange.indexOf(requestData.color.high),
  });
  const [clarityValues, setClarityValues] = useState({
    low: clarityRange.indexOf(requestData.clarity.low),
    high: clarityRange.indexOf(requestData.clarity.high),
  });
  const [fluorescenceValues, setFluorescenceValues] = useState({
    low: fluorescenceRange.indexOf(requestData.flu.low),
    high: fluorescenceRange.indexOf(requestData.flu.high),
  });
  const [makeValues, setMakeValues] = useState({
    low: makeRange.indexOf(requestData.make.low),
    high: makeRange.indexOf(requestData.make.high),
  });

  const [isShapeError, setIsShapeError] = useState(false);
  const [isQuantityError, setIsQuantityError] = useState(false);
  const [isRequestedDeliveryDateError, setIsRequestedDeliveryDateError] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSelectedShape(requestData.shape);
    setFormValues({
      quantity: requestData.quantity,
      date: requestData.deliveryDate,
    });
    setCaratValues({
      min: Number(requestData.weight.low),
      max: Number(requestData.weight.high),
    });
    setColorValues({
      low: colourRange.indexOf(requestData.color.low),
      high: colourRange.indexOf(requestData.color.high),
    });
    setClarityValues({
      low: clarityRange.indexOf(requestData.clarity.low),
      high: clarityRange.indexOf(requestData.clarity.high),
    });
    setFluorescenceValues({
      low: fluorescenceRange.indexOf(requestData.flu.low),
      high: fluorescenceRange.indexOf(requestData.flu.high),
    });
    setMakeValues({
      low: makeRange.indexOf(requestData.make.low),
      high: makeRange.indexOf(requestData.make.high),
    });
  }, [requestData]);

  const saveMutation = useSaveRequest();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const saveRequest = async () => {
    const newRequest: RequestItem = {
      ...requestData,
      shape: selectedShape,
      quantity: formValues.quantity,
      deliveryDate: formValues.date,
      weight: {
        low: caratValues.min.toString(),
        high: caratValues.max.toString(),
      },
      color: {
        low: colourRange[colorValues.low],
        high: colourRange[colorValues.high],
      },
      clarity: {
        low: clarityRange[clarityValues.low],
        high: clarityRange[clarityValues.high],
      },
      flu: {
        low: fluorescenceRange[fluorescenceValues.low],
        high: fluorescenceRange[fluorescenceValues.high],
      },
      make: {
        low: makeRange[makeValues.low],
        high: makeRange[makeValues.high],
      },
      sightHolder: {
        id: user.organisation.id,
        name: user.organisation.name,
      },
      date: moment().format(serverDateTimeFormat),
    };

    await saveMutation.mutateAsync(newRequest);
    await queryClient.invalidateQueries("requestList");
  };
  const handleCheckbox = (checked: boolean) => {
    if (checked) {
      setAgreeTerms(true);
      setShowCheckboxError(false);
    } else {
      setAgreeTerms(false);
      setShowCheckboxError(true);
    }
  };
  const handleConfirmRequest = () => {
    let valid = true;
    if (selectedShape === "") {
      setIsShapeError(true);
      valid = false;
    } else {
      setIsShapeError(false);
    }
    if (formValues.quantity === "" || parseInt(formValues.quantity, 10) <= 0) {
      setIsQuantityError(true);
      valid = false;
    } else {
      setIsQuantityError(false);
    }
    if (formValues.date === "") {
      setIsRequestedDeliveryDateError(true);
      valid = false;
    } else {
      setIsRequestedDeliveryDateError(false);
    }

    if (agreeTerms) {
      setAgreeTerms(true);
      setShowCheckboxError(false);
    } else {
      valid = false;
      setAgreeTerms(false);
      setShowCheckboxError(true);
    }
    if (valid) {
      saveRequest();
      setIsModalOpen(true);
      setAgreeTerms(false);
    }
  };

  return (
    <>
      <div className="new-request">
        <h3 className="light color-primary">{isNew ? "New Request" : "Request " + (requestData.id || "")}</h3>
        <Row>
          <Col xs={12} lg={6}>
            <NewRequestStoneDetails
              formValues={formValues}
              setFormValues={setFormValues}
              isQuantityError={isQuantityError}
              setIsQuantityError={setIsQuantityError}
              isRequestedDeliveryDateError={isRequestedDeliveryDateError}
              setIsRequestedDeliveryDateError={setIsRequestedDeliveryDateError}
              isNew={isNew}
            />
            <NewRequestShape
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              isShapeError={isShapeError}
              setIsShapeError={setIsShapeError}
            />
          </Col>
          <Col xs={12} lg={6}>
            <NewRequestRangeGroup
              caratValues={caratValues}
              setCaratValues={setCaratValues}
              colorValues={colorValues}
              setColorValues={setColorValues}
              clarityValues={clarityValues}
              setClarityValues={setClarityValues}
              fluorescenceValues={fluorescenceValues}
              setFluorescenceValues={setFluorescenceValues}
              makeValues={makeValues}
              setMakeValues={setMakeValues}
            />
          </Col>
        </Row>

        <NewRequestConfirmation
          openHomePage={() => navigate("/curate/my-offers/")}
          setNewEmptyRequest={setNewEmptyRequest}
          isNew={isNew}
          handleCheckbox={handleCheckbox}
          handleConfirmRequest={handleConfirmRequest}
          showCheckboxError={showCheckboxError}
          agreeTerms={agreeTerms}
          setAgreeTerms={setAgreeTerms}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
};
