import { useParams } from "react-router-dom";
import { OfferRequest } from "./offerRequest";
import { Loader } from "../../components/";
import { useGetCurateRequestItem } from "../../queries";

export const OfferScreen = () => {
  const { id } = useParams();
  const { isLoading: isCurateLoading, data: item, isError } = useGetCurateRequestItem(id);
  if (isCurateLoading || isError || !item) {
    return <Loader hasError={isError} />;
  }

  return <OfferRequest request={item} />;
};
