import { useQuery, UseQueryResult } from "react-query";
import RequestsData from "../mocks/curateRequests.json";
import { RequestItem, RequestItemRaw } from "../utils/models";
import { AdditionalConfig } from "../utils/consts";
import Server from "../config/server.json";
import Config from "../config/auth.json";
import axios from "axios";
import { serializeCurateItem } from "../utils/serializers";

const fetch = async (): Promise<RequestItemRaw[]> => {
  if (Server.useMocks) {
    return RequestsData;
  }

  const url = Config.endpoint + Server.getCurateRequestList;
  const response = await axios.get(url);
  return response.data;
};

export const useGetCurateRequests = (): UseQueryResult<RequestItem[]> => {
  return useQuery(["requestList"], fetch, {
    select: (list) => {
      return list.map(serializeCurateItem);
    },
    ...AdditionalConfig,
  });
};
