import { useQuery, UseQueryResult } from "react-query";
import axios from "axios";
import userData from "../mocks/user.json";
import { User, UserRaw } from "../utils/models";
import Config from "../config/auth.json";
import Server from "../config/server.json";
import { useAuth } from "../hooks/";
import { AdditionalConfig, Roles } from "../utils/consts";
import { serializeTnC } from "../utils/serializers";

const fetch = async (): Promise<UserRaw> => {
  if (Server.useMocks) {
    return userData;
  }
  const response = await axios.get(Config.userEndpoint);
  return response.data;
};

export const hasRole = (roleType: string[], roles: string[]): boolean => {
  return roleType.some((role) => roles.includes(role));
};

export const useGetUser = (): UseQueryResult<User> => {
  const { idToken } = useAuth();
  return useQuery(["user"], fetch, {
    enabled: !!idToken,
    select: (data) => {
      const roles: string[] = [];
      data.roles.forEach((role) => {
        const roleList = role.permissions.map(({ PermissionName }) => PermissionName);
        roles.push(...roleList);
      });
      let organisationName = "";
      let organizationId = "";
      if (Array.isArray(data.organisations) && data.organisations.length > 0) {
        const orgData = data.organisations.sort((a, b) => parseInt(a.OrgID, 10) - parseInt(b.OrgID, 10))[0];
        organisationName = orgData.OrgType;
        organizationId = orgData.OrgID;
      }

      return {
        id: data.UserID,
        name: data.Name,
        lastName: data.LastName,
        email: data.Email,
        organisation: {
          name: organisationName,
          id: organizationId,
        },
        access: {
          connect: hasRole(Roles.Connect, roles),
          curate: hasRole(Roles.Curate, roles),
          manage: hasRole(Roles.Manage, roles),
        },
        tnc: data.TnC.map(serializeTnC),
      };
    },
    ...AdditionalConfig,
  });
};
