import { useState } from "react";
import { Row } from "react-bootstrap";
import {
  InfoStatusBlock,
  Loader,
  Modal,
  OrderDetailBoxWrapper,
  OrderRequestBox,
  Refresh,
  SortButton,
  SymbolDownload,
  SymbolNext,
  TableWithPaginationWrapper,
} from "../../components";

import {
  basicTableDateFormat,
  ButtonText,
  CuratePageText,
  ModalTexts,
  PaginationStartNumber,
  PlatformTermsAndConditions,
  RequestStatus,
  SortDirection,
  ThereIsNoData,
} from "../../utils/consts";
import { RequestItem, TnCService } from "../../utils/models";
import { useGetBoxList, useSaveRequest } from "../../queries";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useDoDownload } from "../../hooks";
import { copy, formatDate } from "../../utils/helpers";
import moment from "moment-timezone";
import { NotRequiredTnC } from "../../components/TermsAndConditions/NotRequired";

export const MyOffersCurate = ({ data }: { data: RequestItem[] }) => {
  const [sortTableDir, setSortTableDir] = useState<SortDirection.ASC | SortDirection.DESC>(SortDirection.DESC);
  const [modalSelectedBox, setModalSelectedBox] = useState("");
  const { isLoading, data: boxes, isError } = useGetBoxList();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCancelRequestOpen, setIsModalCancelRequestOpen] = useState(false);
  const [requestCancel, setRequestCancel] = useState<RequestItem | null>(null);
  const navigate = useNavigate();
  const cancelMutation = useSaveRequest();
  const queryClient = useQueryClient();
  const [isModalTandCOpen, setIsModalTandCOpen] = useState(false);

  const handleCancel = async (order: RequestItem) => {
    setIsModalCancelRequestOpen(true);
    setRequestCancel(order);
  };

  const handleCancelConfirm = async () => {
    if (requestCancel) {
      const newRequestCancel = copy(requestCancel);
      newRequestCancel.status = RequestStatus.Cancelled;
      await cancelMutation.mutateAsync(newRequestCancel);
      await queryClient.invalidateQueries("requestList");
      setIsModalCancelRequestOpen(false);
    }
  };

  const handleEdit = (order: RequestItem) => {
    navigate("/curate/my-offers/request/" + order.id);
  };

  const handleOpenAcceptedOffer = (order: RequestItem) => {
    navigate("/curate/my-offers/" + order.id);
  };

  const [downloadList, setDownloadList] = useState<string[]>([]);
  const { isDone } = useDoDownload(downloadList);

  if (isDone && downloadList.length > 0) {
    setDownloadList([]);
  }

  if (isLoading || !Array.isArray(boxes)) {
    return <Loader hasError={isError} />;
  }

  const handleSortClick = (colName: string, sortTableDir: string) => {
    if (sortTableDir === SortDirection.DESC) {
      setSortTableDir(SortDirection.ASC);
    } else {
      setSortTableDir(SortDirection.DESC);
    }
  };

  const sortedData = data.sort((a, b) => {
    return (sortTableDir === SortDirection.DESC ? -1 : 1) * moment(a.date).diff(moment(b.date), "seconds");
  });

  const curateTableCols = [
    <SortButton colName={"Order Requested Date"} onClick={handleSortClick} sortBy={sortTableDir} />,
    "Request ID",
    "PShape",
    "Quantity",
    "PWeight",
    "PColour",
    "PClarity",
    "PFlu",
    "PMake",
    "Request Delivery Date",
    "Status",
    "",
  ];

  const handleOpenModal = (boxID?: string) => {
    if (boxID) {
      setModalSelectedBox(boxID);
      setIsModalOpen(true);
    }
  };

  const handleDownloadRequest = () => {
    const box = boxes.find((el) => el.id === modalSelectedBox);
    if (box) {
      setDownloadList([box.link]);
    }
    setIsModalOpen(false);
  };

  const modalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.Download}`,
      onClick: () => handleDownloadRequest(),
      class: "btn-primary btn-wide",
      symbol: <SymbolDownload />,
    },
  ];

  const modalCancelRequestButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => handleCancelConfirm(),
      class: "btn-primary-warning btn-wide",
    },
  ];

  const noDataButtons = [
    {
      text: `${ButtonText.NewRequest}`,
      onClick: () => navigate("/curate/my-offers/request/new"),
      class: "btn-primary",
    },
  ];

  return (
    <div className="platform-curate">
      {sortedData.length === 0 ? (
        <InfoStatusBlock message={ThereIsNoData.CurateRequests} widerTextBlock={true} buttons={noDataButtons} />
      ) : (
        <div className="curate-platform">
          <div className="my-offers">
            <div className="headline">
              <h3 className="light">{CuratePageText.OffersHeading}</h3>
              <div className="subtitle medium">{CuratePageText.OffersSubtitle}</div>
            </div>
            <Row xs={12}>
              <div className="offers-action">
                <OrderRequestBox />
                <OrderDetailBoxWrapper />
              </div>
            </Row>
          </div>
          <div className="my-requests">
            <div className="headline">
              <h3 className="light">{CuratePageText.RequestsHeading}</h3>
              <div className="subtitle medium">{CuratePageText.RequestsSubtitle}</div>
            </div>
            <TableWithPaginationWrapper
              itemsPerPage={PaginationStartNumber}
              allItems={sortedData.length}
              stickyCols={2}
              columns={curateTableCols}
              data={sortedData.map((order) => {
                const formattedDate = formatDate(order.date || "");
                return [
                  { value: formattedDate, tdClass: "curate first-letter-cap cell_1" },
                  { value: order.id, tdClass: "first-letter-cap cell_2" },
                  { value: order.shape.toLowerCase(), tdClass: "first-letter-cap cell_3" },
                  { value: order.quantity, tdClass: "first-letter-cap cell_4" },
                  {
                    value:
                      order.weight.low === order.weight.high
                        ? order.weight.low
                        : order.weight.low + " - " + order.weight.high,
                    tdClass: "first-letter-cap cell_5",
                  },
                  {
                    value:
                      order.color.low === order.color.high
                        ? order.color.low.toLowerCase()
                        : order.color.low.toLowerCase() + " - " + order.color.high.toLowerCase(),
                    tdClass: "first-letter-cap cell_5",
                  },
                  {
                    value:
                      order.clarity.low === order.clarity.high
                        ? order.clarity.low.toUpperCase()
                        : order.clarity.low.toUpperCase() + " - " + order.clarity.high.toUpperCase(),
                    tdClass: "first-letter-cap cell_5",
                  },
                  {
                    value:
                      order.flu.low === order.flu.high
                        ? order.flu.low.toLowerCase()
                        : order.flu.low.toLowerCase() + " - " + order.flu.high.toLowerCase(),
                    tdClass: "first-letter-cap cell_5",
                  },
                  {
                    value:
                      order.make.low === order.make.high
                        ? order.make.low.toUpperCase()
                        : order.make.low.toUpperCase() + " - " + order.make.high.toUpperCase(),
                    tdClass: "first-letter-cap cell_5",
                  },
                  {
                    value: moment(order.deliveryDate).format(basicTableDateFormat),
                    tdClass: "first-letter-cap cell_9",
                  },
                  { value: order.status, tdClass: "first-letter-cap sticky-cell2 cell_10" },
                  {
                    value: (
                      <div className="buttons-wrapper">
                        {order.status.toLowerCase() === RequestStatus.Submitted && (
                          <>
                            <button className="btn-secondary-small btn-secondary" onClick={() => handleEdit(order)}>
                              {ButtonText.Edit}
                              <Refresh />
                            </button>

                            <button className="special-warning btn-primary-warning" onClick={() => handleCancel(order)}>
                              {ButtonText.Cancel}
                            </button>
                          </>
                        )}
                        {order.status.toLowerCase() === RequestStatus.Offered && (
                          <button
                            className="btn-secondary-small"
                            onClick={() => handleOpenModal(order.offer?.boxId || undefined)}
                          >
                            {ButtonText.Download}
                            <SymbolDownload />
                          </button>
                        )}
                        {order.status.toLowerCase() === RequestStatus.Accepted && (
                          <button className="btn-secondary-small" onClick={() => handleOpenAcceptedOffer(order)}>
                            {ButtonText.Details}
                            <SymbolNext />
                          </button>
                        )}
                      </div>
                    ),
                    tdClass: "sticky-cell1 cell_11 buttons_status_col",
                  },
                ];
              })}
            />
          </div>
        </div>
      )}
      <div className="platform-tc ">
        <div className="tc-text" onClick={() => setIsModalTandCOpen(true)}>
          {PlatformTermsAndConditions.Curate}
        </div>
      </div>
      {isModalTandCOpen && <NotRequiredTnC onClose={() => setIsModalTandCOpen(false)} type={TnCService.AlphaCurate} />}

      {isModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderDownloadBox}
          modalContent={ModalTexts.ContentDownloadBox}
          buttons={modalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isModalCancelRequestOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderCancel}
          modalContent={ModalTexts.ContentCancel}
          buttons={modalCancelRequestButtons}
          setIsModalOpen={setIsModalCancelRequestOpen}
        />
      )}
    </div>
  );
};
