import { Diamonds, RequestErrors } from "../../utils/consts";

export type NewRequestShapeProps = {
  selectedShape: string;
  setSelectedShape: (value: string) => void;
  isShapeError: boolean;
  setIsShapeError: (value: boolean) => void;
};

export const NewRequestShape = ({
  selectedShape,
  setSelectedShape,
  isShapeError,
  setIsShapeError,
}: NewRequestShapeProps) => {
  const handleSelectedShape = (title: string) => {
    setSelectedShape(title);
    setIsShapeError(false);
  };
  return (
    <>
      <div className={`shape-box color-primary ${isShapeError ? "error-border" : ""}`}>
        <div className="box-header heavy caption-large-upper">SHAPE</div>
        <div className="box-shapes">
          {Object.keys(Diamonds).map((diamondKey, index) => {
            const diamond = Diamonds[diamondKey];
            let shapeClassName =
              selectedShape === "" ? "shape" : selectedShape === diamond.Title ? "shape active" : "shape not-active";

            return (
              <div className={shapeClassName} key={index} onClick={() => handleSelectedShape(diamond.Title)}>
                <div className="shape-group-block">
                  {diamond.Icon}
                  <div className="button-small-text medium color-primary shape-name">{diamond.Title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isShapeError && (
        <div className="request-error-message color-error body-small medium error-border">{RequestErrors.Shape}</div>
      )}
    </>
  );
};
