import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import AuthConfig from "../config/auth.json";
import { generateCodeChallenge, getRandomString } from "../utils/codeChallenge";
import { apiCallResolveMargin, logoutKey, Second } from "../utils/consts";

export type AuthTokenData = {
  token: string;
  expiresIn?: number;
  refreshToken?: string;
};

type AuthContextProps = {
  getAuthEndpoint: () => Promise<string>;
  isLoading: boolean;
  accessToken: string;
  idToken: string;
  setIsLoading: (value: boolean) => void;
  loadTokenByCode: (code: string) => Promise<boolean>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextProps>({
  getAuthEndpoint: async () => "",
  isLoading: true,
  accessToken: "",
  idToken: "",
  setIsLoading: () => {},
  loadTokenByCode: async () => false,
  logout: () => {},
});

const StorageVerifierKey = "Verifier";
const ClientIDPath = "{CLIENT_ID}";
const RedirectPath = "{REDIRECT}";
const ChallengePath = "{CHALLENGE}";

export const setTokenForAxios = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeTokenFromAxios = () => {
  delete axios.defaults.headers.common.Authorization;
};

const RandomStringLength = 128;

export const getAuthEndpoint = async () => {
  let url = AuthConfig.login;
  url = url.replace(ClientIDPath, AuthConfig.clientID);
  url = url.replace(RedirectPath, AuthConfig.redirect);
  const challenge = getRandomString(RandomStringLength);
  window.localStorage.setItem(StorageVerifierKey, challenge);
  url = url.replace(ChallengePath, await generateCodeChallenge(challenge));
  return AuthConfig.url + url;
};

export const getLogoutEndpoint = () => {
  let url = AuthConfig.logout;
  const logoutRedirect = encodeURI(AuthConfig.redirect);
  url = url.replace(ClientIDPath, AuthConfig.clientID);
  url = url.replace(RedirectPath, logoutRedirect);
  return AuthConfig.url + url;
};

export const AuthProvider = ({ children }: { children: any }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessTokenInternal] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [idToken, setIdTokenInternal] = useState("");

  const setTimeOutRef = useRef<NodeJS.Timeout | undefined>();
  const setLogoutTimeOutRef = useRef<NodeJS.Timeout | undefined>();

  const logout = async () => {
    try {
      await axios.post(
        AuthConfig.url + "oauth2/revoke",
        {
          token: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
            Authorization: "Basic " + btoa(AuthConfig.clientID + ":" + AuthConfig.clientSecret),
          },
        },
      );
    } catch (e) {
      // put it to console and ignore
      console.log("something went wrong with revocation token", e);
    }
    removeTokenFromAxios();
    window.localStorage.setItem(logoutKey, getRandomString());
    window.location.href = getLogoutEndpoint();
  };

  const refreshTokenCallback = useCallback(async (token: string): Promise<void> => {
    try {
      const encoder = new TextEncoder();
      const response = await axios.post(
        AuthConfig.url + AuthConfig.cognito,
        {
          grant_type: "refresh_token",
          client_id: AuthConfig.clientID,
          refresh_token: token,
          client_secret: AuthConfig.clientSecret,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic " + encode(encoder.encode([AuthConfig.clientID, AuthConfig.clientSecret].join(":"))),
          },
        },
      );
      await setAccessToken(response.data.access_token, response.data.id_token, token, response.data.expires_in);
    } catch (e) {
      logout();
    }
    // for fix dependency loop
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const setAccessToken = useCallback(
    async (token: string, idToken: string, refreshToken: string, expiresIn: number) => {
      window.localStorage.removeItem(logoutKey);
      setAccessTokenInternal(token);
      setIdTokenInternal(idToken);
      setRefreshToken(refreshToken);
      const currentTimeoutRef = setTimeOutRef.current;
      if (currentTimeoutRef) {
        clearTimeout(currentTimeoutRef);
      }
      const currentLogoutTimeoutRef = setLogoutTimeOutRef.current;
      if (currentLogoutTimeoutRef) {
        clearTimeout(currentLogoutTimeoutRef);
      }
      setTimeOutRef.current = setTimeout(
        () => refreshTokenCallback(refreshToken),
        expiresIn * Second - apiCallResolveMargin,
      );
      setLogoutTimeOutRef.current = setInterval(() => {
        if (window.localStorage.getItem(logoutKey)) {
          logout();
        }
      }, Second);
      setTokenForAxios(idToken);
    },
    [refreshTokenCallback, setAccessTokenInternal],
  );

  const loadTokenByCode = useCallback(
    async (code: string): Promise<boolean> => {
      setIsLoading(true);
      const verifier = window.localStorage.getItem(StorageVerifierKey);
      window.localStorage.removeItem(StorageVerifierKey);

      if (verifier) {
        const encoder = new TextEncoder();
        try {
          const response = await axios.post(
            AuthConfig.url + AuthConfig.cognito,
            {
              grant_type: "authorization_code",
              code,
              client_id: AuthConfig.clientID,
              redirect_uri: AuthConfig.redirect,
              code_verifier: verifier,
              client_secret: AuthConfig.clientSecret,
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization:
                  "Basic " + encode(encoder.encode([AuthConfig.clientID, AuthConfig.clientSecret].join(":"))),
              },
            },
          );
          await setAccessToken(
            response.data.access_token,
            response.data.id_token,
            response.data.refresh_token,
            response.data.expires_in,
          );
          setIsLoading(false);
          return true;
        } catch (e) {
          console.log("auth error", e);
        }
      }
      setIsLoading(false);
      return false;
    },
    [isLoading],
  );

  const contextObject = useMemo(
    () => ({
      getAuthEndpoint,
      isLoading,
      idToken,
      setIsLoading: (value: boolean) => {
        setIsLoading(value);
      },
      loadTokenByCode,
      accessToken,
      logout,
    }),
    [isLoading, setIsLoading, accessToken, loadTokenByCode],
  );

  return <AuthContext.Provider value={contextObject}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
