import { useQueryClient } from "react-query";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCurateRequests, useSaveRequest } from "../../queries";
import { useState } from "react";
import {
  Calendar,
  ClockTimeIcon,
  Close,
  DatePickerInput,
  Loader,
  ManageOfferInfoBar,
  InfoStatusBlock,
  Modal,
  PreviousBox,
  Refresh,
  SymbolConfirm,
} from "../../components";
import { Offer, RequestItem } from "../../utils/models";
import { copy } from "../../utils/helpers";
import {
  ButtonText,
  ManageOfferStatusText,
  ModalTexts,
  RequestStatus,
  basicTableDateFormat,
  serverDateTimeFormat,
} from "../../utils/consts";
import { RequestDetailsTable } from "./RequestDetailsTable";
import { ManageStatusTableWrapper } from "./ManageStatusTableWrapper";
import { OfferSend } from "./OfferSend";

export const ManageStatus = () => {
  const navigate = useNavigate();
  const { isLoading, isRefetching, data, isError } = useGetCurateRequests();
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [offerForm, setOfferForm] = useState({
    offerPrice: "",
    validTo: "",
    expectedDeliveryDate: "",
  });

  const [isOfferPriceError, setIsOfferPriceError] = useState(false);
  const [isExpectedDeliveryDateError, setIsExpectedDeliveryDateError] = useState(false);
  const [isOfferValidUntilError, setIsOfferValidUntilError] = useState(false);

  const [isWithdrawnModalOpen, setIsWithdrawnModalOpen] = useState(false);

  const [isExtendOfferModalOpen, setIsExtendOfferModalOpen] = useState(false);
  const [extendOfferUntil, onChangeDateValueExtendOfferUntil] = useState<any>(undefined);
  const [extendOfferDate, setExtendOfferDate] = useState("");
  const [isExtendOfferDateError, setIsExtendOfferDateError] = useState(false);
  const [isRestoreOfferModalOpen, setIsRestoreOfferModalOpen] = useState(false);

  const [savingLoading, setSavingLoading] = useState(false);
  const [editableOffer, setEditableOffer] = useState<RequestItem>();
  const [stoneCount, setStoneCount] = useState(0);
  const saveMutation = useSaveRequest();
  const queryClient = useQueryClient();

  if (isLoading || isRefetching || !Array.isArray(data) || savingLoading) {
    return <Loader hasError={isError} />;
  }

  const order = data.find((el) => el.id === id);

  if (!id || !order) {
    navigate("/manage/");
    return null;
  }
  const handleSubmitOffer = async () => {
    let valid = true;

    if (offerForm.offerPrice === "") {
      setIsOfferPriceError(true);
      valid = false;
    } else {
      setIsOfferPriceError(false);
    }
    if (offerForm.expectedDeliveryDate === "") {
      setIsExpectedDeliveryDateError(true);
      valid = false;
    } else {
      setIsExpectedDeliveryDateError(false);
    }
    if (offerForm.validTo === "") {
      setIsOfferValidUntilError(true);
      valid = false;
    } else {
      setIsOfferValidUntilError(false);
    }

    if (valid) {
      const newOfferUpdate = copy(order);
      (newOfferUpdate.offer as Offer).offerDate = moment().format(serverDateTimeFormat);
      (newOfferUpdate.offer as Offer).price = offerForm.offerPrice;
      (newOfferUpdate.offer as Offer).expectedDeliveryDate = moment(offerForm.expectedDeliveryDate).format(
        serverDateTimeFormat,
      );
      (newOfferUpdate.offer as Offer).validTo = moment(offerForm.validTo).format(serverDateTimeFormat);
      (newOfferUpdate.offer as Offer).stoneCount = stoneCount;
      newOfferUpdate.status = RequestStatus.Offered;
      await saveMutation.mutateAsync(newOfferUpdate);
      await queryClient.invalidateQueries("requestList");
      navigate(-1);
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const currentOrder = order as RequestItem;
  const boxId = currentOrder.offer?.boxId || "";

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleNotOfferedRequest = async () => {
    const newDeclineRequest = copy(order);
    newDeclineRequest.status = RequestStatus.NotOffered;
    await saveMutation.mutateAsync(newDeclineRequest);
    await queryClient.invalidateQueries("requestList");
    navigate("/manage/");
  };

  const handleOpenModalWithdrawn = (order: RequestItem) => {
    setIsWithdrawnModalOpen(true);
    setEditableOffer(order);
  };

  const handleCloseModalWithdrawn = () => {
    setIsWithdrawnModalOpen(false);
  };

  const withdrawnOffer = async () => {
    if (editableOffer) {
      const newWithdrawnOffer = copy(editableOffer);
      newWithdrawnOffer.status = RequestStatus.Withdrawn;
      await saveMutation.mutateAsync(newWithdrawnOffer);
      await queryClient.invalidateQueries("requestList");
      setIsWithdrawnModalOpen(false);
      navigate(-1);
    }
  };

  const handleOpenModalExtendOffer = (order: RequestItem) => {
    setIsExtendOfferModalOpen(true);
    setEditableOffer(order);
  };

  const handleCloseModalExtendOffer = () => {
    setIsExtendOfferModalOpen(false);
  };

  const handleExtendOffer = (date: any) => {
    setIsExtendOfferDateError(false);
    onChangeDateValueExtendOfferUntil(date);
    setExtendOfferDate(moment(date).format(serverDateTimeFormat));
  };
  const extendOffer = async () => {
    if (extendOfferUntil === undefined) {
      setIsExtendOfferDateError(true);
    } else {
      setIsExtendOfferDateError(false);
      if (editableOffer) {
        setSavingLoading(true);
        const newExtendOffer = copy(editableOffer);
        newExtendOffer.status = RequestStatus.Offered;
        newExtendOffer!.offer!.validTo = moment(extendOfferDate).format(serverDateTimeFormat);
        await saveMutation.mutateAsync(newExtendOffer);
        await queryClient.invalidateQueries("requestList");
        setIsExtendOfferModalOpen(false);
        setSavingLoading(false);
        navigate(-1);
      }
    }
  };

  const extendOfferModalHeaderText = "Extend Offer Date";
  const extendOfferModalContent = (
    <div style={{ height: "450px" }}>
      <p>
        The Offer Valid Until date is {moment(order.offer?.validTo).format(basicTableDateFormat)} are you sure you want
        to extend?
      </p>
      <DatePickerInput
        label={"Offer Valid Until"}
        dateValue={extendOfferUntil}
        onChangeDateValue={onChangeDateValueExtendOfferUntil}
        handleChangeDate={handleExtendOffer}
        datePickerError={isExtendOfferDateError}
        icon={<ClockTimeIcon />}
        isOpen={true}
        isCloseCalendar={false}
        minDate={editableOffer?.offer?.validTo ? moment(editableOffer.offer.validTo).add(1, "days").startOf("day").toDate() : moment().add(1, "days").toDate()}
      />
    </div>
  );

  const acknowledgedButtons = [
    { text: `${ButtonText.NotOffered}`, onClick: () => handleOpenModal(), class: "btn-secondary" },
    {
      text: `${ButtonText.ReturnToRequestList}`,
      onClick: () => navigate("/manage/"),
      class: "btn-primary",
      symbol: <PreviousBox />,
    },
  ];

  const acknowledgedModalButtons = [
    { text: `${ButtonText.Cancel}`, onClick: () => setIsModalOpen(false), class: "btn-secondary btn-wide" },
    {
      text: `${ButtonText.NotOffered}`,
      onClick: () => handleNotOfferedRequest(),
      class: "btn-primary-warning btn-wide",
    },
  ];

  const basicReturnButton = [
    {
      text: `${ButtonText.ReturnToRequestList}`,
      onClick: () => navigate("/manage/"),
      class: "btn-primary",
      symbol: <PreviousBox />,
    },
  ];

  const modalWithdrawnButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => handleCloseModalWithdrawn(),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Withdrawn}`,
      onClick: () => withdrawnOffer(),
      class: "btn-primary-warning btn-wide",
    },
  ];

  const modalExtendOfferButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => handleCloseModalExtendOffer(),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Confirm}`,
      onClick: () => extendOffer(),
      class: "btn-primary btn-wide",
      symbol: <SymbolConfirm />,
    },
  ];

  const statusOfferedButtons = [
    {
      text: `${ButtonText.ExtendOfferDate}`,
      onClick: () => handleOpenModalExtendOffer(order),
      class: "btn-secondary",
      symbol: <Calendar />,
    },
    {
      text: `${ButtonText.Withdrawn}`,
      onClick: () => handleOpenModalWithdrawn(order),
      class: "btn-primary-warning",
    },
  ];

  const statusWithdrawnButtons = [
    {
      text: `${ButtonText.RestoreOffer}`,
      onClick: () => handleOpenModalRestoreOffer(order),
      class: "btn-secondary",
      symbol: <Refresh />,
    },
  ];
  const handleOpenModalRestoreOffer = (order: RequestItem) => {
    setIsRestoreOfferModalOpen(true);
    setEditableOffer(order);
  };

  const restoreOffer = async () => {
    if (editableOffer) {
      const newRestoreOffer = copy(editableOffer);
      newRestoreOffer.status = RequestStatus.Offered;
      await saveMutation.mutateAsync(newRestoreOffer);
      await queryClient.invalidateQueries("requestList");
      setIsRestoreOfferModalOpen(false);
    }
  };
  const modalRestoreOfferButtons = [
    {
      text: `${ButtonText.Cancel}`,
      onClick: () => setIsRestoreOfferModalOpen(false),
      class: "btn-secondary btn-wide",
    },
    {
      text: `${ButtonText.Confirm}`,
      onClick: () => restoreOffer(),
      class: "btn-primary btn-wide",
      symbol: <SymbolConfirm />,
    },
  ];
  return (
    <div className="manage-status">
      <button className="close" onClick={handleClose}>
        <Close />
      </button>
      <RequestDetailsTable order={order} />

      {currentOrder.status.toLowerCase() === RequestStatus.Acknowledged && (
        <InfoStatusBlock
          message={ManageOfferStatusText.Acknowledged}
          buttons={acknowledgedButtons}
          modalHeader={ModalTexts.HeaderNotOffered}
          modalContent={ModalTexts.ContentNotOffered}
          isModalOpen={isModalOpen}
          modalButtons={acknowledgedModalButtons}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      {currentOrder.status.toLowerCase() === RequestStatus.Cancelled && (
        <InfoStatusBlock message={ManageOfferStatusText.Cancelled} buttons={basicReturnButton} />
      )}

      {currentOrder.status.toLowerCase() === RequestStatus.InProgress && (
        <>
          <ManageStatusTableWrapper boxId={boxId} setStoneCount={setStoneCount} />
          <OfferSend
            offerForm={offerForm}
            setOfferForm={setOfferForm}
            isOfferPriceError={isOfferPriceError}
            setIsOfferPriceError={setIsOfferPriceError}
            isExpectedDeliveryDateError={isExpectedDeliveryDateError}
            setIsExpectedDeliveryDateError={setIsExpectedDeliveryDateError}
            isOfferValidUntilError={isOfferValidUntilError}
            setIsOfferValidUntilError={setIsOfferValidUntilError}
            handleSubmitOffer={handleSubmitOffer}
          />
        </>
      )}

      {currentOrder.status.toLowerCase() === RequestStatus.Offered && (
        <>
          <ManageStatusTableWrapper boxId={boxId} />
          <ManageOfferInfoBar order={order} buttons={statusOfferedButtons} />
        </>
      )}

      {currentOrder.status.toLowerCase() === RequestStatus.Accepted && (
        <>
          <ManageStatusTableWrapper boxId={boxId} />
          <ManageOfferInfoBar order={order} />
        </>
      )}

      {currentOrder.status.toLowerCase() === RequestStatus.Withdrawn && (
        <>
          <ManageStatusTableWrapper boxId={boxId} />
          <ManageOfferInfoBar order={order} buttons={statusWithdrawnButtons} />
        </>
      )}
      {currentOrder.status.toLowerCase() === RequestStatus.Declined && (
        <>
          <ManageStatusTableWrapper boxId={boxId} />
          <ManageOfferInfoBar order={order} />
        </>
      )}
      {currentOrder.status.toLowerCase() === RequestStatus.NotOffered && (
        <InfoStatusBlock message={ManageOfferStatusText.NotOffered} buttons={basicReturnButton} />
      )}
      {currentOrder.status.toLowerCase() === RequestStatus.Expired && (
        <>
          <ManageStatusTableWrapper boxId={boxId} />
          <ManageOfferInfoBar order={order} />
        </>
      )}

      {isWithdrawnModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderWithdrawn}
          modalContent={ModalTexts.ContentWithdrawn}
          buttons={modalWithdrawnButtons}
          setIsModalOpen={setIsWithdrawnModalOpen}
        />
      )}
      {isExtendOfferModalOpen && (
        <Modal
          modalHeader={extendOfferModalHeaderText}
          modalContent={extendOfferModalContent}
          buttons={modalExtendOfferButtons}
          setIsModalOpen={setIsExtendOfferModalOpen}
        />
      )}
      {isRestoreOfferModalOpen && (
        <Modal
          modalHeader={ModalTexts.HeaderRestoreOffer}
          modalContent={ModalTexts.ContentRestoreOffer}
          buttons={modalRestoreOfferButtons}
          setIsModalOpen={setIsRestoreOfferModalOpen}
        />
      )}
    </div>
  );
};
