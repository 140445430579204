export type StoneTableProps = {
  columns: any[];
  data: {
    tdClass?: string;
    value?: any;
  }[][];
  stickyCols?: number;
};

export const StoneTable = ({ columns, data, stickyCols }: StoneTableProps) => {
  let columnsNumber = columns.length;
  return (
    <div className="stones-table-list">
      <table>
        <thead>
          <tr className="body-small heavy tr-head sticky">
            {columns.map((col, index) => (
              <th
                className={`body-small heavy ${index === 0 && "first-col"} cell_${index + 1}                 
                ${stickyCols === 1 && index === columnsNumber - 1 ? "sticky-cell1-header" : ""} 
              ${stickyCols === 2 && (index === columnsNumber - 1 ? "sticky-cell1-header" : "")} 
              ${stickyCols === 2 && (index === columnsNumber - 2 ? "sticky-cell2-header" : "")}
                
                `}
                key={index}
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="body-small medium color-primary">
              {row.map((item: { onClick?: any; tdClass?: string; value?: string }, subIndex: number) => (
                <td
                  onClick={item?.onClick}
                  key={subIndex}
                  className={`body-small medium ${item.tdClass ?? ""}`}
                  style={{
                    cursor: item?.onClick ? "pointer" : "default",
                  }}
                >
                  {item.value ?? ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
