import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetBoxList } from "../queries";
import { BoxItem } from "../utils/models";
import moment from "moment-timezone";

type BoxNavigationContextProps = {
  current: any | null;
  next: any | null;
  prev: any | null;
  isLoading: boolean;
  isError: boolean;
  count: number;
};

export const BoxNavigationContext = createContext<BoxNavigationContextProps>({
  current: null,
  next: null,
  prev: null,
  isLoading: false,
  isError: false,
  count: 0,
});

export const BoxNavigationProvider = ({ children }: { children: any }) => {
  const { boxID } = useParams();
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { data, isLoading, isError } = useGetBoxList(!boxID);

  useEffect(() => {
    if (!isLoading && !isError && Array.isArray(data)) {
      const list = data.sort((a: BoxItem, b: BoxItem) => moment(b.createdDate).diff(moment(a.createdDate), "seconds"));
      if (!boxID) {
        setCurrentIndex(-1);
      } else {
        setCurrentIndex(list.findIndex((i) => i.id === boxID));
      }
    }
  }, [boxID, isLoading, isError, data]);

  const contextObject = useMemo(() => {
    let current = null;
    let prev = null;
    let next = null;
    if (currentIndex !== -1 && !isLoading && !isError && Array.isArray(data)) {
      const list = data.sort((a: BoxItem, b: BoxItem) => moment(b.createdDate).diff(moment(a.createdDate), "seconds"));
      current = (list as BoxItem[])[currentIndex];

      if (currentIndex + 1 < list.length) {
        next = list[currentIndex + 1].id;
      }
      if (currentIndex - 1 >= 0) {
        prev = list[currentIndex - 1].id;
      }
    }

    return {
      current,
      isLoading,
      isError,
      next,
      prev,
      count: data?.length || 0,
    };
  }, [currentIndex, isError, isLoading, data]);

  return <BoxNavigationContext.Provider value={contextObject}>{children}</BoxNavigationContext.Provider>;
};

export const useBoxNavigation = () => useContext(BoxNavigationContext);
