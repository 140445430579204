import { useQuery, UseQueryResult } from "react-query";
import BoxList from "../mocks/boxListActual.json";
import { BoxItem, BoxItemRaw } from "../utils/models";
import Server from "../config/server.json";
import axios from "axios";
import Config from "../config/auth.json";
import { AdditionalConfig } from "../utils/consts";
import moment from "moment-timezone";

const fetch = async (): Promise<BoxItemRaw[]> => {
  if (Server.useMocks) {
    return BoxList;
  }
  const url = Config.endpoint + Server.getBoxes;
  const response = await axios.get(url);
  return response.data;
};

export const useGetBoxList = (lockRequest = false): UseQueryResult<BoxItem[]> => {
  return useQuery(["boxList"], fetch, {
    select: (list) => {
      return list
        .map((item) => ({
          id: item.boxId || "",
          stoneCount: parseInt(item.StoneCount, 10),
          sightHolderId: item.SightHolderId,
          sightHolderName: item.SightHolderName || "",
          sightNumber: item.SightId || "",
          createdDate: item.CreatedDate || "",
          dispatchDate: item.DispatchDate,
          link: item.boxdownloaddata || "",
        }))
        .sort((a, b) => moment(b.dispatchDate).unix() - moment(a.dispatchDate).unix());
    },
    enabled: !lockRequest,
    ...AdditionalConfig,
  });
};
