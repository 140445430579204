import { Button, Col, Row } from "react-bootstrap";

type DevInputProps = {
  surface: number[];
  setSurface: (value: number[]) => void;
  wireframe: number[];
  setWireframe: (value: number[]) => void;
  setHidden: () => void;
};

export const DevInput = ({ setHidden, surface, setSurface, wireframe, setWireframe }: DevInputProps) => {
  return (
    <div className={"dev-color-input container"}>
      <Row>
        <Col>Surface</Col>
        <Col>
          <input
            type={"number"}
            value={surface[0]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newSurface = [...surface];
                newSurface[0] = value;
                setSurface(newSurface);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={surface[1]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newSurface = [...surface];
                newSurface[1] = value;
                setSurface(newSurface);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={surface[2]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newSurface = [...surface];
                newSurface[2] = value;
                setSurface(newSurface);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={surface[3]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newSurface = [...surface];
                newSurface[3] = value;
                setSurface(newSurface);
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>Wireframe</Col>
        <Col>
          <input
            type={"number"}
            value={wireframe[0]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newWireframe = [...wireframe];
                newWireframe[0] = value;
                setWireframe(newWireframe);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={wireframe[1]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newWireframe = [...wireframe];
                newWireframe[1] = value;
                setWireframe(newWireframe);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={wireframe[2]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newWireframe = [...wireframe];
                newWireframe[2] = value;
                setWireframe(newWireframe);
              }
            }}
          />
        </Col>
        <Col>
          <input
            type={"number"}
            value={wireframe[3]}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              if (!isNaN(value)) {
                const newWireframe = [...wireframe];
                newWireframe[3] = value;
                setWireframe(newWireframe);
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={setHidden}>Hide</Button>
        </Col>
      </Row>
    </div>
  );
};
