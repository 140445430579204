import { Row } from "react-bootstrap";
import { BarCode, Calendar, RoughtStone, SymbolUser } from "..";

type ConnectDetailCardProps = {
  boxId?: string;
  quantity?: number;
  sightDate?: string;
  sightHolder?: string;
};
export const ConnectDetailCard = (props: ConnectDetailCardProps) => {
  return (
    <Row>
      <div className="connect-detail-card body-small medium color-primary">
        <div className="box-id">
          <BarCode />
          <div className="number">ID: {props.boxId}</div>
        </div>
        <div className="stones-count">
          <RoughtStone />
          <div className="quantity">Quantity: {props.quantity}</div>
        </div>
        <div className="date">
          <Calendar />
          <div className="month">Sight: {props.sightDate?.toUpperCase()}</div>
        </div>
        <div className="sightholder">
          <SymbolUser />
          <div className="name">Sightholder: {props.sightHolder}</div>
        </div>
      </div>
    </Row>
  );
};
