import moment from "moment-timezone";
import { ChangeEventHandler, useState } from "react";
import { Calendar, ClockTimeIcon, DatePickerInput, InputField } from "../../components";
import { ButtonText, OfferErrors, serverDateTimeFormat } from "../../utils/consts";

export type OfferFormValue = {
  offerPrice: string;
  validTo: string;
  expectedDeliveryDate: string;
};

export type OfferSendProps = {
  offerForm: OfferFormValue;
  setOfferForm: (value: any) => void;
  isOfferPriceError: boolean;
  setIsOfferPriceError: (value: boolean) => void;
  isExpectedDeliveryDateError: boolean;
  setIsExpectedDeliveryDateError: (value: boolean) => void;
  isOfferValidUntilError: boolean;
  setIsOfferValidUntilError: (value: boolean) => void;
  handleSubmitOffer: () => void;
};

export const OfferSend = ({
  offerForm,
  setOfferForm,
  isOfferPriceError,
  setIsOfferPriceError,
  isExpectedDeliveryDateError,
  setIsExpectedDeliveryDateError,
  isOfferValidUntilError,
  setIsOfferValidUntilError,
  handleSubmitOffer,
}: OfferSendProps) => {
  const [expectedDeliveryDate, onChangeDateValueDeliveryDate] = useState<any>(undefined);
  const [offerValidUntil, onChangeDateValueValidUntil] = useState<any>(undefined);

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setOfferForm({
      ...offerForm,
      offerPrice: value,
    });
    if (name === "offerPrice") {
      setIsOfferPriceError(false);
    }
  };

  const handleChangeDateDelivery = (date: any) => {
    setIsExpectedDeliveryDateError(false);
    onChangeDateValueDeliveryDate(date);
    setOfferForm({
      ...offerForm,
      expectedDeliveryDate: moment(date).format(serverDateTimeFormat),
    });
  };

  const handleChangeDateValidUntil = (date: any) => {
    setIsOfferValidUntilError(false);
    onChangeDateValueValidUntil(date);
    setOfferForm({
      ...offerForm,
      validTo: moment(date).format(serverDateTimeFormat),
    });
  };

  let offerPriceFilled = offerForm.offerPrice.length > 0;

  return (
    <div className="send-offer-block">
      <div className="w-100">
        <InputField
          label={"Offer Price $"}
          type={"number"}
          value={offerForm.offerPrice}
          name={"offerPrice"}
          onChange={handleChangeInput}
          filled={offerPriceFilled}
        />
        {isOfferPriceError && (
          <div className="input-error-message color-error body-small medium"> {OfferErrors.Price}</div>
        )}
      </div>
      <div className="w-100">
        <DatePickerInput
          label={"Expected Delivery Date"}
          dateValue={expectedDeliveryDate}
          onChangeDateValue={onChangeDateValueDeliveryDate}
          handleChangeDate={handleChangeDateDelivery}
          datePickerError={isExpectedDeliveryDateError}
          icon={<Calendar />}
          isCloseCalendar={true}
          errorMessage={OfferErrors.DeliveryDate}
        />
      </div>
      <div className="w-100">
        <DatePickerInput
          label={"Offer Valid Until"}
          dateValue={offerValidUntil}
          onChangeDateValue={handleChangeDateValidUntil}
          handleChangeDate={handleChangeDateValidUntil}
          datePickerError={isOfferValidUntilError}
          icon={<ClockTimeIcon />}
          isCloseCalendar={true}
          minDate={moment().add(1, "days").toDate()}
          errorMessage={OfferErrors.ValidUntil}
        />
      </div>
      <div>
        <button className="btn-primary" onClick={handleSubmitOffer}>
          {ButtonText.SendOffer}
        </button>
      </div>
    </div>
  );
};
