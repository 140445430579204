import { RequestItem, StoneItem } from "../../utils/models";
import { useGetBoxList } from "../../queries";
import { useEffect, useState } from "react";
import { Loader, OfferLeftPanel } from "../../components";

type OfferLeftPanelWrapperProps = {
  boxId: string | null;
  requestDetails: RequestItem;
  stones?: StoneItem[];
  selectedStone: string;
  setSelectedStone: (value: string) => void;
  handleRefresh3D: () => void;
};

export const OfferLeftPanelWrapper = ({
  requestDetails,
  stones,
  selectedStone,
  setSelectedStone,
  boxId,
  handleRefresh3D,
}: OfferLeftPanelWrapperProps) => {
  const { isLoading, data, isError } = useGetBoxList();
  const [downloadBox, setDownloadBox] = useState("");

  useEffect(() => {
    if (boxId && !isLoading && Array.isArray(data)) {
      const box = data.find((i) => i.id === boxId);
      if (box) {
        setDownloadBox(box.link);
      }
    }
  }, [boxId, isLoading, data]);

  if (boxId && isLoading && !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }

  return (
    <OfferLeftPanel
      handleRefresh3D={handleRefresh3D}
      requestDetails={requestDetails}
      stones={stones}
      selectedStone={selectedStone}
      setSelectedStone={setSelectedStone}
      boxDownloadLink={downloadBox}
    />
  );
};
