import { Col, FormCheck, Row } from "react-bootstrap";
import { I3D, Image } from "../";

type ViewSwitcherProps = {
  is3D: boolean;
  setIs3D: (value: boolean) => void;
  surfaceMeshVisible: boolean;
  updateSurfaceMeshVisible: (value: boolean) => void;
  inclusionsVisible: boolean;
  updateInclusionsVisible: (value: boolean) => void;
  isPolishedVisible: boolean;
  additionalClassName?: string;
  updateIsPolishedVisible: (value: boolean) => void;
};

export const ViewSwitcher = ({
  is3D,
  setIs3D,
  surfaceMeshVisible,
  updateSurfaceMeshVisible,
  inclusionsVisible,
  updateInclusionsVisible,
  isPolishedVisible,
  updateIsPolishedVisible,
  additionalClassName = "",
}: ViewSwitcherProps) => (
  <div className={"view-switcher-wrapper " + additionalClassName}>
    <div className="view-type">
      <button onClick={() => setIs3D(true)} className={`view-type-button btn-wide ${is3D ? "active" : ""}`}>
        <I3D />
        360° View
      </button>
      <button className={`view-type-button btn-wide ${!is3D ? "active" : ""}`} onClick={() => setIs3D(false)}>
        <Image />
        gallery
      </button>
    </div>
    {is3D && (
      <Row>
        <Col>
          <Row className="view-switcher-container">
            <Col className="text">polished fit</Col>
            <Col className="view-switcher">
              <FormCheck
                type="switch"
                checked={isPolishedVisible}
                onChange={(e) => {
                  updateIsPolishedVisible(e.target.checked);
                  if (!surfaceMeshVisible) {
                    updateSurfaceMeshVisible(true);
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="view-switcher-container top-bottom-border">
            <Col className="text">surface mesh</Col>
            <Col className="view-switcher">
              <FormCheck
                type="switch"
                checked={surfaceMeshVisible}
                disabled={!isPolishedVisible}
                onChange={(e) => updateSurfaceMeshVisible(e.target.checked)}
              />
            </Col>
          </Row>
          <Row className="view-switcher-container">
            <Col className="text">inclusions</Col>
            <Col className="view-switcher">
              <FormCheck
                type="switch"
                checked={inclusionsVisible}
                onChange={(e) => updateInclusionsVisible(e.target.checked)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )}
  </div>
);
