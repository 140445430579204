import { useNavigate } from "react-router-dom";
import { InfoStatusBlock, Loader } from "../../components";
import { useGetCurateRequests } from "../../queries";
import { ManageOffersTable } from "./ManageOffersTable";
import { ButtonText, PlatformPageLink, ThereIsNoData } from "../../utils/consts";

export const ManageOffersScreen = () => {
  const navigate = useNavigate();
  const { isLoading, data, isError } = useGetCurateRequests();
  if (isLoading || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }

  const returnButtons = [
    {
      text: `${ButtonText.ReviewRequests}`,
      onClick: () => navigate(PlatformPageLink.Manage),
      class: "btn-primary",
    },
  ];

  return (
    <div className="my-manage">
      {data.length === 0 ? (
        <InfoStatusBlock message={ThereIsNoData.ManageOffers} widerTextBlock={true} buttons={returnButtons} />
      ) : (
        <ManageOffersTable data={data} />
      )}
    </div>
  );
};
