import { FormCheck } from "react-bootstrap";
import { StoneItem } from "../../utils/models";

type StoneItemProps = {
  active: boolean;
  setSelectedStone: (value: string) => void;
  element: StoneItem;
};

export const StoneItemContent = ({ active, setSelectedStone, element }: StoneItemProps) => {
  const statusClass = active ? "active" : "";
  return (
    <div className={`stones-wrapper ${statusClass}`} onClick={() => setSelectedStone(element.id)}>
      <FormCheck type="radio" className="diamond-size" checked={active} onChange={() => setSelectedStone(element.id)} />
      <div className="stones-parameters">
        <div className="body-small medium last first-letter-cap wi70">{element.id}</div>

        <div className="body-small medium weight wi60">{element.weight}</div>
        <div className="body-small medium last first-letter-cap wi60">{element.color.toUpperCase()}</div>
        <div className="body-small medium last first-letter-cap wi80">{element.flu.toLowerCase()}</div>
        <div className="body-small medium last first-letter-cap wi60">{element.polish[0].shape.toLowerCase()}</div>
        <div className="body-small medium last first-letter-cap wi60">{element.polish[0].weight}</div>
        <div className="body-small medium last first-letter-cap wi60">{element.polish[0].clarity.toUpperCase()}</div>
        <div className="body-small medium last first-letter-cap wi60">{element.polish[0].make.toUpperCase()}</div>
      </div>
    </div>
  );
};
