import { SortBigToSmall, SortSmallToBig, NotSorted } from "../";
import { SortDirection } from "../../utils/consts";

type SortButtonProps = {
  colName: string;
  onClick: (colName: string, sortBy:string) => void;
  sortBy: string;
};
export const SortButton = (props: SortButtonProps) => {
  return (
    <div className="sort" onClick={() => props.onClick(props.colName, props.sortBy)}>
      {props.colName}
      {props.sortBy === SortDirection.ASC && <SortSmallToBig />}
      {props.sortBy === SortDirection.DESC && <SortBigToSmall />}
      {props.sortBy === SortDirection.None && <NotSorted />}
    </div>
  );
};
