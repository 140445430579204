import { RangeSliderWithInputs } from "../";
import { clarityRange, colourRange, fluorescenceRange, makeRange } from "../../utils/consts";

import { Slider } from "@mui/material";

export type HighLow = {
  high: number;
  low: number;
};
export type MaxMin = {
  min: number;
  max: number;
};

export type NewRequestRangeGroupProps = {
  caratValues: MaxMin;
  colorValues: HighLow;
  clarityValues: HighLow;
  fluorescenceValues: HighLow;
  makeValues: HighLow;
  setCaratValues: (value: MaxMin) => void;
  setColorValues: (value: HighLow) => void;
  setClarityValues: (value: HighLow) => void;
  setFluorescenceValues: (value: HighLow) => void;
  setMakeValues: (value: HighLow) => void;
};

export const NewRequestRangeGroup = ({
  caratValues,
  colorValues,
  clarityValues,
  fluorescenceValues,
  makeValues,
  setCaratValues,
  setColorValues,
  setClarityValues,
  setFluorescenceValues,
  setMakeValues,
}: NewRequestRangeGroupProps) => {
  const colorArr = colourRange.map((label, index) => ({
    value: index,
    label,
  }));

  const clarityArr = clarityRange.map((label, index) => ({
    value: index,
    label,
  }));

  const fluorescenceArr = fluorescenceRange.map((label, index) => ({
    value: index,
    label,
  }));

  const makeArr = makeRange.map((label, index) => ({
    value: index,
    label,
  }));
  return (
    <>
      <div className="range-box">
        <RangeSliderWithInputs caratValues={caratValues} setCaratValues={setCaratValues} />
        <div className="slider-container">
          <div className="slider-container-header caption-large-upper heavy">colour</div>
          <div className="slider-with-values">
            <div className="slider-box">
              <Slider
                defaultValue={0}
                min={0}
                max={colourRange.length - 1}
                onChange={(event, value) => {
                  if (Array.isArray(value)) {
                    setColorValues({ low: value[0], high: value[value.length - 1] });
                  } else {
                    setColorValues({ low: value, high: value });
                  }
                }}
                disableSwap={true}
                value={[colorValues.low, colorValues.high]}
                valueLabelDisplay="off"
                marks={colorArr}
              />
            </div>
          </div>
        </div>
        <div className="slider-container">
          <div className="slider-container-header caption-large-upper heavy">clarity</div>
          <div className="slider-with-values">
            <div className="slider-box">
              <Slider
                defaultValue={0}
                min={0}
                max={clarityRange.length - 1}
                onChange={(event, value) => {
                  if (Array.isArray(value)) {
                    setClarityValues({ low: value[0], high: value[value.length - 1] });
                  } else {
                    setClarityValues({ low: value, high: value });
                  }
                }}
                disableSwap={true}
                value={[clarityValues.low, clarityValues.high]}
                valueLabelDisplay="off"
                marks={clarityArr}
              />
            </div>
          </div>
        </div>
        <div className="slider-container">
          <div className="slider-container-header caption-large-upper heavy">Fluorescence</div>
          <div className="slider-with-values">
            <div className="slider-box">
              <Slider
                defaultValue={0}
                min={0}
                max={fluorescenceRange.length - 1}
                onChange={(event, value) => {
                  if (Array.isArray(value)) {
                    setFluorescenceValues({ low: value[0], high: value[value.length - 1] });
                  } else {
                    setFluorescenceValues({ low: value, high: value });
                  }
                }}
                disableSwap={true}
                value={[fluorescenceValues.low, fluorescenceValues.high]}
                valueLabelDisplay="off"
                marks={fluorescenceArr}
              />
            </div>
          </div>
        </div>
        <div className="slider-container">
          <div className="slider-container-header caption-large-upper heavy">MAKE</div>
          <div className="slider-with-values">
            <div className="slider-box">
              <Slider
                defaultValue={0}
                min={0}
                max={makeRange.length - 1}
                onChange={(event, value) => {
                  if (Array.isArray(value)) {
                    setMakeValues({ low: value[0], high: value[value.length - 1] });
                  } else {
                    setMakeValues({ low: value, high: value });
                  }
                }}
                disableSwap={true}
                value={[makeValues.low, makeValues.high]}
                valueLabelDisplay="off"
                marks={makeArr}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
