import { useState, useEffect } from "react";
import { NextBox } from "../GlobalIcons";
import ErrorDiamond from "../../assets/images/image_diamond.jpg";
import { useNavigate } from "react-router-dom";
import { ButtonText, ErrorPageMessages } from "../../utils/consts";

export const NotFoundPage = () => {
  const endTime = new Date(Date.now() + 5000);
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  const navigate = useNavigate();

  function calculateRemainingTime() {
    const now = new Date();
    const difference = endTime.getTime() - now.getTime();
    return difference > 0 ? difference : 0;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);
    return () => clearInterval(timer);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const seconds = Math.ceil(remainingTime / 1000);

  const handleNavigateHome = () => {
    navigate("/");
  };
  if (seconds === 0) {
    navigate("/");
  }
  return (
    <div className="not-found-page">
      <div className="error-page-text">
        <div className="huge-text">{ErrorPageMessages.Header}</div>
        <div className="error-code">{ErrorPageMessages.Error404}</div>
        <h5 className="error-text medium">{ErrorPageMessages.Message404}</h5>
        <div className="button-wrapper">
          <button className="btn-primary" onClick={handleNavigateHome}>
            {ButtonText.ToHomePage} <NextBox />
          </button>
          <div className="error-loader-wrapper">
            <div className="error-loader"></div>
          </div>
          <div className="countdown-timer subtitle medium">{`You will be redirected in ${seconds} seconds`}</div>
        </div>
      </div>
      <div className="error-page-image">
        <img src={ErrorDiamond} alt="error page" />
      </div>
    </div>
  );
};
