import { NavLink } from "react-router-dom";
import { NavbarText } from "../../utils/consts";
import { RequestsIcon, Offers, FeatureBox } from "../";

export const NavBarManage = () => {
  return (
    <>
      <NavLink to="/manage/" end>
        <FeatureBox icon={<RequestsIcon />} btnText={NavbarText.Requests} />
      </NavLink>
      <NavLink to="/manage/offers/">
        <FeatureBox icon={<Offers />} btnText={NavbarText.Offers} />
      </NavLink>
    </>
  );
};
