import { useRef, useEffect } from "react";

import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import vtkRenderWindow from "@kitware/vtk.js/Rendering/Core/RenderWindow";
import vtkRenderer from "@kitware/vtk.js/Rendering/Core/Renderer";
import vtkInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
import openGLRenderer from "@kitware/vtk.js/Rendering/OpenGL/RenderWindow";
import Trackball from "@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera";
import { PolyItem } from "../../utils/models";
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkCellPicker from "@kitware/vtk.js/Rendering/Core/CellPicker";
import {
  WiderTableScreen,
  defaultWireframeColor,
  interpolateHoverDefaultColor,
  tabletScreenSize,
} from "../../utils/consts";
import { Rotate3D } from "../";

type View3DContext = {
  renderWindow: vtkRenderWindow;
  interactor: vtkInteractor;
  renderer: vtkRenderer;
  openGLRenderWindow: openGLRenderer;
  trackball: any;
  picker: vtkCellPicker;
};

type View3DProps = {
  surface: vtkActor | null;
  mesh: vtkActor[];
  poly: PolyItem[];
  version: number;
};

export const View3D = ({ surface, mesh, poly, version }: View3DProps) => {
  const vtkContainerRef = useRef(null);
  const context = useRef(null);

  useEffect(() => {
    if (!context.current && vtkContainerRef && vtkContainerRef.current) {
      const renderWindow = vtkRenderWindow.newInstance();

      const renderer = vtkRenderer.newInstance();
      renderer.setBackground(0.114, 0.118, 0.106);
      renderWindow.addRenderer(renderer);
      const openGLRenderWindow = openGLRenderer.newInstance();
      openGLRenderWindow.setContainer(vtkContainerRef.current);

      const { width } = (vtkContainerRef.current as any).getBoundingClientRect();
      let classBig = document.querySelectorAll(".big");
      let canvaHeight =
        window.innerWidth < (classBig.length > 0 ? WiderTableScreen : tabletScreenSize)
          ? window.innerHeight / 2
          : window.innerHeight;
      openGLRenderWindow.setSize(width, canvaHeight);
      renderWindow.addView(openGLRenderWindow);

      const trackball = Trackball.newInstance();
      const interactor = vtkInteractor.newInstance();
      interactor.setView(openGLRenderWindow);
      interactor.initialize();
      interactor.bindEvents(vtkContainerRef.current);
      interactor.setInteractorStyle(trackball);

      const picker = vtkCellPicker.newInstance();
      picker.setTolerance(0);
      picker.setPickFromList(1);
      picker.initializePickList();

      if(surface!==null) {
        renderer.addActor(surface);
      }
      mesh.forEach((meshItem) => {
        renderer.addActor(meshItem);
      });
      poly.forEach((polyItem) => {
        renderer.addActor(polyItem.actor);
        picker.addPickList(polyItem.actor);
        if (polyItem.wireframe) {
          renderer.addActor(polyItem.wireframe);
        }
      });
      renderWindow.getInteractor().onMouseMove((data) => {
        if (renderer !== data.pokedRenderer) {
          return;
        }
        const pos = data.position;
        const point = [pos.x, pos.y, 0.0];
        picker.pick(point, renderer);
        const actors = picker.getActors();
        poly.forEach((item) => {
          if (item.wireframe) {
            if (actors.length && item.actor === actors[0]) {
              item.wireframe
                .getProperty()
                .setColor(
                  interpolateHoverDefaultColor[0],
                  interpolateHoverDefaultColor[1],
                  interpolateHoverDefaultColor[2],
                );
            } else {
              item?.wireframe
                ?.getProperty()
                .setColor(defaultWireframeColor[0], defaultWireframeColor[1], defaultWireframeColor[2]);
            }
          }
        });
        renderWindow.render();
      });

      renderer.resetCamera();
      renderWindow?.render();

      (context.current as unknown as View3DContext) = {
        renderWindow,
        renderer,
        openGLRenderWindow,
        interactor,
        trackball,
        picker,
      };
    }

    return () => {
      if (context.current) {
        const { renderer, picker, openGLRenderWindow, renderWindow, interactor, trackball }: View3DContext =
          context.current;
        openGLRenderWindow?.delete();
        renderer?.delete();
        renderWindow?.delete();
        interactor?.delete();
        picker?.delete();
        trackball?.delete();
        context.current = null;
        // remove canvas item from container because it is not removed automatically
        if (vtkContainerRef.current && (vtkContainerRef.current as any)?.firstChild) {
          /* eslint-disable react-hooks/exhaustive-deps */
          (vtkContainerRef.current as any).removeChild((vtkContainerRef.current as any).firstChild);
        }
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [vtkContainerRef, mesh, poly, surface]);

  const handleResize3DCanvas = () => {
    if (context.current) {
      const { width } = (vtkContainerRef.current as any).getBoundingClientRect();
      const { openGLRenderWindow }: View3DContext = context.current;
      let classBig = document.querySelectorAll(".big");
      let canvaHeight =
        window.innerWidth < (classBig.length > 0 ? WiderTableScreen : tabletScreenSize)
          ? window.innerHeight / 2
          : window.innerHeight;
      openGLRenderWindow.setSize(width, canvaHeight);
      const { renderWindow }: View3DContext = context.current;
      renderWindow?.render();
    }
  };

  useEffect(() => {
    handleResize3DCanvas();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [context.current, version]);

  useEffect(() => {
    function handleResize() {
      handleResize3DCanvas();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className="view3d-wrapper">
      <div ref={vtkContainerRef} className="view3d-container" />
      <div className={"rotate-3d-icon"}>
        <Rotate3D />
      </div>
    </div>
  );
};
