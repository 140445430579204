import { Loader, OrderDetailBox } from "../";
import { RequestStatus } from "../../utils/consts";
import { useGetCurateRequests } from "../../queries";
import moment from "moment-timezone";
import { RequestItem } from "../../utils/models";
import { CustomCarousel } from "../CustomCarousel";

export const OrderDetailBoxWrapper = () => {
  const { isLoading, data, isError } = useGetCurateRequests();

  if (isLoading || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }
  let sortedData: RequestItem[] = [...data]
    .filter((obj) => obj.status.toLowerCase() === RequestStatus.Offered)
    .sort(
      (a: RequestItem, b: RequestItem) => -1 * moment(a.offer?.offerDate).diff(moment(b.offer?.offerDate), "seconds"),
    );

  return (
    <>
      <CustomCarousel>
        {sortedData?.map((data, index) => (
          <OrderDetailBox
            key={index.toString()}
            id={data.id || ""}
            price={data.offer?.price}
            quantity={data.offer?.stoneCount}
            shape={data.shape}
            valid={data.offer?.validTo}
          />
        ))}
      </CustomCarousel>
    </>
  );
};
