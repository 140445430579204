// Menu-navigation icons  ------------START----------
import { ReactComponent as Boxes } from "../../assets/icons/boxes.svg";
import { ReactComponent as Serv } from "../../assets/icons/services.svg";
import { ReactComponent as Notif } from "../../assets/icons/notification.svg";
import { ReactComponent as Filt } from "../../assets/icons/filter2.svg";
import { ReactComponent as Down } from "../../assets/icons/download-1.svg";
import { ReactComponent as NewRuq } from "../../assets/icons/plus.svg";
import { ReactComponent as Offe } from "../../assets/icons/offers.svg";
import { ReactComponent as PrevBox } from "../../assets/icons/left.svg";
import { ReactComponent as NextBoXX } from "../../assets/icons/right.svg";
import { ReactComponent as ChevDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as ChevUp } from "../../assets/icons/chevron-up.svg";
import { ReactComponent as Price } from "../../assets/icons/price.svg";
import { ReactComponent as Timer } from "../../assets/icons/timer.svg";
import { ReactComponent as NoSort } from "../../assets/icons/NotSorted.svg";
import { ReactComponent as BigToSmallSort } from "../../assets/icons/SortBigToSmall.svg";
import { ReactComponent as SmallToBigSort } from "../../assets/icons/SortSmallToBig.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";

// Menu-navigation icons  ------------END----------

// Platform icons -----------START---------------

import { ReactComponent as Connect } from "../../assets/icons/platform/Connect-Icon.svg";
import { ReactComponent as Curate } from "../../assets/icons/platform/Curate-Icon.svg";
import { ReactComponent as Manage } from "../../assets/icons/platform/Manage-Icon.svg";

// Platform icons -----------END---------------

// Diamonds

import { ReactComponent as AsscherDiamond } from "../../assets/icons/diamonds/Asscher.svg";
import { ReactComponent as CushionDiamond } from "../../assets/icons/diamonds/Cushion.svg";
import { ReactComponent as EmeraldDiamond } from "../../assets/icons/diamonds/Emerald.svg";
import { ReactComponent as HeartDiamond } from "../../assets/icons/diamonds/Heart.svg";
import { ReactComponent as MarquiseDiamond } from "../../assets/icons/diamonds/Marquise.svg";
import { ReactComponent as OvalDiamond } from "../../assets/icons/diamonds/Oval.svg";
import { ReactComponent as PearDiamond } from "../../assets/icons/diamonds/Pear.svg";
import { ReactComponent as PrincessDiamond } from "../../assets/icons/diamonds/Princess.svg";
import { ReactComponent as RadiantDiamond } from "../../assets/icons/diamonds/Radiant.svg";
import { ReactComponent as RoundDiamond } from "../../assets/icons/diamonds/Round.svg";
import { ReactComponent as DiamondBasic } from "../../assets/icons/diamond.svg";

// Button icons ------------START------------
import { ReactComponent as ConfirmSymbol } from "../../assets/icons/confirm.svg";
import { ReactComponent as ConfirmSymbolWhite } from "../../assets/icons/confirmWhite.svg";

import { ReactComponent as DownloadSymbol } from "../../assets/icons/download-1.svg";
import { ReactComponent as NextSymbol } from "../../assets/icons/details.svg";
// Button icons ------------END------------
import { ReactComponent as BarCodeIcon } from "../../assets/icons/bar-code.svg";
import { ReactComponent as WeightIcon } from "../../assets/icons/weigth.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as RoughtStoneIcon } from "../../assets/icons/rought-stone.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";

import { ReactComponent as I3DIcon } from "../../assets/icons/3d.svg";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";

import { ReactComponent as ChevronLeftIcon } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as Time } from "../../assets/icons/time.svg";
import { ReactComponent as Rotate3DIcon } from "../../assets/icons/rotate3D.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as EyeWhiteIcon } from "../../assets/icons/EyeWhite.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusL.svg";
import { ReactComponent as EyeOpenIcon } from "../../assets/icons/EyeOn.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import { ReactComponent as NextWithBox } from "../../assets/icons/next-with-box.svg";
import { ReactComponent as PrevWithBox } from "../../assets/icons/prev-with-box.svg";

import { ReactComponent as DeBeersLogoIcon } from "../../assets/icons/deBeers.svg";

export const DeBeersLogo = () => {
  return <DeBeersLogoIcon />;
};

export const Refresh = () => {
  return <RefreshIcon />;
};
export const Minus = () => {
  return <MinusIcon />;
};
export const Plus = () => {
  return <PlusIcon />;
};
export const ArrowDown = () => {
  return <ArrowDownIcon />;
};
export const EyeWhite = () => {
  return <EyeWhiteIcon />;
};
export const Close = () => {
  return <CloseIcon />;
};
export const Rotate3D = () => {
  return <Rotate3DIcon />;
};
export const BarCode = () => {
  return <BarCodeIcon />;
};
export const Weight = () => {
  return <WeightIcon />;
};
export const Calendar = () => {
  return <CalendarIcon />;
};

export const I3D = () => {
  return <I3DIcon />;
};
export const Image = () => {
  return <ImageIcon />;
};

export const ChevronLeft = () => {
  return <ChevronLeftIcon />;
};
export const ChevronRight = () => {
  return <ChevronRightIcon />;
};
export const ChevronUp = () => {
  return <ChevUp />;
};
export const ChevronDown = () => {
  return <ChevDown />;
};

export const Asscher = () => {
  return <AsscherDiamond />;
};
export const Cushion = () => {
  return <CushionDiamond />;
};
export const Emerald = () => {
  return <EmeraldDiamond />;
};
export const Heart = () => {
  return <HeartDiamond />;
};
export const Marquise = () => {
  return <MarquiseDiamond />;
};
export const Oval = () => {
  return <OvalDiamond />;
};
export const Pear = () => {
  return <PearDiamond />;
};
export const Princess = () => {
  return <PrincessDiamond />;
};
export const Radiant = () => {
  return <RadiantDiamond />;
};
export const Round = () => {
  return <RoundDiamond />;
};

export const DiamondIcon = () => {
  return <DiamondBasic />;
};

export const ActiveBoxes = () => {
  return <Boxes />;
};

export const Services = () => {
  return <Serv />;
};
export const Notification = () => {
  return <Notif />;
};

export const Filter = () => {
  return <Filt />;
};
export const Download = () => {
  return <Down />;
};

export const NewRequest = () => {
  return <NewRuq />;
};

export const Offers = () => {
  return <Offe />;
};

export const PreviousBox = () => {
  return <PrevBox />;
};

export const NextBox = () => {
  return <NextBoXX />;
};

export const ConnectIcon = () => {
  return <Connect />;
};

export const CurateIcon = () => {
  return <Curate />;
};
export const ManageIcon = () => {
  return <Manage />;
};

// Buttons -------------------------------------------------
export const SymbolConfirm = () => {
  return <ConfirmSymbol />;
};
export const SymbolConfirmWhite = () => {
  return <ConfirmSymbolWhite />;
};

export const SymbolDownload = () => {
  return <DownloadSymbol />;
};

export const SymbolNext = () => {
  return <NextSymbol />;
};

export const RoughtStone = () => {
  return <RoughtStoneIcon />;
};

export const SymbolUser = () => {
  return <UserIcon />;
};

export const PriceIcon = () => {
  return <Price />;
};

export const ClockTimeIcon = () => {
  return <Time />;
};

export const RequestsIcon = () => {
  return <Timer />;
};
export const NotSorted = () => {
  return <NoSort />;
};
export const SortBigToSmall = () => {
  return <BigToSmallSort />;
};
export const SortSmallToBig = () => {
  return <SmallToBigSort />;
};

export const EyeOnWhite = () => {
  return <EyeOpenIcon />;
};

export const CarouselNext = () => {
  return <NextWithBox />;
};

export const CarouselPrev = () => {
  return <PrevWithBox />;
};

export const LogOutIcon = () => {
  return <Logout />;
};