import { Col } from "react-bootstrap";
import { BarCode, Calendar, ClockTimeIcon, DiamondIcon, PriceIcon } from "../";
import moment from "moment-timezone";
import { basicTableDateFormat } from "../../utils/consts";
import { formatPrice } from "../../utils/helpers";

type RequestDetailsProps = {
  id: string;
  price: string;
  deliveryDate: string;
  count: number;
  shape: string;
  validUntil: string;
};

export const RequestDetails = ({ id, price, deliveryDate, count, shape, validUntil }: RequestDetailsProps) => {
  return (
    <Col>
      <div className="description-row">
        <Col className="icon-block">
          <BarCode />
        </Col>
        <Col className="body-small medium text_pad">ID: {id}</Col>
      </div>
      <div className="description-row">
        <Col className="icon-block">
          <PriceIcon />
        </Col>
        <Col className="body-small medium text_pad">Offer Price: {formatPrice(price)} $</Col>
      </div>
      <div className="description-row">
        <Col className="icon-block">
          <Calendar />
        </Col>
        <Col className="body-small medium text_pad">
          Expected Delivery Date: {moment(deliveryDate).format(basicTableDateFormat)}
        </Col>
      </div>
      <div className="description-row">
        <Col className="icon-block">
          <ClockTimeIcon />
        </Col>
        <Col className="body-small medium text_pad">
          Offer Valid Until: {moment(validUntil).format(basicTableDateFormat)}
        </Col>
      </div>
      {count > 0 && (
        <div className="description-row">
          <Col className="icon-block">
            <DiamondIcon />
          </Col>
          <Col className="body-small medium text_pad first-letter-cap">
            Stones: {count} {shape.toLowerCase()}
          </Col>
        </div>
      )}
    </Col>
  );
};
